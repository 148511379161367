import React, { useState, useEffect } from "react";
import { isNullOrUndefined } from "../utils/commonFunctions";
import NumberFormat from "../utils/NumberFormat";
import TableHeader from "./TableHeader";
import "./SimpleTable.scss";
import "./TransactionHistoryTable.scss";
import TableData from "./TableData";
import Token from "../misc/Token";
import { display } from "../utils/ValidationUtils";
import EmptyStateMessage from "../misc/EmptyStateMessage";
import LoadingIcon from "../misc/LoadingIcon";
import FlexWrapper from "../layout/FlexWrapper";

export const fields = {
	date: "DATE",
	particulars: "PARTICULARS",
	typeActivity: "TYPE_ACTIVITY",
	quantity: "QUANTITY",
	unitCost: "UNIT_COST",
	currency: "CURRENCY",
	total: "TOTAL",
	cashBalance: "CASH_BALANCE",
};

const headers = {
	[fields.date]: "Date",
	[fields.particulars]: "Particulars",
	[fields.typeActivity]: "Type/Activity",
	[fields.quantity]: "Unit Qty",
	[fields.unitCost]: "Unit Cost",
	[fields.currency]: "Currency",
	[fields.total]: "Total",
	[fields.cashBalance]: "Cash Balance",
};

const tableDisplay = {
	[fields.date]: ({ date }) => { return isNullOrUndefined(date) ? null : display.dateForDisplay(date) },
	[fields.particulars]: ({ incomeTransaction, assetTransaction, nativeCurrency }) => (incomeTransaction && incomeTransaction.ticker) || (assetTransaction && assetTransaction.ticker) || nativeCurrency,
	[fields.typeActivity]: ({ narrative }) => narrative,
	[fields.quantity]: ({ assetTransaction }) => { return <NumberFormat value={assetTransaction ? assetTransaction.units : 0} /> },
	[fields.unitCost]: ({ assetTransaction }) => { return <NumberFormat places={2} prefix="" value={assetTransaction ? assetTransaction.price : 0} /> },
	[fields.currency]: ({ nativeCurrency }) => nativeCurrency,
	[fields.total]: ({ nativeCurrencyValue }) => { return isNullOrUndefined(nativeCurrencyValue) ? null : <NumberFormat places={2} prefix="" value={nativeCurrencyValue} /> },
	[fields.cashBalance]: ({ nativeCurrencyBalance }) => { return isNullOrUndefined(nativeCurrencyBalance) ? null : <NumberFormat places={2} prefix="" value={nativeCurrencyBalance} /> },
};

const OrderHistoryList = ({ transactions, loading, connectionError }) => {
	const [sortedTransactions, setSortedTransactions] = useState(null)

	useEffect(() => {
		setSortedTransactions(transactions)
	}, [transactions])

	if (loading) {
		return <FlexWrapper fullWidth justify="center"><LoadingIcon colour="brand" /></FlexWrapper>;
	} else if (connectionError) {
		return <EmptyStateMessage message="We’re having trouble getting this information right now. Please try again later" />;
	} else {
		return (
			<div className="order-history-list">
				<div style={{ width: "100%", overflow: "auto" }}>
					<table className="simple-table transaction-table" style={{ width: "100%" }}>
						<tbody>
							<tr>
								<TableHeader width={100} value={headers[fields.date]} />
								<TableHeader width={100} value={headers[fields.particulars]} />
								<TableHeader width={160} value={headers[fields.typeActivity]} />
								<TableHeader value={headers[fields.quantity]} alignment="right" />
								<TableHeader value={headers[fields.unitCost]} alignment="right" />
								<TableHeader value={headers[fields.currency]} />
								<TableHeader value={headers[fields.total]} alignment="right" />
								<TableHeader value={headers[fields.cashBalance]} alignment="right" />
							</tr>
						</tbody>
						<tbody>
							{sortedTransactions &&
								sortedTransactions.length > 0 &&
								sortedTransactions.map((transaction, index) => {
									const withdrawal = transaction.action === "Buy" ? true : false
									return (
										<tr key={index}>
											<TableData>{tableDisplay[fields.date](transaction) || "-"}</TableData>
											<TableData>
												<Token>{tableDisplay[fields.particulars](transaction)}</Token>
											</TableData>
											<TableData>{tableDisplay[fields.typeActivity](transaction) || "-"}</TableData>
											<TableData alignment="right">{tableDisplay[fields.quantity](transaction) || "-"}</TableData>
											<TableData alignment="right">{tableDisplay[fields.unitCost](transaction) || "-"}</TableData>
											<TableData alignment="right" className="td-currency">{tableDisplay[fields.currency](transaction) || "-"}</TableData>
											<TableData alignment="right" className={`td-total text-align-right${withdrawal ? " colour-text-danger" : " colour-text-positive"}`}>{tableDisplay[fields.total](transaction) || "-"}</TableData>
											<TableData alignment="right" className={`td-cash-balance text-align-right`}>{tableDisplay[fields.cashBalance](transaction) || "-"}</TableData>
										</tr>
									)
								})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default OrderHistoryList;

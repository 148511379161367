import './App.scss';
import "./styles/UtilClasses.scss";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginWrapper from './components/pages/login/LoginWrapper';
import LoginPage from './components/pages/login/pages/LoginPage';
import ForgotPasswordPage from './components/pages/login/pages/ForgotPasswordPage';
import OnboardingWrapper from './components/pages/onboarding/OnboardingWrapper';
import PortalWrapper from './components/pages/portal/PortalWrapper';
import SettingsPage from './components/pages/portal/pages/SettingsPage';
import PortfolioPage from './components/pages/portal/pages/PortfolioPage';
import OrdersPage from './components/pages/portal/pages/OrdersPage';
import TransactionsPage from './components/pages/portal/pages/TransactionsPage';
import ContactPage from './components/pages/portal/pages/ContactPage';
import ErrorPage from './components/pages/portal/pages/ErrorPage';
import OnboardingBasicInfo from './components/pages/onboarding/pages/OnboardingBasicInfo';
import OnboardingDetailedInfo from './components/pages/onboarding/pages/OnboardingDetailedInfo';
import OnboardingComplete from './components/pages/onboarding/pages/OnboardingComplete';
import OnboardingBankVerification from './components/pages/onboarding/pages/OnboardingBankVerification';
import OrderHistoryPage from './components/pages/portal/pages/OrderHistoryPage';
import { ThreeSkyeWrapper, withThreeSkyeGlobal } from "@threeskye/global";
import { Component, useEffect, useState } from 'react';
import HandleBankVerification from './components/pages/auxiliary/HandleBankVerification';
import HandleEmailVerification from './components/pages/auxiliary/HandleEmailVerification';
import { useRemote } from './components/utils/ApiUtils';

function App() {

	const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("3skye.loggedin") === "true");

	const remote = useRemote();

	useEffect(() => {
		remote.registerLogoutHandler(logout)
	},[remote])

	const login = () => {
		setLoggedIn(true);
		sessionStorage.setItem("3skye.loggedin", "true");
	}

	const logout = () => {
		sessionStorage.setItem("3skye.loggedin", "false");
		setLoggedIn(false);
	}

	return (
		<div className="App">
			<div id="master-container">
				<BrowserRouter>
					{/* Pages */}
					<Routes>
						{/* Onboarding pages */}
						<Route path="/onboarding" element={<OnboardingWrapper />}>
							<Route index element={<OnboardingBasicInfo />} />
							<Route path=":uuid" element={<OnboardingDetailedInfo />} />
							<Route path="detailed-info" element={<OnboardingDetailedInfo />} />
							<Route path="bank-acc-verification" element={<OnboardingBankVerification />} />
							<Route path="complete" element={<OnboardingComplete />} />
						</Route>
						<Route path="/akahu/oauth" element={<HandleBankVerification />}></Route>
						<Route path="/verify/:uuid/:code" element={<HandleEmailVerification />}></Route>
						{/* Login pages */}
						{/* <Route path="/login" element={<LoginWrapper />}>
							<Route index element={<LoginPage />} />
							<Route path="forgot-password" element={<ForgotPasswordPage />} />
						</Route> */}
						{/* Portal pages */}
						{loggedIn
							? <Route path="/" element={<PortalWrapper logout={logout} />}>
								<Route index element={<PortfolioPage />} />
								<Route path="orders" element={<OrdersPage />} />
								<Route path="orders/:ticker/:action" element={<OrdersPage />} />
								<Route path="order-history" element={<OrderHistoryPage />} />
								<Route path="transactions" element={<TransactionsPage />} />
								<Route path="contact-us" element={<ContactPage />} />
								<Route path="settings" element={<SettingsPage />} />
								<Route path="*" element={<ErrorPage />} />
							</Route>
							: <Route path="/" element={<LoginWrapper />}>
								<Route index element={<LoginPage login={login} />} />
								<Route path="verified" element={<LoginPage login={login} />} />
								<Route path="forgot-password" element={<ForgotPasswordPage />} />
							</Route>}
					</Routes>
				</BrowserRouter>
			</div>
		</div>
	);
}

class Wrapped extends Component {
	render() {
		const With = withThreeSkyeGlobal(App);
		return (
			<ThreeSkyeWrapper disableI18n={true}>
				<With />
			</ThreeSkyeWrapper>
		);
	}
}

export default Wrapped;
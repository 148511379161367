import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../../buttons/Button';
import TextInput from '../../../inputs/TextInput';
import FlexWrapper from '../../../layout/FlexWrapper';
import Logos from "../../../../resources/images/main-logo.png";
import { useNavigate } from "react-router-dom";
import { withRemote } from "@threeskye/global";
import TwoFAPage from './TwoFAPage';
import { useCookies, withCookies } from 'react-cookie';
import Toast from '../../../misc/Toast';
import { useLocation } from 'react-router';

const LoginPage = ({login : parentLogin, forgotPasswordVisited, remote, cookies: browserCookies}) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [hasFailed, setHasFailed] = useState(false);
	const [message, setMessage] = useState("");
	const [loggingIn, setLoggingIn] = useState(false);
	const [twoFAKey, setTwoFAKey] = useState("");
	const [twoFAMessage, setTwoFAMessage] = useState("");
	let navigate = useNavigate();

	const [cookies, setCookie, removeCookie] = useCookies([]);

	const [openVerifiedToaster, setOpenVerifiedToaster] = useState(false);

	const location = useLocation()

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		return;
		}
		setOpenVerifiedToaster(false);
	};

	useEffect(() => {
		if (location.pathname === "/verified"){
		setOpenVerifiedToaster(true)
	}}, [location])

	const login = (e) => {
		e.preventDefault();
		setHasFailed(false);
		setLoggingIn(true);

		const data = { username: email, password: password};
		const twofa = has2fa();
		if (twofa) {
			data.twoFAToken = twofa;
		}
		remote.post("/um/stockfox/login", data).then((response) => {
			if (response.success) {
				setLoggingIn(true);
				parentLogin();
			} else if (response.message === "2fa-failure") {
				setTwoFAKey(response.data.key);
				setTwoFAMessage("We've sent a code to your cellphone. Please enter it here.");
				//TODO - not sure if we're doing 2fa for sf?
			} else if (response.message == "account-locked") {
				//TOOD  - handle
			} else {
				setHasFailed(true);
				setMessage(" Invalid username or password, please try again")
				setLoggingIn(false);
			}
			// 	this.setState({ loading: false, loggingInOnMount: false }, () => this.props.login());
			// } else if (response.message === "2fa-failure") {
			// 	this.setState({ loading: false, loggingInOnMount: false, show2fa: true, twoFAKey: response.data.key });
			// } else if (response.message === "account-locked") {
			// 	this.setState({ loading: false, loggingIn: false, loggingInOnMount: false, show2fa: false, message: "This account is locked, please contact your Hobson Wealth adviser." });
			// } else {
			// 	this.setState({ loading: false, loggingInOnMount: false, loggingIn: false, message: "Could not log in. Please try again." });
			// }
		});
	}

	const has2fa = () => {
		let username = email.replace("@", "#");
		let cookie = cookies["3skye.login." + username];
		const cookie2 = browserCookies.get('3skye.login.' + username);
		// if (typeof cookie === "undefined") {
		// 	cookie = cookies.get("sf.login." + username);
		// }
		if (typeof cookie2 === "undefined") {
			return false;
		}

		return cookie2;
	}

	const check2fa = (code) => {
		const data = {
			username: email,
			code,
			key: twoFAKey,
		};

		remote.post("/um/validate-credentials-2fa", data).then(
			(resp) => {
				if (resp.status === "valid") {
					const cookieValue = has2fa();//resp.cookie;

					let username = email.replace("@", "#");
					const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
					setCookie("3skye.login." + username, cookieValue, { path: "/", expires: oneYear, secure: true });
					// if (this.state.twofaSetup) {
					//We're already logged in, so just store the cookie and carry on
					// this.setState({ twofaCompleted: true, showPostLogin: true });
					// } else {
					//It's an actual login
					const data2 = {
						username: email,
						password: password,
						twoFAToken: cookieValue,
						source: "tomato"
					};

					remote.post("/um/sf/login", data2, { rawResponse: true }).then(
						(x) => {

							//TODO - sf check setup.
							parentLogin();
//							this.props.login();
						},
						(x) => {
							setHasFailed(true);
							setTwoFAKey("");
							setMessage("We couldn't log you in ... please try again");
						}
					);
				}
			},
			(x) => {
				setTwoFAMessage("Oops, that code wasn't right.  Please try again" );
			}
		);
	}

	if (twoFAKey !== "") {
		return <TwoFAPage onSubmit={check2fa} twoFAMessage={twoFAMessage}/>
	}

	return (
		<form>
			{/* Toast for email verified */}
			<Toast
				open={openVerifiedToaster}
				message={<div>Email successfully verified<br />You're all ready to log in</div>}
				type="positive"
				handleClose={handleClose}
			/>
			<Grid container spacing={3} className={`transition-fade-in${forgotPasswordVisited ? "-right" : ""}`}>
				<Grid item xs={12}>
					<p className="m-none font-weight-semi-bold">Welcome back</p>
				</Grid>
				<Grid item xs={12}>
					<div className="title colour-text-black">Sign In</div>
				</Grid>
				{/* Fields */}
				<Grid item xs={12}>
					<TextInput required valid={email} label="Email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<TextInput required valid={password} label="Password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
				</Grid>
				{hasFailed && <Grid item xs={12}>
					<p className="colour-text-danger">
						{message}
					</p>
				</Grid>}
				<Grid item xs={12}>
					<FlexWrapper justify="space-between" gap="s">
						<p className="m-none colour-text-lm-dark-grey text-link" onClick={() => navigate("forgot-password")}>
							Forgot Password
						</p>
						<p className="m-none colour-text-lm-dark-grey text-link" onClick={() => navigate("/onboarding")}>
							Disclaimer
						</p>
					</FlexWrapper>
				</Grid>
				<Grid item xs={12}>
					<Button loading={loggingIn} disabled={!email || !password} fullWidth onClick={login}>
						Sign In
					</Button>
				</Grid>
				<Grid item xs={12} container justifyContent="center">
					<img alt="3skye" src={Logos} style={{ width: 100 }} className="mt-xl" />
				</Grid>
			</Grid>
		</form>
	);
}

export default withCookies(withRemote(LoginPage));
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Divider from '../../../layout/Divider';
import FlexWrapper from '../../../layout/FlexWrapper';
import NavigationLinks, { NavigationLinkButton } from './NavigationLinks';
import "./SidebarNav.scss";
import Logos from "../../../../resources/images/main-logo.png";
import { CashBalance, PortfolioValuation } from '../../../misc/AccountBalances';
import { useRemote, useStorage } from '../../../utils/ApiUtils'
import NumberFormat from '../../../utils/NumberFormat';

const SidebarNav = ({ links, activePage, onLinkClick, logout, user }) => {
	const remote = useRemote()
	const storage = useStorage()
	const [account, setAccount] = useState(null)
	const [cashBalance, setCashBalance] = useState(0)
	const [valuation, setValuation] = useState(0)

	useEffect(() => {
		storage.getOrFetch(`/modules/crm/client/accounts?dynamicFields=CustodyType`)
			.then((accounts) => {
				setAccount(accounts[0])
				storage.getOrFetch(`/modules/crm/accounts/${accounts[0].id}/portfolio`)
					.then(portfolio => {
						setValuation(portfolio.data.valuation)
					})
			})
			storage.getOrFetch('/modules/hobson-support/stockfox/cash-balance').then(resp => {
			if (resp.balance) {
				setCashBalance(resp.balance);
			}
		});
	}, [remote, storage])

	return (
		<div id="side-bar-wrapper">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h1>Hi, {account && account.primary.givenName}</h1>
				</Grid>
				{/* Valuation/Balance */}
				<Grid item xs={12}>
					<FlexWrapper gap="m" wrap>
						<Divider dashed spacing="none" />
						<PortfolioValuation value={<NumberFormat places={2} value={valuation} prefix="$" />} currency={"NZD"} />
						<CashBalance value={<NumberFormat places={2} value={cashBalance || 0} prefix="$" />} currency={"NZD"} />
						<Divider dashed spacing="none" />
					</FlexWrapper>
				</Grid>
				{/* Navigation Buttons */}
				<Grid item xs={12}>
					<NavigationLinks links={links} activePage={activePage} onLinkClick={onLinkClick} />
				</Grid>
				<Grid item xs={12}>
					<Divider dashed className="spacing-top-none spacing-bottom-m" />
					<NavigationLinkButton label="Log Out" path="/" onLinkClick={logout} />
				</Grid>
			</Grid>
			<Grid item xs={12} container justifyContent="flex-start">
				<img alt="3skye" src={Logos} style={{ width: 100, marginTop: "3rem" }} />
			</Grid>
		</div>
	);
}

export default SidebarNav;
import React from 'react';
import CheckboxGroup from '../inputs/CheckboxGroup';

const ActionTypeFilterCheck = ({ setActionBuy, actionBuy, setActionSell, actionSell }) => {
	return (
		<CheckboxGroup
			label="Action"
			checkboxes={[
				{
					label: "Buy",
					onChange: setActionBuy,
					checked: actionBuy
				},
				{
					label: "Sell",
					onChange: setActionSell,
					checked: actionSell
				},
			]}
		/>
	);
}

export default ActionTypeFilterCheck;
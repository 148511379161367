import { Backdrop, Fade, Grid, Modal as MuiModal } from '@material-ui/core';
import React from 'react';
import { X } from 'react-feather';
import "./Modal.scss";

const Modal = ({ open, handleClose, children, header, className, width, spacing, hideClose }) => {
	return (
		<MuiModal
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			open={open}
			onClose={handleClose}
			className={`modal-outer-wrapper${className ? ' ' + className : ""}`}
			closeAfterTransition
		>
			<Fade in={open}>
				<div className="modal-wrapper" style={{width: width}}>
					{!hideClose && <X className='modal-close-icon' size={20} onClick={handleClose} />}
					<Grid item container spacing={spacing ? spacing : 3}>
						{header && (
							<Grid item xs={12}>
								<h1>{header}</h1>
							</Grid>
						)}
						{children}
					</Grid>
				</div>
			</Fade>
		</MuiModal>
	);
};
export default Modal;
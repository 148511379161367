import React from 'react';
import { Outlet } from 'react-router-dom';

const LoginWrapper = () => {
	return (
		<div className="transition-fade-up-slow p-m" alignitems="flex-end" style={{ maxWidth: 448, margin: "0 auto" }}>
			<Outlet />
		</div>
	);
}

export default LoginWrapper;
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import OnboardingPersonalDetails from '../sections/OnboardingPersonalDetails';
import OnboardingFatcaTax from '../sections/OnboardingFatcaTax';
import OnboardingOccupation from '../sections/OnboardingOccupation';
import GridItemDivider from '../../../layout/GridItemDivider';
import OnboardingAml from '../sections/OnboardingAml';
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import { Loading } from '@threeskye/global/dist/ThreeSkyeGlobal';

const OnboardingDetailedInfo = () => {
	const [prescribedPerson, setPrescribedPerson] = useState();

	const [clientSetup, setClientSetup, setNextValid, remote, setNextFunc] = useOutletContext();

	const [loading, setLoading] = useState(false);

	const params = useParams();

	const [details, setDetails] = useState({});
	// const [valid, setValidState] = useState();
	const [personalValid, setPersonalValid] = useState(false);
	const [prescribedValid, setPrescribedValid] = useState(false);
	const [fatcaValid, setFatcaValid] = useState(false);
	const [occupationValid, setOccupationValid] = useState(false);
	const [amlValid, setAmlValid] = useState(false);

	const nav = useNavigate();

	useEffect(() => {
		if (params && params.uuid) {
			if (!clientSetup || (params.uuid !== clientSetup.uuid)) {
				setLoading(true);
				remote.get("/public/modules/hobson-support/stockfox/client/" + params.uuid).then(result => {
					if (result.success) {
						setClientSetup(result.data);
						setDetails(result.data);
						setLoading(false);
					} else {
						nav("/onboarding");
					}
				});
			} else {
				setDetails(clientSetup);
			}
		} else {
			if (!clientSetup || !clientSetup.uuid) {
				nav("/onboarding");
			} else {
				setDetails(clientSetup);
			}
		}
	}, [clientSetup, params]);

	const mergeDetails = (deets) => {
		const current = details;
		Object.assign(current, deets);
		setDetails(current);
		if (current.uuid) {
			save(current);
		}
	}

	useEffect(() => {
		if (!clientSetup) {
			return;
		}
		setNextValid(personalValid && prescribedValid && fatcaValid && occupationValid && amlValid);
		setNextFunc({
			url: "/public/modules/hobson-support/stockfox/client/" + clientSetup.uuid,
			method: 'put',
			payload: details,
			listeners: [() => { remote.post(`/public/modules/hobson-support/stockfox/client/${clientSetup.uuid}/complete`) }, () => { nav("/onboarding/complete") }]
		}, []
		);
	}, [details, clientSetup, personalValid, prescribedValid, fatcaValid, occupationValid, amlValid]);

	const save = (deets) => {
		if (!clientSetup || !clientSetup.uuid) {
			return;
		}
		remote.put("/public/modules/hobson-support/stockfox/client/" + clientSetup.uuid, deets);
	}


	if (params && params.uuid && clientSetup && !params.uuid === clientSetup.uuid) {
		return <Loading />
	}
	return (
		<form>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<div className="title">Welcome!</div>
				</Grid>
				<Grid item xs={12}>
					<h1>Enter your details</h1>
				</Grid>
			</Grid>
			{/* Personal Fields. The prescribed radio buttons live inside the component below. */}
			<OnboardingPersonalDetails handlePrescribedPerson={(e) => setPrescribedPerson(e)} clientSetup={clientSetup} setPersonal={mergeDetails} setPrescribedValid={setPrescribedValid} setPersonalValid={setPersonalValid} loading={loading} />
			<GridItemDivider />
			{/* Hide/display other sections depending on prescribed status */}
			{prescribedPerson !== "Yes" && (
				<>
					<OnboardingFatcaTax clientSetup={clientSetup} setFatcaTax={mergeDetails} setComponentValid={setFatcaValid} loading={loading} />
					<GridItemDivider clientSetup={clientSetup} />
					<OnboardingOccupation clientSetup={clientSetup} setOccupation={mergeDetails} setComponentValid={setOccupationValid} loading={loading} />
					<GridItemDivider clientSetup={clientSetup} />
					<OnboardingAml clientSetup={clientSetup} setAML={mergeDetails} setComponentValid={setAmlValid} loading={loading} />
				</>
			)}
		</form>
	);
}

export default OnboardingDetailedInfo;
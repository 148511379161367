import { cloneElement } from "react";
import FlexWrapper from "../layout/FlexWrapper";
import Tooltip from "../layout/Tooltip";
import AssetOption from "../misc/ticker-assets/AssetOption";
import "./CustomSelectOptions.scss";
import { components } from "react-select";
import BankAccountOption from "../misc/BankAccountOption";

export const optionWithTooltip = ({ label, icon, tooltip, isDisabled }) => (
	<FlexWrapper gap="s" className={isDisabled ? "custom-option-disabled" : ""}>
		<div className="custom-option-label">{label ? label : ""}</div> <Tooltip title={tooltip ? tooltip : ""}>{icon ? cloneElement(icon, { className: icon.props.className + " custom-option-icon" }) : <></>}</Tooltip>
	</FlexWrapper>
);

export const assetOption = ({ value, label, code, img }) => <AssetOption value={value} name={label} code={code} logo={img} />;

export const bankAccountOption = ({ label, currency, bankName, accountNumber }) => <BankAccountOption label={label} bankName={bankName} currency={currency} accountNumber={accountNumber}  />;

export const ShortLabel = props => (
	<components.SingleValue {...props}>
	  {props.data.shortLabel}
	</components.SingleValue>
);
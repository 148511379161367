import React from 'react';
import FlexWrapper from '../layout/FlexWrapper';
import LabelledValue from '../pages/portal/shared/LabelledValue';
import NumberFormat from '../utils/NumberFormat';
import "./OrderListTotals.scss";

const OrderListTotals = ({estimatedTotal, estimatedNewBalance}) => {
	return (
		<FlexWrapper gap="m" wrap className="order-list-totals">
			<LabelledValue fullWidth align="right" label={estimatedTotal < 0 ? "Estimated Total Value (NZD)" :"Estimated Total Cost (NZD)"} value={<NumberFormat value={estimatedTotal < 0 ? estimatedTotal * -1 : estimatedTotal} places={2} prefix="$" />} />
			<LabelledValue fullWidth align="right" label="Estimated New Balance (NZD)" value={<NumberFormat value={estimatedNewBalance} places={2} prefix="$" />} />
		</FlexWrapper>
   );
}

export default OrderListTotals;
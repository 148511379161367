import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Modal from '../../../layout/Modal';
import OrderPlacedMessage from '../../../messages/OrderPlacedMessage';
import OrderPad from '../sections/OrderPad';
import { useRemote } from '../../../utils/ApiUtils'
import OrderList from '../sections/OrderList'
import Button from '../../../buttons/Button';
import LimitOrderMessage from '../../../messages/LimitOrderMessage';
import { useNavigate } from 'react-router-dom';

const OrdersPage = () => {
	const [orderType, setOrderType] = useState("Market");
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const [marketOrders, setMarketOrders] = useState([])
	const [cashBalance, setCashBalance] = useState(0)
	const [estimatedTotalBalance, setEstimatedTotalBalance] = useState(null)
	const [estimatedTotalValue, setEstimatedTotalValue] = useState(null)
	const [account, setAccount] = useState(null)

	const remote = useRemote()
	const navigate = useNavigate()

	useEffect(() => {
		remote.get('/modules/hobson-support/stockfox/cash-balance').then(resp => {
			if (resp.balance) {
				setCashBalance(resp.balance);
			}
		});
		remote.get(`/modules/crm/client/accounts?dynamicFields=CustodyType`)
			.then((accounts) => {
				setAccount(accounts[0])
			});
	}, [remote])

	useEffect(() => {
		let estimatedTotalCost = 0;
		marketOrders.forEach((order) => {
			if (order.action === "buy") {
				estimatedTotalCost = estimatedTotalCost + order.estimatedCost
			} else {
				estimatedTotalCost = estimatedTotalCost - order.estimatedCost
			}
		})
		let newTotalBalance = cashBalance - estimatedTotalCost
		setEstimatedTotalBalance(newTotalBalance)
		setEstimatedTotalValue(estimatedTotalCost)
	}, [marketOrders, cashBalance])

	const handlePlaceOrder = () => {
		const orders = marketOrders.map((order) => order.DTO)
		placeOrder(orders)
		// setOpen(true)
		navigate("/order-history")
	}

	const placeOrder = (orders) => {
		remote.post(`/modules/hobson-support/orders/${account.id}`, orders)
	}

	return (
		<>
			<Modal open={open} handleClose={handleClose}>
				<OrderPlacedMessage orderType={orderType} handleClose={handleClose} />
			</Modal>
			<Grid item container spacing={3} className="transition-fade-up-slow">
				<Grid item xs={12}>
					<div className="title colour-text-black">Place Order</div>
				</Grid>
				{/* Ordering pad */}
				<Grid item xs={12}>
					<OrderPad orderType={orderType} onTypeChange={(value) => setOrderType(value)} cashBalance={cashBalance} marketOrders={marketOrders} setMarketOrders={setMarketOrders} estimatedTotalBalance={estimatedTotalBalance} account={account} placeOrder={placeOrder} />
				</Grid>
				{/* Ordering List */}
				<Grid item xs={12}>
					<h1>Orders List</h1>
				</Grid>
				<Grid item xs={12}>
					<OrderList marketOrders={marketOrders} setMarketOrders={setMarketOrders} estimatedTotalBalance={estimatedTotalBalance} estimatedTotalValue={estimatedTotalValue} />
				</Grid>
				{orderType === "Limit" && (
					<Grid item xs={12} className="transition-fade-up-slow">
						<LimitOrderMessage justify="flex-end" />
					</Grid>
				)}
				<Grid item xs={12} container justifyContent="flex-end">
					<Button onClick={() => handlePlaceOrder()}>Place Orders</Button>
				</Grid>
			</Grid>
		</>
	);
}

export default OrdersPage;
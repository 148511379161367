import React from 'react';
import { Info } from 'react-feather';
import NotificationItem from '../buttons/NotificationItem';
import FlexWrapper from '../layout/FlexWrapper';
import "./MobileNotificationMenu.scss";

const MobileNotifications = ({notifications, handleClose}) => {
	return (
		<div className="mobile-notifications-wrapper">
			<FlexWrapper className="menu-header" justify="space-between">
				<h2>Notifications</h2>
				<p className="colour-text-brand">Mark all as read</p>
			</FlexWrapper>
			{notifications && notifications.length > 0 ? (
				notifications.map((notif, idx) => <NotificationItem onClick={handleClose} key={idx} notification={notif} />)
			) : (
				<FlexWrapper justify="center" align="center" className="p-l" gap="s">
					<Info size={16} className="colour-text-brand" /><p>No notifications to display yet</p>
				</FlexWrapper>
			)}
		</div>
	);
}

export default MobileNotifications;
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useRemote } from '../../utils/ApiUtils';
import Logos from "../../../resources/images/main-logo.png";
import FlexWrapper from '../../layout/FlexWrapper';
import { AlertOctagon, CheckCircle } from 'react-feather';
import LoadingIcon from '../../misc/LoadingIcon';
import Button from '../../buttons/Button';

const HandleEmailVerification = () => {

	const params = useParams();
	const navigate = useNavigate();
	const remote = useRemote();
	const [response, setResponse] = useState({});
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const { uuid, code } = params;

		remote.post(`/public/modules/hobson-support/stockfox/client/${uuid}/verify-email/${code}`).then(json => {
			setResponse(json);
			setLoading(false)
			if (json && json.success === true) {
				navigate("/verified");
			} 

		});
	}, [params])

	// Fail
	if ( !loading && (!response || response.success === false)) {
		return (
			<div className="transition-fade-up-slow p-m" alignitems="flex-end" style={{ maxWidth: 500, margin: "0 auto" }}>
				<Grid container spacing={3} className="transition-fade-in-left" justifyContent="center">
					<Grid item xs={12} container justifyContent='center'>
						<FlexWrapper gap="m">
							<div className="title colour-text-black">Unable to verify your email</div>
							<AlertOctagon className="colour-text-danger" />
						</FlexWrapper>
					</Grid>
					<Grid item xs={12}>
						<p className="text-align-center">We were unable to verify your email address. Please try again.</p>
					</Grid>
					<Grid item>
						{/* TODO hook up button */}
						<Button onClick={() => {navigate("/onboarding")}} >Start Over</Button>
					</Grid>
					<Grid item xs={12} container justifyContent="center">
						<img src={Logos} style={{ width: 100 }} className="mt-xl" alt="3skye" />
					</Grid>
				</Grid>
			</div>
		);

		// Loading or Success (will redirect on Success)
	} else {
		return (
			<div className="transition-fade-up-slow p-m" alignitems="flex-end" style={{ maxWidth: 500, margin: "0 auto" }}>
				<Grid container spacing={3} className="transition-fade-in-left">
					<Grid item xs={12} container justifyContent="center">
						<FlexWrapper gap="m">
							<div className="title colour-text-black">Verifying email details</div>
							<LoadingIcon size={24} colour="brand" />
						</FlexWrapper>
					</Grid>
					<Grid item xs={12}>
						<p className="text-align-center">Please wait a moment while we verify your email</p>
					</Grid>
					<Grid item xs={12} container justifyContent="center">
						<img src={Logos} style={{ width: 100 }} className="mt-xl" alt="3skye" />
					</Grid>
				</Grid>
			</div>
		);
	}

}

export default HandleEmailVerification;
import React from 'react';
import Select from 'react-select';
import { ChevronDown, X } from 'react-feather';
import TextFieldLabel from './TextFieldLabel';
import "./SelectInput.scss";
import ValidationIcons from './ValidationIcons';
import InputHelperText from './InputHelperText';

const SingleSelectInput = ({ helperText, label, infoIcon, required, valid, invalid, hideDropdownIndicator, showSeparator, clearValue, isClearable, components, width, isSearchable, disabled, ...props }) => {
	// component overrides
	const customComponents = {
		IndicatorSeparator: () => (isClearable || clearValue) && !hideDropdownIndicator ? <div className="select-input-separator" /> : null,
		ClearIndicator: () => <X onClick={clearValue} />,
		DropdownIndicator: () => (hideDropdownIndicator ? null : <ChevronDown />),
	};

	return (
		<div className="select-input-wrapper" style={{width: width}}>
			{label && (
				<TextFieldLabel>
					{label}
					<ValidationIcons required={required} valid={valid} infoIcon={infoIcon} />
				</TextFieldLabel>
			)}
			<Select
				{...props}
				isSearchable={isSearchable}
				isClearable={clearValue || !required}
				components={{ ...customComponents, ...components }}
				className={`select-input${invalid ? " select-input-invalid" : ""}`}
				classNamePrefix="single-select-input"
				menuPortalTarget={document.body} 
				styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
				isDisabled={disabled}
			/>
			{helperText && <InputHelperText valid={valid} invalid={!valid}>{helperText}</InputHelperText>}
		</div>
	);
}

export default SingleSelectInput;
import React from 'react';
import { Check, Info } from 'react-feather';
import Tooltip from '../layout/Tooltip';
import "./ValidationIcons.scss";

const ValidationIcons = ({required, valid, infoIcon}) => {
	return (
		<>
			{required && valid ? (
				<Check className="validation-icon valid-icon" />
			) : infoIcon ? (
				<Tooltip title={infoIcon}>
					<Info className="validation-icon info-icon" />
				</Tooltip>
			) : (
				""
			)}
		</>
	);
}
export default ValidationIcons;
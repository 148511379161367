import React from 'react';
import FlexWrapper from '../../../layout/FlexWrapper';
import { useNavigate } from "react-router-dom";
import "./NavigationLinks.scss";

export const NavigationLinkButton = ({ label, path, active,onLinkClick }) => {
	let navigate = useNavigate();
	return (
		<div onClick={() => {navigate(path);onLinkClick && onLinkClick();}} className={`nav-link-button${active ? " active-link" : ""}`}>
			{label}
		</div>
	)
}

const NavigationLinks = ({links, activePage, onLinkClick}) => {
	return (
		<FlexWrapper gap="xs" wrap onClick={onLinkClick}>
			{links &&
				links.length > 0 &&
				links.map((link, idx) => (
					<NavigationLinkButton key={idx} path={link.path} active={link.path === activePage} label={link.label} />
				))}
		</FlexWrapper>
	);
}

export default NavigationLinks;
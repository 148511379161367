import React from 'react';

const LabelledValue = ({label, value, className, align, fullWidth, valueClassName}) => {
	return (
		<div className={`labelled-value-wrapper${className ? ' ' + className : ''}${align ? " text-align-" + align : ""}`}  style={{width: fullWidth ? "100%" : ""}}>
			<p className="label mt-none mb-xs colour-text-dark-green" style={{fontSize: 12}}>{label}</p>
			<h2 className={`value${valueClassName ? " " + valueClassName : ""}`}>{value}</h2>
		</div>
   );
}

export default LabelledValue;
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { bankAccounts, dummyCash } from '../../resources/DummyData';
import Button from '../buttons/Button';
import { bankAccountOption } from '../inputs/CustomSelectOptions';
import SingleSelectInput from '../inputs/SingleSelectInput';
import Divider from '../layout/Divider';
import FlexWrapper from '../layout/FlexWrapper';
import Modal from '../layout/Modal';
import LabelledValue from '../pages/portal/shared/LabelledValue';
import OrderFieldRow from '../tables/OrderFieldRow';
import useWindowDimensions from '../utils/DisplayUtils';
import { useStorage, useRemote } from '../utils/ApiUtils';
import NumberFormat from '../utils/NumberFormat';

const WithdrawFundsModal = ({ handleClose, open, accountId, cashAssets }) => {
	const [bankAccount, setBankAccount] = useState("");
	const [bankOptions, setBankOptions] = useState([])
	const [NZDWithdrawalAmount, setNZDWithdrawalAmount] = useState(null)
	const [AUDWithdrawalAmount, setAUDWithdrawalAmount] = useState(null)
	const [accountDetails, setAccountDetails] = useState(null)
	const [conversionRate, setConversionRate] = useState(1.1)
	const [formValid, setFormValid] = useState(false)

	const { width } = useWindowDimensions();
	const isMobile = width < 1000;
	const storage = useStorage()
	const remote = useRemote()
	const AUD = cashAssets && cashAssets.find((cashAsset) => cashAsset.tickerCode === "Cash AUD")
	const NZD = cashAssets && cashAssets.find((cashAsset) => cashAsset.tickerCode === "Cash NZD")

	const AUDConvertedToNZD = AUDWithdrawalAmount * conversionRate
	const totalWithdrawal = (NZDWithdrawalAmount ? parseInt(NZDWithdrawalAmount) : 0) + (AUDConvertedToNZD ? parseInt(AUDConvertedToNZD) : 0)

	useEffect(() => {
		storage.getOrFetch("/modules/crm/provider/accounts/" + accountId).then((account) => {
			const banks = account && account.bankAccounts && account.bankAccounts
			setAccountDetails(banks)
			let selectOptions = banks
				? banks.map((bankAccount) => {
					const { accountNumber, currency, bank } = bankAccount.content
					return { label: bankAccount.name && bankAccount.name, bankName: bank && bank, currency: currency && currency, accountNumber: accountNumber && accountNumber, value: bankAccount }
				})
				: []
			setBankOptions(selectOptions)
		})

	}, [accountId, cashAssets, storage])

	useEffect(() => { setFormValid(checkValid()) }, [bankAccount, NZDWithdrawalAmount, AUDWithdrawalAmount])

	const checkValid = () => {
		//NZD balance >= 0 
		// if (NZD && (NZD.value - NZDWithdrawalAmount) < 0) {
		// 	if(NZD.value >= 0) {console.log("test1 failed => ", NZD.value, NZD.value-NZDWithdrawalAmount); return false}
		// }
		//AUD balance >= 0
		// if (AUD && (AUD.value - AUDWithdrawalAmount) < 0) {
		// 	if(NZD.value >= 0) {console.log("test2 failed => ", AUD.value, AUD.value-AUDWithdrawalAmount); return false}
		// }
		//withdrawal amount is > 0
		if (totalWithdrawal < 0) return false
		//destination bank is valid
		if (!bankAccount) return false
		return true
	}

	const sendWithdrawRequest = () => {
		const NZDRequest = NZDWithdrawalAmount && NZDWithdrawalAmount > 0 ? { amount: NZDWithdrawalAmount, currency: "NZD", estimatedWithdrawal: NZDWithdrawalAmount, bankAccount: bankAccount.value.content } : null
		const AUDRequest = AUDWithdrawalAmount && AUDWithdrawalAmount > 0 ? { amount: AUDWithdrawalAmount, currency: "AUD", estimatedWithdrawal: AUDConvertedToNZD, bankAccount: bankAccount.value.content } : null
		let DTA = [];
		NZDRequest && DTA.push(NZDRequest)
		AUDRequest && DTA.push(AUDRequest)
		if (DTA.length > 0) {
			remote.post(`/modules/hobson-support/withdrawals/${accountId}`, DTA)
		}
		handleClose()
	}

	return (
		<Modal open={open} handleClose={handleClose} header="Withdraw Funds" className="withdraw-funds-modal" spacing={3}>
			{/* Order fields */}
			<Grid item container spacing={3} justifyContent="space-between">
				<Grid item xs={12} style={{ paddingTop: 0 }}>
					{/* <p className="sub-label">*Estimated</p> */}
				</Grid>
				{!isMobile && (
					<>
						<Grid item xs="auto">
							<p style={{ display: "inline-block" }}>Cash Balance</p>
						</Grid>
						<Grid item xs="auto">
							<p style={{ display: "inline-block" }}>Amount</p>
						</Grid>
					</>
				)}
				<Grid item xs={12}>
					<Divider dashed spacing="none" colour="dark-green" />
				</Grid>
				<Grid item container alignItems="center" spacing={1}>
					<OrderFieldRow autoFocus balance={(NZD && NZD.value) || 0} currency={"NZD"} balanceLabel="New Balance*" onChange={(e) => setNZDWithdrawalAmount(e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<Divider dashed spacing="none" colour="dark-green" />
				</Grid>
				{AUD &&
					<>
						<Grid item container alignItems="center" spacing={1}>
							<OrderFieldRow balance={(AUD && AUD.value) || 0} currency={"AUD"} balanceLabel="New Balance*" onChange={(e) => setAUDWithdrawalAmount(e.target.value)} />
						</Grid>
						<Grid item xs={12}>
							<Divider dashed spacing="none" colour="dark-green" />
						</Grid>
					</>
				}
				<Grid item xs={12}>
					<FlexWrapper gap="l" wrap>
						<LabelledValue className="text-align-right" fullWidth label="Total Withdrawal (NZD)*" value={<NumberFormat places={2} value={totalWithdrawal} />} valueClassName={totalWithdrawal >= 0 ? "colour-text-positive" : "colour-text-danger"} />
						<Divider dashed spacing="none" colour="dark-green" />
					</FlexWrapper>
				</Grid>
				<Grid item xs={12}>
					<SingleSelectInput
						required
						formatOptionLabel={bankAccountOption}
						label="Destination Bank Account"
						placeholder="Select account"
						value={bankAccount}
						options={bankOptions}
						onChange={(value) => setBankAccount(value)}
						blurInputOnSelect
						isSearchable={false}
					/>
				</Grid>
				<Grid item xs={12}>
					<FlexWrapper gap="m" justify="flex-end">
						<Button variant="dark" onClick={handleClose}>
							Cancel
						</Button>
						<Button disabled={!formValid} onClick={() => sendWithdrawRequest()} >Confirm Withdrawal</Button>
					</FlexWrapper>
				</Grid>
			</Grid>
		</Modal>
	);
}

export default WithdrawFundsModal;
import React, { useState, useEffect } from 'react';
import PortalCard from '../../../layout/PortalCard';
import OrderPadBuy from './OrderPadBuy';
import { useParams } from 'react-router';

const OrderPad = ({ orderType, onTypeChange, marketOrders, setMarketOrders, estimatedTotalBalance, account, cashBalance }) => {
	const [activeTab, setActiveTab] = useState("buy")
	const { action } = useParams()

	useEffect(() => {
		if (action) {
			setActiveTab(action)
		}
	}, [action])

	return (
		<PortalCard
			tabs={[
				{ label: "Buy", onClick: () => setActiveTab("buy"), active: activeTab === "buy" },
				{ label: "Sell", onClick: () => setActiveTab("sell"), active: activeTab === "sell" },
			]}
		>
			<OrderPadBuy account={account} cashBalance={cashBalance} action={activeTab} orderType={orderType} onTypeChange={onTypeChange} marketOrders={marketOrders} setMarketOrders={setMarketOrders} estimatedTotalBalance={estimatedTotalBalance} />
		</PortalCard>
	);
}
export default OrderPad;
import IrdValidator from "ird-nz";
import Phone from 'phone'
import { format, isBefore, isValid, addDays } from 'date-fns'
import { isNullOrUndefined } from "./commonFunctions";
import moment from 'moment'

const monthNames = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

const capitalise = word => (word === null || word === "" || word === undefined ? "" : word.charAt(0).toUpperCase() + word.slice(1));

export const display = {
	titleCase: (str) => {
		if (!str) return ""
		return str.toLowerCase().split(' ').map(function(word) {
		  return (word.charAt(0).toUpperCase() + word.slice(1));
		}).join(' ');
	  },
	dateForDTO: (day, month, year) => {
		// combines day, month and year into format "yyyy-MM-dd"
		if (day && day !== "" && month && month !== "" && year && year !== "") {
			let dateToSend = new Date()
			dateToSend.setDate(day)
			dateToSend.setMonth(month - 1)
			dateToSend.setFullYear(year)
			return format(dateToSend, "yyyy-MM-dd")
		}
	},
	dateForDisplay: (date) => {
		// turns date from "yyyy-MM-dd" into "dd/MM/yyyy"
		if(date){
			let dateArray = date.split("-")
			if (dateArray[2] && dateArray[2] !== "" && dateArray[1] && dateArray[1] !== "" && dateArray[0] && dateArray[0] !== "") {
				let dateToDisplay = new Date()
				dateToDisplay.setDate(dateArray[2])
				dateToDisplay.setMonth(dateArray[1]-1)
				dateToDisplay.setFullYear(dateArray[0])
				return format(dateToDisplay, "dd/MM/yyyy")
			}
			else return null
		}
	},
	money: number => {
		if (isNaN(number) || number === "" || number === null || number === undefined) return "";
		if (number.toString().match(/\d+\.\d{2,}/g)) {
			number = Math.floor(number * 100) / 100;
		} else if (number.toString().match(/\d+\.\d/g)) {
			number = Math.floor(number * 10) / 10;
		}
		return number || number === 0 ? "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
	},
	numberWithCommas: number => {
		if (isNaN(number) || number === "" || number === null || number === undefined) return "";
		if (number.toString().match(/\d+\.\d{2,}/g)) {
			number = Math.floor(number * 100) / 100;
		} else if (number.toString().match(/\d+\.\d/g)) {
			number = Math.floor(number * 10) / 10;
		}
		return number || number === 0 ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
	},
	percentage: value => {
		return `${(value * 100).toFixed(0)}%`;
	},
	boolean: value => {
		return value ? "Yes" : "No";
	},
	asK: number => {
		return number < 1000 ? `$${Math.round(number)}` : `$${Math.round(number / 1000)}k`;
	},
	fullDate: shortDate => {
		var date = new Date(shortDate);
		return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
	},
	name: name => {
		return name ? capitalise(name.replace(/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/g, "")) : "";
	},
	age: age => {
		const number = parseInt(`${age}`.substring(0, 3).replace(/[^0-9]/g, ""));
		return !isNaN(number) && number >= 0 ? (number <= 130 ? `${number}` : `${number}`.substring(0, 2)) : "";
	},
	email: email => {
		email = email || "";
		let ensuredEmail = email.replace(/\.{2}/g, ".").replace(/[^a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~@.]/g, "");
		if (ensuredEmail.includes("@")) {
			ensuredEmail = ensuredEmail.split("@")[0] + "@" + ensuredEmail.split("@")[1];
		}
		return ensuredEmail;
	},
	string: content => (content && content !== null ? content : "N/A"),
	link: content => content.replace(/ /g, "-").toLowerCase(),
	investmentObjective: forFirstHome => (forFirstHome ? "First Home" : "Retirement"),
	address: address => {
		return address;
	},
	mobile: mobile => {
		mobile = mobile || "";
		return mobile
			.toString()
			.slice(0, 1)
			.replace(/[^0-9+]/g, "")
			.concat(mobile.slice(1).replace(/[^0-9]/g, ""))
			.slice(0, 14);
	},
	day: day => {
		const parsedDay = parseInt(
			(day || "")
				.toString()
				.slice(0, 3)
				.replace(/[^0-9]/g, ""),
		);
		if (isNaN(parsedDay) || parsedDay < 1) {
			return "";
		}
		if (parsedDay >= 100 && parsedDay <= 320) {
			return parsedDay.toString().slice(0, 2);
		}
		if (parsedDay > 31) {
			return parsedDay.toString().slice(0, 1);
		}
		if (parsedDay < 10) {
			return "0" + parsedDay;
		}
		return parsedDay;
	},
	month: month => {
		const parsedMonth = parseInt(
			(month || "")
				.toString()
				.slice(0, 3)
				.replace(/[^0-9]/g, ""),
		);
		if (isNaN(parsedMonth) || parsedMonth < 1) {
			return "";
		}
		if (parsedMonth >= 100 && parsedMonth <= 130) {
			return parsedMonth.toString().slice(0, 2);
		}
		if (parsedMonth > 12) {
			return parsedMonth.toString().slice(0, 1);
		}
		if (parsedMonth < 10) {
			return "0" + parsedMonth;
		}
		return parsedMonth;
	},
	year: year => {
		const parsedYear = parseInt(
			(year || "")
				.toString()
				.slice(0, 4)
				.replace(/[^0-9]/g, ""),
		);
		if (isNaN(parsedYear) || parsedYear < 1) {
			return "";
		}
		return parsedYear;
	},
	passportNumber: ppNumber => {
		return ppNumber;
	},
	birthCertificateNumber: bcNumber => {
		return bcNumber;
	},
	irdNumber: irdNumber => {
		return irdNumber || irdNumber === 0
			? (irdNumber.toFixed ? irdNumber.toFixed(0) : irdNumber)
				.toString()
				.replace(/[^0-9]/g, "")
				.substring(0, 9)
				.replace(/\B(?=(\d{3})+(?!\d))/g, "-")
			: "";
	},
}

export const validate = {
	value: value => {
		return value === null || value === "" || value === undefined ? "Please enter a value" : "";
	},
	response: response => {
		return response === null || response === "" || response === undefined ? "Please enter a response" : "";
	},
	country: country => {
		return country === null || country === "" || country === undefined || country === [] ? "Please enter a country" : "";
	},
	occupation: (currentOccupation, previousOccupation) => {
		return (currentOccupation === null || currentOccupation === "" || currentOccupation === undefined)
			&& (previousOccupation === null || previousOccupation === "" || previousOccupation === undefined)
			? "Please enter a current or previous occupation"
			: "";
		// return value === null || value === "" || value === undefined ? "Please enter an occupation" : "";
	},

	employer: employer => {
		return employer === null || employer === "" || employer === undefined || employer === [] ? "Please enter an employer" : "";
	},
	industry: industry => {
		return industry === null || industry === "" || industry === undefined || industry === [] ? "Please enter an industry" : "";
	},
	select: (value, options) => {
		if (options.includes(value)) return "";
		return "Please select a value";
	},
	number: number => (validate.value(number) !== "" || isNaN(number) || typeof number !== "number" ? "Please enter a number" : ""),
	name: name => (name === null || name === "" || name === undefined ? "Please enter your name" : ""),
	lastName: name => (name === null || name === "" || name === undefined ? "Please enter your last name" : ""),
	email: email => {
		if (email === null || email === "" || email === undefined) return "Please enter your email";
		else if (!email.includes("@")) return "Your email is missing an @";
		else if (email.endsWith("@")) return "Domain value is missing";
		else if (email.endsWith(".") || !email.replace(/[^@]+@/g, "").includes(".")) return "Top level domain is missing";
		else if (email.includes("@.")) return "Lower level domain is missing";
		else return "";
	},
	termsAndConditions: hasAccepted => {
		if (hasAccepted) return "";
		else return "You need to accept the terms and conditions to continue";
	},
	checkbox: hasAccepted => {
		if (hasAccepted) return "";
		else return "You need to check this to continue";
	},
	age: age => (age > 0 && age <= 130 ? "" : "Must be between 1-130"),
	balance: balance => (validate.number(balance) !== "" || balance < 0 ? "Must be a non-negative number" : ""),
	income: income => (validate.number(income) !== "" || income < 0 ? "Must be a non-negative number" : ""),
	contribution: contribution => (contribution >= 0 ? "" : "Must be a positive number"),
	forFirstHome: forFirstHome => (forFirstHome !== null && forFirstHome !== undefined ? "" : "Must be a boolean"),
	firstHomeYears: firstHomeYears => (firstHomeYears > 0 ? (firstHomeYears <= 30 ? "" : "Can not be greater than 30") : "Must be a positive number"),
	voluntaryContribution: voluntaryContribution => (voluntaryContribution >= 0 ? "" : "Must be a positive number"),
	mobile: mobile => {
		const numberInfo = mobile && mobile.idd && mobile.number && Phone(`${mobile.idd}${mobile.number}`)
		return numberInfo && numberInfo.isValid ? "" : "Must be a valid phone"
	},
	date: (day, month, year) => {
		if (!day || day === "") {
			return "Day is missing.";
		}
		if (!month || month === "") {
			return "Month is missing.";
		}
		if (!year || year === "") {
			return "Year is missing.";
		}
		const lastDaysOfMonth = [31, year % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		if (lastDaysOfMonth[month - 1] === undefined || day > lastDaysOfMonth[month - 1]) {
			return `${day}${day === 1 ? "st" : day === 2 ? "nd" : "th"} of ${display.name(monthNames[month - 1].toLowerCase())} is invalid`;
		}
		return "";
	},
	dateOfBirth: (day, month, year) => {
		const dateValidation = validate.date(day, month, year);
		const minYear = 1900;
		const now = new Date();
		if (year.length < 4) return "Please enter the full year"
		if (dateValidation !== "") return dateValidation;
		if (year > now.getFullYear() || (year === now.getFullYear() && month > now.getMonth() + 1) || (year === now.getFullYear() && month === now.getMonth() + 1 && day > now.getDate())) return "Birth date cannot be in the future";
		if (year < minYear) return "Year of birth must be >= " + minYear;
		return "";
	},
	expiryDate: (day, month, year) => {
		const dateValidation = validate.date(day, month, year);
		if (dateValidation !== "") return dateValidation;
		const now = new Date();
		if (year < now.getFullYear() || (year === now.getFullYear() && month < now.getMonth() + 1) || (year === now.getFullYear() && month === now.getMonth() + 1 && day < now.getDate())) return "This is expired";
		const maxYear = now.getFullYear() + 10;
		if (year > maxYear) return "Passport expiry year must be < " + maxYear;
		return "";
	},
	irdNumber: irdNumber => {
		return IrdValidator.isValid(irdNumber) ? "" : "IRD number is not valid";
	},
	address: (address, addressSuggestions) => {
		// return address && addressSuggestions ? (addressSuggestions.includes(address) ? "" : addressSuggestions.length > 1 ? "Please select an address" : "Please input a valid address") : "Please input an address";
		return address ? addressSuggestions ? (addressSuggestions.includes(address) ? "" : addressSuggestions.length > 1 ? "Please select an address" : "Please input a valid address") : "" : "Please input a valid address"
	},
	driversLicenceNumber: driversLicenceNumber => {
		return /[A-Z]{2}\d{6}/.test(driversLicenceNumber) ? "" : "Please enter a valid Driver's Licence Number";
	},
	password: (password) => {
		let count = password.match(/[a-z]/) ? 1 : 0;
		count += password.match(/[A-Z]/) ? 1 : 0;
		count += password.match(/[0-9]/) ? 1 : 0;
		count += password.match(/[^A-Za-z0-9]/) ? 1 : 0;
		if (password.length < 8) return "Password must be at least 8 characters";
		if (count < 3) return "Password must contain at least three of lower case, upper case, numeric and non-alphanumeric characters";
		return "";
	},
	resetPassword: (pw1, pw2) => {
		if (pw1.length < 8) {
			return "Password must be at least 8 characters";
		}
		let count = pw1.match(/[a-z]/) ? 1 : 0;
		count += pw1.match(/[A-Z]/) ? 1 : 0;
		count += pw1.match(/[0-9]/) ? 1 : 0;
		count += pw1.match(/[^A-Za-z0-9]/) ? 1 : 0;
		if (count < 3) {
			return "Password must contain at least three of lower case, upper case, numeric and non-alphanumeric characters";
		}
		if (pw1 !== pw2) {
			return "Passwords do not match";
		}
		return "";
	},
	confirmPassword: (confirmPassword, password) => {
		if (confirmPassword !== password) return "Passwords must match"
		else return ""
	}
}

export const handleOnboardingInputChange = (value, checkIfValid, setState, extraArgs) => {
	if (checkIfValid) {
		extraArgs
			? setState({ value, touched: true, valid: checkIfValid(value, ...extraArgs) })
			: setState({ value, touched: true, valid: checkIfValid(value) })
	} else setState({ value, touched: true, valid: true })
}

export const checkOnboardingInputValid = (value) => { return (value.valid === "" || value.valid === true) && value.touched ? true : false }
export const checkOnboardingInputInvalid = (value) => { return (value.valid === "" || value.valid === true) || value.touched === false ? false : true }

export function retrievePhonePrefixList(entry, storage) {
	entry = entry && entry.startsWith("+") ? entry.substring(1) : entry;
	const findMatches = (str, list) => {
		var filteredAndTransformedList = [];
		const match = typeof str === "number" ? str : str.toLowerCase();
		for (var i = 0; i < list.length; i++) {
			if (list[i]["name"].toLowerCase().includes(match)
				|| list[i]["idd"].toString().indexOf(match) === 0) {
				filteredAndTransformedList.push({
					label: list[i].name,
					value: "+" + list[i].idd,
					id: list[i].id
				})
			}
		}
		return filteredAndTransformedList;
	}
	return storage.get("/public/modules/hobson-support/stockfox/idds")
		.then(idds => {
			return findMatches(entry, idds);
		});
}

export function testInternationalNumberSeperately(idd, number) {
	return !!idd && /^\+[0-9]{1,4}$/.test(idd.replace(/\s/g, "")) //assumes that the prefix is a string!!!
		&& /^[1-9][0-9]{6,10}$/.test(number);//also total length test?
}

export const dateRangeIsValidAndNonNull = (fromDate, toDate) => {
	
		if (isNullOrUndefined(fromDate) || isNullOrUndefined(toDate)){
			return false
		}

	let parseFromDate = fromDate	
	let parseToDate = toDate
		
	if (moment.isMoment(fromDate)) parseFromDate = fromDate.format("yyyy-MM-dd")
	if (moment.isMoment(toDate)) parseToDate = toDate.format("yyyy-MM-dd")



	const fromDateArray = parseFromDate.split("-")
	const toDateArray = parseToDate.split("-")
	const newFromDate = new Date()
	const newToDate = new Date()
	newFromDate.setDate(fromDateArray[2])
	newFromDate.setMonth(fromDateArray[1])
	newFromDate.setFullYear(fromDateArray[0])
	newToDate.setDate(toDateArray[2])
	newToDate.setMonth(toDateArray[1])
	newToDate.setFullYear(toDateArray[0])
	const from = parseFromDate ? isValid(newFromDate) : null;
	const to = parseToDate ? isValid(newToDate) : null;
	if (from && to) {
		return isBefore(newFromDate, addDays(newToDate, 1));
	}
	return false;
};

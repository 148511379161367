import React from 'react';
import Divider from '../layout/Divider';
import FlexWrapper from '../layout/FlexWrapper';
import "./BankAccountOption.scss";

const BankAccountOption = ({ label, currency, bankName, accountNumber}) => {
	return (
		<FlexWrapper className="bank-account-option" wrap>
			<FlexWrapper gap="s" className="account-fields">
				<div className="account-name font-weight-semi-bold">{label}</div>
				<Divider vertical height="14px"/>
				<div className="account-currency">{currency}</div>
				<Divider vertical height="14px"/>
				<div className="account-bank-name">{bankName}</div>
			</FlexWrapper>
			<div className="account-number">{accountNumber}</div>
		</FlexWrapper>
   );
}

export default BankAccountOption;
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import FlexWrapper from '../../../layout/FlexWrapper';
import Button from '../../../buttons/Button';
import { useNavigate } from "react-router-dom";
import { useRemote } from '../../../utils/ApiUtils';

const BankVerification = () => {	
	let navigate = useNavigate();

	const [akahuUrl, setAkahuUrl] = useState("");

	const remote = useRemote();

	useEffect(() => {
		remote.get("/modules/hobson-support/stockfox/akahu-url").then(json => setAkahuUrl(json.url));
	});

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FlexWrapper gap="s">
						<div className="title">Bank Account Verification</div>
					</FlexWrapper>
				</Grid>
				<Grid item xs={12}>
					<h1>To proceed, we need to verify your bank account</h1>
					<p>We use the Akahu app to verify your access.  You can safely log in to your bank and we will be provided with the bank account number. 
						No data is stored by Akahu, and we store only the bank account details that you authorise.</p>
					<Button disabled={akahuUrl===""} className="mt-xl" onClick={() => window.location.href=akahuUrl}>Begin Now</Button>
				</Grid>
			</Grid>
		</>
	);
}

export default BankVerification;
import React, { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import TextInput from './TextInput';
import "./PasswordInput.scss";

const PasswordInput = ({...props}) => {
	const [passwordVisible, setPasswordVisible] = useState(false);

	return (
		<TextInput
			{...props}
			className="password-input"
			icon={{ icon: passwordVisible ? <EyeOff className="password-icon password-icon-hidden" /> : <Eye className="password-icon password-icon-visible" />, onClick: () => setPasswordVisible(!passwordVisible) }}
			type={passwordVisible ? "text" : "password" }
		/>
	);
}

export default PasswordInput;
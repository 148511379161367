import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextInput from "../../../inputs/TextInput";
import SingleSelectInput from '../../../inputs/SingleSelectInput';
import MultiSelectInput from '../../../inputs/MultiSelectInput';
import { pirOptions, rwtOptions } from '../../../../resources/DummyData';
import { validate, handleOnboardingInputChange, checkOnboardingInputValid, checkOnboardingInputInvalid } from '../../../utils/ValidationUtils'
import { useRemote } from '../../../utils/ApiUtils';

const OnboardingFatcaTax = ({ clientSetup, setFatcaTax, componentValid, setComponentValid }) => {
	const [irdNumber, setIrdNumber] = useState({ value: "", touched: false, valid: "" });
	const [rwtRate, setRwtRate] = useState({ value: { value: null, label: "" }, touched: true, valid: "" });
	const [pirRate, setPirRate] = useState({ value: { value: null, label: "" }, touched: true, valid: "" });
	const [countryOfBirth, setCountryOfBirth] = useState({ value: "", touched: false, valid: "" });
	const [countriesOfCitizenship, setCountriesOfCitizenship] = useState({ value: [], touched: false, valid: "" });
	const [countriesOfResidence, setCountriesOfResidence] = useState({ value: [], touched: false, valid: "" });
	const [countriesOfTaxResidence, setCountriesOfTaxResidence] = useState({ value: [], touched: false, valid: "" });

	const [countryCodes, setCountryCodes] = useState([])

	const remote = useRemote();


	useEffect(() => {
		remote.get("/public/modules/hobson-support/stockfox/country-codes").then(allCountries => {
			function arraymove(arr, fromIndex, toIndex) {
				var element = arr[fromIndex];
				arr.splice(fromIndex, 1);
				arr.splice(toIndex, 0, element);
			}
			let rearrangedArray = [...allCountries];
			let AU = rearrangedArray.findIndex((country) => country.id === 13)
			let NZ = rearrangedArray.findIndex((country) => country.id === 158)
			arraymove(rearrangedArray, AU, 0)
			arraymove(rearrangedArray, NZ, 0)
			setCountryCodes(rearrangedArray.map(({ id, name, code, threeLetterCode }) => ({ value: { id, name, code, threeLetterCode }, label: name })));
		})
		return () => setCountryCodes([])
	}, [remote]);

	
	useEffect(() => {

		const mapCountriesToSelectableObjects = (countries) => {
			if (countryCodes.length > 0) {
				return countries && countries.length > 0 ? countries.map((country) => {
					return mapSingleCountryCodeToSelectObject(country)
				}) : []
			} else return countries
		}
	
		const mapSingleCountryCodeToSelectObject = (country) => {
			let countryDetails = countryCodes.length >= 1 ? countryCodes.find((countryCode) => countryCode.value.code === country) : { value: { name: "", code: "", threeLetterCode: "", id: null }, label: "" }
			return countryDetails
		}

		if (clientSetup && clientSetup.uuid && countryCodes.length >= 1) {
			setIrdNumber({ value: clientSetup.ird === null ? "" : clientSetup.ird, touched: false, valid: "" });
			setRwtRate({ value: clientSetup.rwt === null ? "" : { value: clientSetup.rwt, label: clientSetup.rwt }, touched: false, valid: "" });
			setPirRate({ value: clientSetup.pir === null ? "" : { value: clientSetup.pir, label: clientSetup.pir }, touched: false, valid: "" });
			setCountryOfBirth({ value: clientSetup.birthCountry === null ? "" : mapSingleCountryCodeToSelectObject(clientSetup.birthCountry), touched: false, valid: "" });
			setCountriesOfCitizenship({ value: (clientSetup.citizenCountries === null || clientSetup.citizenCountries === undefined) ? [] : mapCountriesToSelectableObjects(clientSetup.citizenCountries), touched: false, valid: "" });
			setCountriesOfResidence({ value: (clientSetup.residenceCountries === null || clientSetup.residenceCountries === undefined) ? [] : mapCountriesToSelectableObjects(clientSetup.residenceCountries), touched: false, valid: "" });
			setCountriesOfTaxResidence({ value: (clientSetup.taxResidenceCountries === null && clientSetup.taxResidenceCountries === undefined) ? [] : mapCountriesToSelectableObjects(clientSetup.taxResidenceCountries), touched: false, valid: "" });
		}
	}, [clientSetup, countryCodes])

	useEffect(() => {
		if (countryCodes.length < 1) return;
		if (clientSetup && clientSetup.uuid) {
			const isComponentValid = ![
				validate.irdNumber(irdNumber.value) === "" || irdNumber.value === "",
				validate.country(countryOfBirth) === "",
				validate.country(countriesOfCitizenship) === "",
				validate.country(countriesOfResidence) === "",
				validate.country(countriesOfTaxResidence) === ""
			].includes(false)
			setComponentValid(isComponentValid)
			setFatcaTax({
				ird: irdNumber.value,
				rwt: rwtRate && rwtRate.value && (rwtRate.value.value || rwtRate.value.value === null || rwtRate.value.value === "") ? rwtRate.value.value : rwtRate.value,
				pir: pirRate.value.value ? pirRate.value.value : null,
				birthCountry: countryOfBirth.value && countryOfBirth.value.value && countryOfBirth.value.value.code ? countryOfBirth.value.value.code : null,
				citizenCountries: returnCountryCode(countriesOfCitizenship.value),
				residenceCountries: returnCountryCode(countriesOfResidence.value),
				taxResidenceCountries: returnCountryCode(countriesOfTaxResidence.value)
			});
		}
	}, [clientSetup, irdNumber, rwtRate, pirRate, countryOfBirth, countriesOfCitizenship, countriesOfResidence, countriesOfTaxResidence, countryCodes, setComponentValid, setFatcaTax]);

	const returnCountryCode = (array) => {
		if (!array || array.length < 1) return []
		else if (typeof array[0] === 'object' && array[0] !== null) return  array.map(c => c ? c.value.code : null)
		else if (typeof array[0] === 'string') return array
		else {
			return []
		}
	}

	return (
		<div className="mt-l mb-l">
			{/* General Detail Fields Here */}
			<Grid className="transition-fade-up-slow" container spacing={3}>
				<Grid item xs={12}>
					<h1>Tax / FATCA</h1>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput
						required
						label="IRD Number"
						placeholder="IRD Number"
						valid={checkOnboardingInputValid(irdNumber)}
						invalid={checkOnboardingInputInvalid(irdNumber)}
						helperText={irdNumber.valid && irdNumber.valid}
						value={irdNumber.value}
						onChange={(e) => handleOnboardingInputChange(e.target.value, validate.irdNumber, setIrdNumber)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SingleSelectInput
						label="RWT Rate"
						placeholder="RWT Rate"
						value={rwtRate.value}
						options={rwtOptions}
						onChange={(value) => handleOnboardingInputChange(value, null, setRwtRate)}
						clearValue={() => setRwtRate({ value: { value: null, label: "" }, touched: false, valid: "" })}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SingleSelectInput
						label="PIR Rate"
						placeholder="PIR Rate"
						value={pirRate.value}
						options={pirOptions}
						onChange={(value) => handleOnboardingInputChange(value, null, setPirRate)}
						clearValue={() => setPirRate({ value: { value: null, label: "" }, touched: false, valid: "" })}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SingleSelectInput
						required
						label="Country of Birth*"
						placeholder="Country of Birth"
						value={countryOfBirth.value}
						valid={checkOnboardingInputValid(countryOfBirth)}
						invalid={checkOnboardingInputInvalid(countryOfBirth)}
						helperText={countryOfBirth.valid && countryOfBirth.valid}
						onChange={(value) => handleOnboardingInputChange(value, validate.country, setCountryOfBirth)}
						clearValue={() => handleOnboardingInputChange("", validate.country, setCountryOfBirth)}
						options={countryCodes}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<MultiSelectInput
						required
						label="Countries of Citizenship*"
						placeholder="Countries of Citizenship"
						value={countriesOfCitizenship.value}
						valid={checkOnboardingInputValid(countriesOfCitizenship)}
						invalid={checkOnboardingInputInvalid(countriesOfCitizenship)}
						helperText={countriesOfCitizenship.valid}
						onChange={(value) => handleOnboardingInputChange(value, validate.country, setCountriesOfCitizenship)}
						clearValue={() => handleOnboardingInputChange("", validate.country, setCountriesOfCitizenship)}
						options={countryCodes}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<MultiSelectInput
						required
						label="Countries of Residence*"
						placeholder="Countries of Residence"
						value={countriesOfResidence.value}
						valid={checkOnboardingInputValid(countriesOfResidence)}
						invalid={checkOnboardingInputInvalid(countriesOfResidence)}
						helperText={countriesOfResidence.valid}
						onChange={(value) => handleOnboardingInputChange(value, validate.country, setCountriesOfResidence)}
						clearValue={() => handleOnboardingInputChange("", validate.country, setCountriesOfResidence)}
						options={countryCodes}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<MultiSelectInput
						required
						label="Countries of Tax Residence*"
						placeholder="Countries of Tax Residence"
						value={countriesOfTaxResidence.value}
						valid={checkOnboardingInputValid(countriesOfTaxResidence)}
						invalid={checkOnboardingInputInvalid(countriesOfTaxResidence)}
						onChange={(value) => handleOnboardingInputChange(value, validate.country, setCountriesOfTaxResidence)}
						clearValue={() => handleOnboardingInputChange("", validate.country, setCountriesOfTaxResidence)}
						helperText={countriesOfTaxResidence.valid && countriesOfTaxResidence.valid}
						options={countryCodes}
					/>
				</Grid>
			</Grid>
		</div>
	);
}
export default OnboardingFatcaTax;
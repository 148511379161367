import React from 'react';
import TextFieldLabel from '../inputs/TextFieldLabel';
import "./BankAccountField.scss";

const BankAccountField = ({ bankAccount }) => {
	const {name, content } = bankAccount;
	const {accountNumber, accountName, currency} = content
	return (
		<div className="bank-account-field-wrapper">
			<TextFieldLabel>{name}</TextFieldLabel>
			<div className="fields-wrapper">
				<div className="bank-name">{accountName}</div>
				<div className="bank-number">{accountNumber}</div>
				<div className="bank-currency">{currency}</div>
			</div>
		</div>
	);
}

export default BankAccountField;
import React from 'react';
import LoadingIcon from '../misc/LoadingIcon';
import "./Button.scss";

const Button = ({ onClick, style, className, children, icon, variant, disabled, fullWidth, subtle, size, loading, hover }) => {
	let buttonClass = "button-base";

	if (className) {
		buttonClass += " " + className;
	}
	if (variant) {
		buttonClass += " variant-" + variant;
	}
	if (hover) {
		buttonClass += " hover-" + hover;
	}
	if (size) {
		buttonClass += " size-" + size;
	}
	if (disabled) {
		buttonClass += " button-disabled";
	}
	if (fullWidth) {
		buttonClass += " button-full-width";
	}
	if (subtle) {
		buttonClass += " button-subtle";
	}
	if (loading) {
		buttonClass += " button-loading";
	}

	return (
		<button disabled={disabled || loading} className={buttonClass} onClick={onClick} style={style}>
			{loading ? (
				<LoadingIcon size={24} />
			) : (
				<>
					{icon && icon}
					{children}
				</>
			)}
		</button>
	);
}

export default Button;
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../inputs/TextInput';
import DateOfBirthInput from '../../../../inputs/DateOfBirthInput';
import { months } from '../../../../../resources/DummyData';

const AccInfoPersonalSection = ({ clientDetails }) => {

	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState({ day: "", month: "", year: "" });

	useEffect(() => {
		if (clientDetails) {
			const unstructuredData = clientDetails.unstructuredData ? JSON.parse(clientDetails.unstructuredData) : null
			const getDOB = clientDetails.birthDate ? clientDetails.birthDate.split("-") : ["", "", ""]
			setFirstName(clientDetails.givenName ? clientDetails.givenName : "")
			setMiddleName(clientDetails.middleNames ? clientDetails.middleNames : "")
			setLastName(clientDetails.familyName ? clientDetails.familyName : "")
			setDateOfBirth({ day: getDOB[2], month: months.find((month => month.value == getDOB[1])), year: getDOB[0] })
		}
	}, [clientDetails])

	return (
		<Grid item container spacing={3}>
			<Grid item xs={12}>
				<h1>Personal</h1>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="First Name" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="Middle Names" placeholder="Middle Names" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="Last Name" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<DateOfBirthInput
					disabled
					required
					month={{ width: "70%", invalid: false, value: dateOfBirth.month, onChange: (e) => setDateOfBirth({ ...dateOfBirth, month: e }) }}
					day={{ invalid: false, value: dateOfBirth.day, onChange: (e) => setDateOfBirth({ ...dateOfBirth, day: e.target.value }) }}
					year={{ invalid: false, value: dateOfBirth.year, onChange: (e) => setDateOfBirth({ ...dateOfBirth, year: e.target.value }) }}
				/>
			</Grid>
		</Grid>
	);
}

export default AccInfoPersonalSection;
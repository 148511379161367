import React, { Component } from "react";
// import withRouteChange from "@threeskye/route-change/dist/RouteChange";
// import { ModalTypes } from "../modal/ModalUtils";
import { isBefore, parseISO } from "date-fns";
import { withStorage } from "@threeskye/global";

export const NotificationsContext = React.createContext({
	notifications: null,
	onNotficationClick: null,
});

export const NotificationsWrapper = withStorage(class extends Component {
	state = { notifications: null, notificationsLoading: true };

	constructor(props) {
		super(props);
		this.onNotificationClick = this.onNotificationClick.bind(this);
		this.deleteNotification = this.deleteNotification.bind(this);
	}

	componentDidMount() {
		this.props.storage.getOrFetch("/modules/crm/client-notifications")
			.then((notifications) => {
				// const notifications = [
				// 	{
				// 		"id": 353639,
				// 		"time": null,
				// 		"timeFormatted": null,
				// 		"dateFormatted": null,
				// 		"createdDate": "2022-04-22T17:24:48.516595",
				// 		"type": {
				// 			"id": 3,
				// 			"name": "Note"
				// 		},
				// 		"location": null,
				// 		"address": null,
				// 		"accounts": [
				// 			5375
				// 		],
				// 		"clients": [],
				// 		"nonPersonClients": [],
				// 		"advice": false,
				// 		"heading": "Holdings report available",
				// 		"content": "{\"blocks\":[{\"key\":\"62f046\",\"text\":\"Your Holdings report for the six-month period 01 October 2021 to 31 March 2022 is available in your My Documents\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
				// 		"attachments": [],
				// 		"changes": [],
				// 		"emailEvent": null,
				// 		"emails": null,
				// 		"clientVisible": true
				// 	}
				// ]

				
				return notifications.sort((notif1, notif2) => {
					let date1 = parseISO(notif1.createdDate)
					let date2 = parseISO(notif2.createdDate)

					if (!date1 && !date2) return 0
					else if (!date1) return -1
					else if (!date2) return 1
					else if (isBefore(date1, date2)) return 1
					else if (isBefore(date2, date1)) return -1
					else return 0
				})
			})
			.then((notifications) => this.setState({ notifications, notificationsLoading: false }));
	}

	onNotificationClick(notification) {
		//TODO
		console.log(notification)
	}

	deleteNotification(notification, event) {
		//Delete it
		event.stopPropagation();
		this.setState({ notifications: this.state.notifications.filter((notificationFromList) => notificationFromList.id != notification.id) })
		this.props.remote.send("delete", `/modules/crm/client-notifications/${notification.id}`)
		// .then((notifications) => {});
	}

	render() {
		const { notifications, notificationsLoading } = this.state;
		const { children } = this.props;
		return <NotificationsContext.Provider value={{ notifications, notificationsLoading, onNotificationClick: this.onNotificationClick, deleteNotification: this.deleteNotification }}>{children}</NotificationsContext.Provider>;
	}
})

export default function withNotifications(WrappedComponent) {
	return (
		class extends Component {
			render() {
				return (
					<NotificationsContext.Consumer>
						{({ notifications, notificationsLoading, onNotificationClick, deleteNotification }) => (
							<WrappedComponent {...this.props} {...{ notifications, notificationsLoading, unreadNotificationCount: (notifications && notifications.length) || 0, onNotificationClick, deleteNotification }} />
						)}
					</NotificationsContext.Consumer>
				);
			}
		}
	);
}

import React, { useState } from 'react';
import { X } from 'react-feather';
import FlexWrapper from '../layout/FlexWrapper';
import AssetLogo from '../misc/ticker-assets/AssetLogo';
import "./MobileOrderListItem.scss";

const ExpandedContentRow = ({label, value}) => {
	return (
		<FlexWrapper className="moli-expanded-row" fullWidth justify="space-between">
			<p className="moli-expanded-label">{label}</p>
			<p className="moli-expanded-value">{value}</p>
		</FlexWrapper>
	);
}

const MobileOrderListItem = ({ subLabel, order, removeOrder, expandedContent }) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<div className={`mobile-order-list-item${expanded ? " moli-expanded" : ""}`} onClick={() => setExpanded(!expanded)}>
			<FlexWrapper gap="s" fullWidth justify="space-between" className="moli-main">
				<FlexWrapper gap="s">
					<AssetLogo logo={order.logo} alt={order.name} size={38} />
					<FlexWrapper className="order-asset-details" wrap>
						<div className="order-list-item-sub-label">{subLabel ? subLabel : order.name}</div>
						<div className="order-list-item-code">
							{order.code} {order.exchange ? "|" + order.exchange : ""}
						</div>
					</FlexWrapper>
				</FlexWrapper>
				<FlexWrapper align="flex-end" direction="column-reverse" wrap className="moli-details">
					<div className="order-list-item-cost">{"$" + order.estimatedCost}</div>
					<div className={`order-list-item-action order-action-${order.action.toLowerCase()}`}>{order.action}</div>
				</FlexWrapper>
				{removeOrder && <X className="remove-icon" size={16} onClick={() => removeOrder(order)} />}
			</FlexWrapper>
			{/* Expanded content */}
			{expanded && (
				<FlexWrapper className="moli-expanded-content transition-fade-in" fullWidth wrap>
					{expandedContent && expandedContent.map((content, idx) => <ExpandedContentRow key={idx} value={content.value} label={content.label} />)}
				</FlexWrapper>
			)}
		</div>
	);
}

export default MobileOrderListItem;
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Button from '../../../../buttons/Button';
import SingleSelectInput from '../../../../inputs/SingleSelectInput';
import TextInput from '../../../../inputs/TextInput';
import Modal from '../../../../layout/Modal';
import AccInfoBankAccounts from './AccInfoBankAccounts';
import AccInfoContactSection from './AccInfoContactSection';
import AccInfoPersonalSection from './AccInfoPersonalSection';
import AccInfoTaxSection from './AccInfoTaxSection';
import AccInfoOccupationSection from './AccInfoOccupationSection';
import "./AccInformation.scss";

const AccountInformation = ({clientDetails, accountDetails}) => {
	const [open, setOpen] = useState(false);
	const [reason, setReason] = useState(false);
	const [otherReason, setOtherReason] = useState(false);
	const handleClose = () => setOpen(false);

	const reasonOptions = [
		{label: "Lorem", value: "Lorem"},
		{label: "Ipsum", value: "Ipsum"},
		{label: "Dolor", value: "Dolor"},
		{label: "Other", value: "Other"}
	]

	return (
		<form>
			<Grid container spacing={5} className="transition-fade-up-slow" alignItems="flex-start" justifyContent="space-between">
				<Modal open={open} handleClose={handleClose} header="Are you sure?">
					<Grid item xs={12}>
						<h2>Please let us know your reason for closing your account</h2>
					</Grid>
					<Grid item xs={12}>
						<SingleSelectInput
							label="Reason"
							placeholder="Select"
							value={reason}
							options={reasonOptions}
							onChange={(value) => setReason(value)}
							clearValue={() => setReason("")}
							blurInputOnSelect
							isSearchable={false}
						/>
					</Grid>
					{reason.value === "Other" && <Grid item xs={12} className="transition-fade-up-slow" >
						<TextInput
							autoFocus
							textArea
							required
							placeholder="e.g. You are no longer interested in lorem ipsum"
							valid={otherReason}
							value={otherReason}
							onChange={(e) => setOtherReason(e.target.value)}
						/>
					</Grid>}
					<Grid item xs={12}>
						<Button disabled={!reason || (reason.value === "Other" && !otherReason)} variant="danger">Close My Account</Button>
					</Grid>
				</Modal>
				<Grid item xs={8}>
					<div className="title colour-text-black">Account Information</div>
				</Grid>
				<Grid item xs="auto" className="close-acc-button-wrapper">
					<Button variant="danger" subtle onClick={() => setOpen(true)}>Close Account</Button>
				</Grid>
				{/* Sub sections */}
				<AccInfoPersonalSection clientDetails={clientDetails} />
				<AccInfoOccupationSection clientDetails={clientDetails} />
				<AccInfoContactSection clientDetails={clientDetails} accountDetails={accountDetails}/>
				<AccInfoTaxSection clientDetails={clientDetails} />
				<AccInfoBankAccounts bankAccounts={accountDetails && accountDetails.bankAccounts} />
				{/* <Grid item xs="auto">
					<Button variant="danger" subtle onClick={() => setOpen(true)}>Close Account</Button>
				</Grid> */}
			</Grid>
		</form>
	);
}

export default AccountInformation;
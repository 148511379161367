import React, { cloneElement } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import "./RadioButtonGroup.scss";
import FlexWrapper from '../layout/FlexWrapper';
import Tooltip from '../layout/Tooltip';

const RadioButtonGroup = ({ options, onChange, id, label, value }) => {
	return (
		<FormControl className="radio-button-group-wrapper">
			<FormLabel id={id}>{label}</FormLabel>
			<RadioGroup row aria-labelledby={id} name="controlled-radio-buttons-group" value={value} onChange={onChange}>
				{options &&
					options.map((option, idx) => (
						<FormControlLabel
							tabIndex={idx + 1}
							className="radio-input-button"
							key={idx}
							value={option.value}
							control={<Radio />}
							disabled={option.disabled}
							label={
								!option.icon || !option.tooltip ? (
									<>{option.label}</>
								) : (
									<FlexWrapper gap="s">
										{option.label}
										<Tooltip title={option.tooltip && option.tooltip}>{option.icon ? cloneElement(option.icon, { className: option.icon.props.className + " custom-option-icon" }) : <></>}</Tooltip>
									</FlexWrapper>
								)
							}
						/>
					))}
			</RadioGroup>
		</FormControl>
	);
}

export default RadioButtonGroup;
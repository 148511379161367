import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { dummyAddresses } from '../../../../../resources/DummyData';
import SingleSelectInput from '../../../../inputs/SingleSelectInput';
import TextInput from '../../../../inputs/TextInput';

const AccInfoContactSection = ({ clientDetails, accountDetails }) => {
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [physicalAddress, setPhysicalAddress] = useState("");
	const [postalAddress, setPostalAddress] = useState("");

	useEffect(() => {
		if (clientDetails) {
			const primaryNumber = clientDetails.twoFANumber && clientDetails.twoFANumber[0]
			const assembledPrimaryNumber = primaryNumber ? `+${primaryNumber.content.idd} ${primaryNumber.content.number}` : ""
			const getAddressType = (type) => {
				if (clientDetails){
					let address = clientDetails.addresses.find((address) => address && address.name && address.name === type)
					return address && address.content && address.content.fullAddress ? { label: address.content.fullAddress, value: address.content.fullAddress} : null
				}
				else return null
			}
			setEmail(clientDetails.email ? clientDetails.email : "")
			setPhone(assembledPrimaryNumber)
			setPhysicalAddress(getAddressType("Physical"))
			setPostalAddress(getAddressType("Postal"))
		}
	}, [clientDetails, accountDetails])

	return (
		<Grid item container spacing={3}>
			<Grid item xs={12}>
				<h1>Contact Information</h1>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="Phone Number" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="Email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
			</Grid>
			<Grid item xs={12}>
				<SingleSelectInput
					disabled
					hideDropdownIndicator
					label="Physical Address"
					placeholder="Physical Address"
					value={physicalAddress}
					onChange={(value) => setPhysicalAddress(value)}
					clearValue={() => setPhysicalAddress("")}
					options={dummyAddresses}
				/>
			</Grid>
			<Grid item xs={12}>
				<SingleSelectInput
					disabled
					hideDropdownIndicator
					label="Postal Address"
					placeholder="Postal Address"
					value={postalAddress}
					onChange={(value) => setPostalAddress(value)}
					clearValue={() => setPostalAddress("")}
					options={dummyAddresses}
				/>
			</Grid>
		</Grid>
	);
}

export default AccInfoContactSection;
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Button from '../../../buttons/Button';
import TextInput from '../../../inputs/TextInput';
import GridItemDivider from '../../../layout/GridItemDivider';
import { useRemote } from '../../../utils/ApiUtils';
import Toast from '../../../misc/Toast'


const ContactPage = () => {
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [openSuccessToaster, setOpenSuccessToaster] = useState(false)
	const [openFailToaster, setOpenFailToaster] = useState(false)
	const remote = useRemote()

	const handleSend = () => {
		remote.post("/modules/hobson-support/stockfox/contact-us", { subject: subject, message: message }).then((response) => {
			if (response.success === true) {
				setOpenSuccessToaster(true)
				setSubject("")
				setMessage("")
			}
			else {
				setOpenFailToaster(true)
			}
		})
	}

	const handleSuccessClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSuccessToaster(false);
	};
	const handleFailClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenFailToaster(false);
	};


	return (
		<>
			<Toast
				open={openSuccessToaster}
				message={<div>Thanks for getting in touch!</div>}
				type="positive"
				handleClose={handleSuccessClose}
			/>
			<Toast
				open={openFailToaster}
				message={<div>Could not send your message at this time.</div>}
				type="danger"
				handleClose={handleFailClose}
			/>
			<Grid item container spacing={3} className="transition-fade-up-slow">
				<Grid item xs={12}>
					<div className="title colour-text-black">Get in Touch</div>
				</Grid>
				<Grid item container spacing={3} alignItems="flex-end">
					<Grid item xs={12}>
						<TextInput required valid={subject} label="Subject" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} controlledByProps />
					</Grid>
					<Grid item xs={12}>
						<TextInput textArea required valid={message} label="Message" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} controlledByProps />
					</Grid>
					<Grid item xs={12}>
						<Button disabled={!subject || !message} onClick={() => handleSend()}>Send</Button>
					</Grid>
				</Grid>
				<GridItemDivider />
				<Grid item xs={12}>
					<h1 className='mb-m'>Address</h1>
					<h4 className="mb-s">Level 4, Australis Nathan Buildings</h4>
					<h4 className="mb-s">37 Galway Street, Britomart</h4>
					<h4>Auckland 1010</h4>
				</Grid>
			</Grid>
		</>
	);
}

export default ContactPage;
import { Grid } from '@material-ui/core';
import React from 'react';
import useWindowDimensions from '../utils/DisplayUtils';
import TextInput from './TextInput';

const AmountRangeInputs = ({ gap, minAmount, maxAmount, label, xs, sm}) => {
	const { width } = useWindowDimensions();
	const shortLabel = width > 599 && width < 660;
	return (
		<>
			<Grid item xs={xs ? xs : 6} sm={sm ? sm : 2} className="transition-default">
				<TextInput type="number" label={label ? ("Min " + label) : shortLabel ? "Min" : "Min Amount"} placeholder="0" value={minAmount.value} onChange={minAmount.onChange} controlledByProps />
			</Grid>
			<Grid item xs={xs ? xs : 6} sm={sm ? sm : 2} className="transition-default">
				<TextInput type="number" label={label ? ("Max " + label) : shortLabel ? "Max" : "Max Amount"} placeholder="0" value={maxAmount.value} onChange={maxAmount.onChange} controlledByProps />
			</Grid>
		</>
   );
}

export default AmountRangeInputs;
import React from 'react';
import { Info } from 'react-feather';
import RadioButtonGroup from '../inputs/RadioButtonGroup';

const OrderTypeFilterRadio = ({ onChange, value, limitOrderDisabled, disabled }) => {
	// Order type options
	const orderTypeOptions = [
		{ value: "Market", label: 'Market', disabled: disabled},
		{ value: "Limit", label: 'Limit', icon: (limitOrderDisabled ? <Info /> : ""), tooltip: (limitOrderDisabled ? "Cannot place orders outside of trading hours" : ""), disabled: limitOrderDisabled || disabled}
	];

	return (
		<RadioButtonGroup
			label="Order Type"
			options={orderTypeOptions}
			id="order-type"
			onChange={onChange}
			value={value}
		/>
   );
}

export default OrderTypeFilterRadio;
import React from 'react';
import FlexWrapper from '../layout/FlexWrapper';
import Checkbox from './Checkbox';
import TextFieldLabel from './TextFieldLabel';

const CheckboxGroup = ({checkboxes, label, id}) => {
	return (
		<div className="checkbox-group">
			<TextFieldLabel id={id}>{label}</TextFieldLabel>
			<FlexWrapper gap="s" fullWidth>
				{checkboxes && checkboxes.map((checkbox, idx) => <Checkbox key={idx} id={checkbox.id} label={checkbox.label} checked={checkbox.checked} onChange={checkbox.onChange} />)}
			</FlexWrapper>
		</div>
	);
}

export default CheckboxGroup;

import React, { useState, useEffect } from "react";
import moment from "moment";
import withChartDurationSelected from "@threeskye/core-components/dist/components/Charts/withChartDurationSelected";
import { isNullOrUndefined } from "./commonFunctions";
import { display } from './ValidationUtils'
import { useRemote, useStorage } from "./ApiUtils";


const withValuations = (WrappedComponent) => {

	return withChartDurationSelected(
		(props) => {

			const timeFrames = [
				{ label: "1m", value: 31 },
				{ label: "3m", value: 93 },
				{ label: "6m", value: 186 },
				{ label: "1y", value: 365 },
				{ label: "2y", value: 730 },
			];

			const [selectedTimeFrameIndex, setStateSelectedTimeFrameIndex] = useState(4)
			const [loadingValuations, setLoadingValuations] = useState(true)
			const [valuation, setValuation] = useState(null)
			const [account, setAccount] = useState({});

			let remote = useRemote()
			let storage = useStorage();

			useEffect(() => {
				storage.getOrFetch("/modules/hobson-support/stockfox/accounts").then(json => {
					setAccount(json[0])});
			},[storage]);

			useEffect(() => { 
				if (!account || !account.id) {
					return;
				}
				fetchValuations();
			}, [account])


			const fetchValuations = () => {
				const startDate = "2019-01-01";
				const now = new Date();
				const endDate = display.dateForDTO(now.getDate(), now.getMonth(), now.getFullYear());
				setLoadingValuations(true);
				if (account === {}) return;
				Promise.all([
					remote.get(`/modules/crm/accounts/${account.id}/valuation?startDate=${startDate}&endDate=${endDate}`),
					remote.get(`/modules/crm/accounts/${account.id}/capital-movements?startDate=${startDate}&endDate=${endDate}`),
				]).then(([valuation, contributionsResponse]) => {
					let map = {};
					if (valuation && valuation.length > 0) {
						map = valuation.reduce((vMap, { date, value }) => ({ ...vMap, [date]: { value } }), map);
					}
					if (contributionsResponse && !contributionsResponse.connectionError && contributionsResponse.data && contributionsResponse.data.length > 0) {
						map = contributionsResponse.data.reduce((vMap, { date, value }) => ({ ...vMap, [date]: { ...(vMap[date] || {}), value2: value } }), map);
					}
					valuation = Object.keys(map)
						.map((date) => ({ date: parseInt(moment(date).format("x")), ...map[date] }))
						.sort((a, b) => a.date - b.date);
					// valuation = valuation.map((v) => ({ date: new Date(v.date).getTime(), value: v.value }));
					setValuation(valuation)
					setLoadingValuations(false);
					props.durationSelected(timeFrames[selectedTimeFrameIndex].value, valuation, false);
				});
			}

			const setSelectedTimeFrameIndex = (selectedTimeFrameIndex) => {
				setStateSelectedTimeFrameIndex(selectedTimeFrameIndex);
				props.durationSelected(timeFrames[selectedTimeFrameIndex].value, valuation, false);
			}

			const renderTooltip = (payload) => {
				if (!payload || !payload.payload) {
					return null;
				}
				const { isPerformance, startValue } = props.withDurationSelectedData;
				if (!payload.active || payload.payload.length === 0) return null;

				let value = (isPerformance ? startValue : 0) + payload.payload[0].payload.value;
				let value2 = payload.payload[0].payload.value2;
				let date = moment(payload.payload[0].payload.date);
				const hasValue1 = !isNullOrUndefined(value) && !isNaN(value);
				const hasValue2 = !isNullOrUndefined(value2);
				return (
					<div className="valuations-graph-tooltip">
						<div className="valuations-graph-tooltip-label">{date.format("Do MMMM YYYY")}</div>
						{hasValue1
							? <div className="valuations-graph-tooltip-value">Value: {display.money(value)}</div>
							: <div className="valuations-graph-tooltip-value">No valuation available</div>
						}
						{hasValue2 && <div className="valuations-graph-tooltip-contribution">Contributions: {display.money(value2)}</div>}
					</div>
				);
			}

			const { withDurationSelectedData } = props;
			return (
				<WrappedComponent
					loadingValuations={loadingValuations}
					selectedTimeFrameIndex={selectedTimeFrameIndex}
					valuation={valuation}
					setSelectedTimeFrameIndex={setSelectedTimeFrameIndex}
					timeFrames={timeFrames}
					hasValuations={valuation && valuation.length > 0 && Object.keys(withDurationSelectedData).length > 0}
					chartProps={{ ...withDurationSelectedData, renderTooltip: renderTooltip, withBackground: true }}
					{...props}
				/>
			);
		}
	)
	// )
	// );
}
export default withValuations
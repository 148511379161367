import React from 'react';
import FlexWrapper from '../../../layout/FlexWrapper';
import MobileOrderListItem from '../../../tables/MobileOrderListItem';
import OrderListItem from '../../../tables/OrderListItem';
import OrderListTotals from '../../../tables/OrderListTotals';
import useWindowDimensions from '../../../utils/DisplayUtils';

const OrderList = ({ header, marketOrders, setMarketOrders, estimatedTotalValue, estimatedTotalBalance }) => {
	// const estimatedTotal = "$15.40";
	// const estimatedNewBalance = "$15.40";
	const removeOrder = (order) => {
		let newMarketOrders = marketOrders.filter((orderInList) => {
			if (orderInList.code !== order.code || orderInList.action !== order.action) return true
			else return false
		})
		setMarketOrders(newMarketOrders)
	}

	const { width } = useWindowDimensions();
	const isMobile = width < 1100;

	return (
		<>
			<FlexWrapper gap="m" wrap>
				{header && (
					<h1 className="font-weight-semi-bold">
						{header} ({marketOrders.length})
					</h1>
				)}
				{marketOrders.map((order, idx) => {
					return (
						<>
							{isMobile ? (
								<MobileOrderListItem
									order={order}
									key={idx}
									removeOrder={removeOrder}
									expandedContent={[
										{ label: "Type", value: order.type },
										{ label: "Asset Unit Price", value: order.assetPrice },
										{ label: "Quantity", value: order.quantity },
									]}
								/>
							) : (
								<OrderListItem order={order} key={idx} removeOrder={removeOrder} />
							)}
						</>
					);
				})}
			</FlexWrapper>
			{/* Order list totals */}
			<OrderListTotals estimatedTotal={estimatedTotalValue ? estimatedTotalValue : 0} estimatedNewBalance={estimatedTotalBalance ? estimatedTotalBalance : 0} />
		</>
	);
}

export default OrderList;
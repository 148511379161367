import React from 'react';
import "./EmptyStateMessage.scss";
import { AlertCircle } from 'react-feather';
import FlexWrapper from '../layout/FlexWrapper';

const EmptyStateMessage = ({message, icon, noMargin}) => {
	return (
		<FlexWrapper gap="s" className="empty-state-message mt-m mb-m" fullWidth>
			{icon ? icon : <AlertCircle />} <span className="empty-state-message">{message}</span>
		</FlexWrapper>
   );
}

export default EmptyStateMessage;
import { Grid } from '@material-ui/core';
import React from 'react';

const ErrorPage = () => {
	return (
		<Grid item container spacing={3} className="transition-fade-up-slow">
			<Grid item xs={12}>
				<div className="title colour-text-black">Page Not Found</div>
			</Grid>
		</Grid>
	);
}


export default ErrorPage;
import React, { useState, useEffect } from "react";
import { isNullOrUndefined } from "../utils/commonFunctions";
import NumberFormat from "../utils/NumberFormat";
import TableHeader from "./TableHeader";
import "./SimpleTable.scss";
import AssetLogo from "../misc/ticker-assets/AssetLogo";
import FlexWrapper from "../layout/FlexWrapper";
import useWindowDimensions from "../utils/DisplayUtils";
import MobileOrderListItem from "./MobileOrderListItem";
import PortalCard from "../layout/PortalCard";
import { display as displayFuncs } from "../utils/ValidationUtils";
import { parseISO, format } from "date-fns";

export const fields = {
	date: "DATE",
	ticker: "TICKER",
	status: "STATUS",
	action: "ACTION",
	type: "TYPE",
	qty: "QUANTITY",
	assetPrice: "UNIT_COST",
	currency: "CURRENCY",
	total: "TOTAL",
};


const OrderHistoryList = ({ orderHistory, assets, tickers }) => {
	const [sortedOrderHistory, setSortedOrderHistory] = useState(null)
	const { width } = useWindowDimensions();
	const isMobile = width < 1000;
	
	useEffect(() => {
		setSortedOrderHistory(orderHistory)
	}, [orderHistory])
	
	const headers = {
		[fields.date]: "Date",
		[fields.ticker]: "Ticker",
		[fields.status]: "status",
		[fields.action]: "action",
		[fields.type]: "type",
		[fields.qty]: "Unit Qty",
		[fields.assetPrice]: "Unit Price",
		[fields.currency]: "Currency",
		[fields.total]: "Total",
	};
	
	const display = {
		[fields.date]: ({ createdAt }) => (isNullOrUndefined(createdAt) ? null : format(parseISO(createdAt), "dd/MM/yyyy")),
		[fields.ticker]: ({ assetId }, tickers, assets) => getTickerFromAssetId(assetId, tickers, assets),
		[fields.status]: ({ status }) => displayFuncs.titleCase(status),
		[fields.action]: ({ action }) => displayFuncs.titleCase(action),
		[fields.type]: ({ type }) => displayFuncs.titleCase(type),
		[fields.assetPrice]: ({ unitCost }) => <NumberFormat places={2} prefix="$" value={unitCost} />,
		[fields.qty]: ({ quantity }) => <NumberFormat value={quantity} />,
		[fields.total]: ({ estimatedCost }) => (isNullOrUndefined(estimatedCost) ? null : <NumberFormat places={2} prefix="$" value={estimatedCost} />),
	};
	
	const getTickerFromAssetId = (assetId) => {
		if (!assets || !tickers || !assetId ) return null;
		let assetFound = assets.find((asset) => asset.id === assetId)
		return tickers.find((ticker) => {
			let splitTicker = ticker.ric.split(".")
			if ((splitTicker[0] === assetFound.tickerCode) + (splitTicker[1] + "X " === assetFound.exchange)) return true
			else return false
		})
	}
	
	let loadingMessage = false;
	if (loadingMessage) {
		return <div>LOADING!</div>;
		// } else if (connectionError) {
		// 	return <GenericConnectionFailErrorMessage />;
		// } else if (isMobile() ? !transactionHistory || transactionHistory.length === 0 : !sortedTransactionHistory || sortedTransactionHistory.length === 0) {
		// 	return <ErrorMessage infoIcon>No transactions to display for this period and these filters.</ErrorMessage>;
	} else {
		return (
			<div className="order-history-list">
				{isMobile ? (
					<FlexWrapper gap="m" wrap>
						{sortedOrderHistory &&
							sortedOrderHistory.length > 0 &&
							sortedOrderHistory.map((order, idx) => (
								<MobileOrderListItem
									order={{
										logo: order.ticker && order.ticker.img,
										code: order.ticker && order.ticker.code,
										exchange: order.ticker && order.ticker.exchange,
										estimatedCost: order.total,
										action: order.action,
									}}
									key={idx}
									subLabel={display[fields.date](order)}
									expandedContent={[
										{ label: "Assset", value: order.ticker && order.ticker.label },
										{ label: "Status", value: order.status },
										{ label: "Asset Unit Price", value: order.ticker && order.ticker.assetPrice },
										{ label: "Type", value: order.type },
										{ label: "Quantity", value: order.qty },
									]}
								/>
							))}
					</FlexWrapper>
				) : (
					<PortalCard>
						<table className="simple-table">
							<tbody>
								<tr>
									<TableHeader value={headers[fields.date]} />
									<TableHeader value={headers[fields.ticker]} />
									<TableHeader value={headers[fields.status]} />
									<TableHeader value={headers[fields.action]} />
									<TableHeader value={headers[fields.type]} />
									<TableHeader value={headers[fields.qty]} alignment="right" />
									<TableHeader value={headers[fields.assetPrice]} alignment="right" />
									<TableHeader value={headers[fields.total]} alignment="right" />
								</tr>
							</tbody>
							<tbody>
								{sortedOrderHistory &&
									sortedOrderHistory.length > 0 &&
									sortedOrderHistory.map((order, index) => (
										<tr key={index}>
											<td className="td-date">{display[fields.date](order) || "-"}</td>
											<td className="td-ticker">
												<FlexWrapper gap="s">
													<AssetLogo logo={display[fields.ticker](order, tickers) && display[fields.ticker](order, tickers).logo} size={20} /> {display[fields.ticker](order) && display[fields.ticker](order).ric || "-"}
													{/* {(display[fields.ticker](order, tickers) && display[fields.ticker](order, tickers, assets).ric) || "-"} */}


												</FlexWrapper>
											</td>
											<td className="td-status">{display[fields.status](order) || "-"}</td>
											<td className={`td-action ${order.action === "Buy" ? "colour-text-positive" : order.action === "Sell" ? "colour-text-danger" : ""}`}>
												{display[fields.action](order) || "-"}
											</td>
											<td className="td-type">{display[fields.type](order) || "-"}</td>
											<td className="text-align-right">{display[fields.qty](order) || "-"}</td>
											<td className="text-align-right">{display[fields.assetPrice](order) || "-"}</td>
											<td className={`td-amount text-align-right ${order.nativeCurrencyValue < 0 ? "negative" : "positive"}`}>
												{display[fields.total](order) || "-"}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</PortalCard>
				)}
			</div>
		);
	}
}


export default OrderHistoryList;

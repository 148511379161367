import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../buttons/Button';
import FlexWrapper from '../layout/FlexWrapper';
import Modal from '../layout/Modal';
import SplitValueField from '../misc/SplitValueField';
import SimpleTable from '../tables/SimpleTable';
import { createData } from '../utils/DisplayUtils';
import OrderPlacedMessage from '../messages/OrderPlacedMessage';
import "./ReviewOrderModal.scss";
import LimitOrderMessage from '../messages/LimitOrderMessage';
import NumberFormat from '../utils/NumberFormat';

const ReviewOrderModal = ({ handleClose, open, action, orderType, asset, assetPriceLimit, quantity, estimatedCost, estimatedTotalBalance }) => {
	const [orderPlaced, setOrderPlaced] = useState(false)
	const [rows, setRows] = useState([])
	const [splitValueData, setSplitValueData] = useState([{ label: "" }, { label: "", className: "colour-text-positive" }, { label: "" }])

	
	useEffect(() => {

		const marketRows = [
			createData("Asset Price", <NumberFormat places={2} prefix="$" value={asset && asset.assetPrice} />),
			createData("Quantity of Shares", quantity),
			createData(estimatedCost < 0 ? "Estimated value (NZD)" : "Estimated Cost (NZD)", <NumberFormat places={2} prefix="$" value={estimatedCost < 0 ? estimatedCost * -1 : estimatedCost} />),
			createData("Estimated New Cash Balance (NZD)", <NumberFormat places={2} prefix="$" value={ estimatedTotalBalance} />),
		];
		const limitRows = [
			createData("Asset Price", <NumberFormat places={2} prefix="$" value={asset && asset.assetPrice} />),
			createData("Price Limit Per Share (NZD)", <NumberFormat places={2} prefix="$" value={assetPriceLimit} />),
			createData("Quantity of Shares", quantity),
			createData(estimatedCost < 0 ? "Estimated value (NZD)" : "Estimated Cost (NZD)", <NumberFormat places={2} prefix="$" value={estimatedCost < 0 ? estimatedCost * -1 : estimatedCost} />),
			createData("Estimated New Cash Balance (NZD)", <NumberFormat places={2} prefix="$" value={ estimatedTotalBalance} />),
		];
		
		setSplitValueData([
			{ label: asset && asset.code },
			{ label: action && action.toUpperCase(), className: "colour-text-positive" },
			{ label: orderType + " Order" }
		])
		setRows(orderType === "market" ? marketRows : limitRows)
	}, [asset, action, orderType, assetPriceLimit, quantity, estimatedCost, estimatedTotalBalance])

	return (
		<Modal open={open} handleClose={handleClose} className="order-review-modal">
			{orderPlaced ? (
				<OrderPlacedMessage orderType={orderType} handleClose={handleClose} />
			) :
				action && orderType && asset && (assetPriceLimit || assetPriceLimit === 0) && quantity && estimatedCost && estimatedTotalBalance ?
					(
						<>
							<Grid item xs={12}>
								<SplitValueField values={splitValueData} />
							</Grid>
							{orderType === "Limit" ? (
								<Grid item xs={12}>
									<LimitOrderMessage />
								</Grid>
							) : (
								""
							)}
							<Grid item xs={12}>
								<SimpleTable rows={rows} />
							</Grid>
							<Grid item xs={12}>
								<FlexWrapper gap="m" justify="flex-end">
									<Button variant="dark" onClick={handleClose}>
										Go Back
									</Button>
									<Button onClick={() => setOrderPlaced(true)}>Place Order</Button>
								</FlexWrapper>
							</Grid>
						</>
					)
				: <div>order unfinished</div>
				}
		</Modal>
	);
}

export default ReviewOrderModal;
import React from 'react';
import "./TableData.scss";

const TableData = ({value, children, alignment, className}) => {
	return (
		<td className={`table-data${className ? ' ' + className : ''}`} style={{textAlign: alignment ? alignment : "left"}}>
			{value || children}
		</td>
   );
}

export default TableData;
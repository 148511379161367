import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../../buttons/Button';
import "./OnboardingWrapper.scss";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRemote } from '../../utils/ApiUtils';

const OnboardingWrapper = () => {
	const location = useLocation();
	const pathname = location.pathname;
	const navigate = useNavigate();
	const onboardingPage = pathname === "/onboarding";
	const detailedInfoPage = pathname === "/onboarding/detailed-info";
	const completePage = pathname === "/onboarding/complete";
	const bankAccPage = pathname === "/onboarding/bank-acc-verification";

	const [clientSetup, setClientSetup] = useState({})

	const [nextValid, setNextValid] = useState(false);

	const [nextFunc, setNextFunc] = useState(() => { });

	const remote = useRemote();


	const nextClicked = () => {
		if (nextFunc.method === "put") {
			remote.put(nextFunc.url, nextFunc.payload).then(json => {
				if (json.success) {
					setClientSetup(json.data);
					if (nextFunc.listeners && nextFunc.listeners.length >= 1) {
						nextFunc.listeners.forEach((listener) => listener())
					}
				} else {
					//todo!
				}
			});
		} else {
			remote.post(nextFunc.url, nextFunc.payload).then(json => {
				if (json.success) {
					setClientSetup(json.data);
					if (nextFunc.listeners && nextFunc.listeners.length >= 1) {
						nextFunc.listeners.forEach((listener) => listener())
					}
				} else {
					//todo!
				}
			});
		}
	}

	return (
		<div id="onboarding-wrapper" className="p-m">
			{/* Map pages */}
			<Outlet context={[clientSetup, setClientSetup, setNextValid, remote, setNextFunc]} />
			{/* Navigation buttons */}
			{pathname !== "complete" && (
				<Grid container spacing={3} justifyContent="flex-end" className="pt-l">
					{!onboardingPage && !completePage && !bankAccPage && (
						<Grid className="transition-fade-up" item xs="auto">
							<Button onClick={() => navigate(-1)} variant="secondary">
								Back
							</Button>
						</Grid>
					)}
					{!completePage && !bankAccPage && (
						<Grid className="transition-fade-up-slow" item xs="auto">
							<Button onClick={() => nextClicked()} disabled={!nextValid}>
								{detailedInfoPage ? "Complete" : "Next"}
							</Button>
						</Grid>
					)}
				</Grid>
			)}
		</div>
	);
}

export default OnboardingWrapper;
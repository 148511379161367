import React, { Component } from "react";
import PropTypes from "prop-types";
import TextInput from "./TextInput"

class FilterInput extends Component {
	render() {
		let { label, name, type, value, placeholder, onChange, onKeyPress, disabled, onBlur, autoFocus, icon, ...props} = this.props;
		type = type || name;
		return (
			<TextInput
				style={{ paddingLeft: icon ? 37 : "" }}
				autoFocus={autoFocus}
				name={name}
				type={type}
				value={value || ""}
				placeholder={placeholder}
				onChange={(userInput) => onChange(userInput.target.value)}
				onKeyPress={onKeyPress}
				disabled={disabled}
				onBlur={onBlur}
				label={label}
				{...props}
			/>
		);
	}
}

FilterInput.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	errorMessage: PropTypes.string,
};

export default FilterInput;

import React from 'react';
import { assetOption } from './CustomSelectOptions';
import SingleSelectInput from './SingleSelectInput';

const AssetSelectField = ({ value, setValue, placeholder, label, onClearValue, onChange, tickers, ...props }) => {
	return (
		<SingleSelectInput
			{...props}
			formatOptionLabel={assetOption}
			label={label ? label : "Asset"}
			placeholder={placeholder ? placeholder : "Asset"}
			options={tickers}
			hideDropdownIndicator
			value={value}
			onChange={onChange}
			onClearValue={onClearValue}
			blurInputOnSelect
			closeMenuOnScroll
		/>
	);
}

export default AssetSelectField;
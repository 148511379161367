import React from "react";
import ThreeSkyeLineChart from "@threeskye/core-components/dist/components/Charts/ThreeSkyeLineChart";
import withValuations from "../../utils/withValuations";
import TimeFrameSelectionMenu from "./TimeFrameSelectionMenu";
import TimeFrameSelectionItem from "./TimeFrameSelectionItem";
import { useRemote } from "../../utils/ApiUtils";
import LoadingIcon from '../LoadingIcon'
import "./ChartOverrides.scss";
import "./PortfolioChart.scss";
import PortalCard from "../../layout/PortalCard";
import FlexWrapper from "../../layout/FlexWrapper";

const PortfolioChart = ({ loadingValuations, valuation, selectedTimeFrameIndex, setSelectedTimeFrameIndex, hasValuations, chartProps }) => {
		const remote = useRemote()

		const mockData = [
			{ date: 1606129200000, value: 9999.99987426, value2: 10000 },
			{ date: 1606215600000, value: 9999.99987426, value2: 60000 },
			{ date: 1606302000000, value: 9999.99987426, value2: 100000 },
			{ date: 1606388400000, value: 59996.52147956, value2: 100000 },
			{ date: 1606474800000, value: 59996.52147956, value2: 100000 },
			{ date: 1606561200000, value: 59996.52147956, value2: 100000 },
			{ date: 1606647600000, value: 99958.01508498, value2: 100000 },
			{ date: 1606734000000, value: 99938.87043042001, value2: 100000 },
			{ date: 1606820400000, value: 99905.9574129, value2: 100000 }
		]

		// remote.get("/modules/crm/client/accounts")
		return (
			<PortalCard className="portfolio-chart-wrapper">
				<FlexWrapper justify="space-between" className="mb-m">
					<h2>Valuation</h2>
					<TimeFrameSelectionMenu>
						<TimeFrameSelectionItem onClick={() => setSelectedTimeFrameIndex(0)}>1m</TimeFrameSelectionItem>
						<TimeFrameSelectionItem onClick={() => setSelectedTimeFrameIndex(1)}>3m</TimeFrameSelectionItem>
						<TimeFrameSelectionItem onClick={() => setSelectedTimeFrameIndex(2)}>6m</TimeFrameSelectionItem>
						<TimeFrameSelectionItem onClick={() => setSelectedTimeFrameIndex(3)}>1y</TimeFrameSelectionItem>
						<TimeFrameSelectionItem onClick={() => setSelectedTimeFrameIndex(4)}>2y</TimeFrameSelectionItem>
					</TimeFrameSelectionMenu>
				</FlexWrapper>
				{/* {valuation && <ThreeSkyeLineChart chartData={valuation} {...chartProps}/>} */}
				{loadingValuations? <FlexWrapper justify="center" fullWidth><LoadingIcon colour="brand" /></FlexWrapper> : <ThreeSkyeLineChart chartData={valuation} {...chartProps} />}
			</PortalCard>
		)
	}

	export default withValuations(PortfolioChart);
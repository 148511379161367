import React from 'react';
import CheckboxGroup from '../inputs/CheckboxGroup';

const OrderTypeFilterCheck = ({ setTypeMarket, typeMarket, setTypeLimit, typeLimit }) => {
	return (
		<CheckboxGroup
			label="Order"
			checkboxes={[
				{
					label: "Market",
					onChange: setTypeMarket,
					checked: typeMarket
				},
				{
					label: "Limit",
					onChange: setTypeLimit,
					checked: typeLimit
				},
			]}
		/>
	);
}

export default OrderTypeFilterCheck;
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../../buttons/Button';
import TextInput from '../../../inputs/TextInput';
import FlexWrapper from '../../../layout/FlexWrapper';
import Logos from "../../../../resources/images/main-logo.png";
import { ChevronLeft } from 'react-feather';
import { useNavigate } from "react-router-dom";

const TwoFAPage = ({onSubmit, twoFAMessage}) => {
	const [code, setCode] = useState("");
	const navigate = useNavigate();

	return (
		<Grid container spacing={3} className="transition-fade-in-left">
			<Grid item xs={12}>
				<div className="title colour-text-black">Two Factor Authentication</div>
			</Grid>
			<Grid item xs={12}>
				<p className="m-none colour-text-lm-dark-grey">
					{twoFAMessage}
				</p>
			</Grid>
			{/* Fields */}
			<Grid item xs={12}>
				<TextInput autocomplete="one-time-code" autoFocus required placeholder="6 Digit Code" value={code} onChange={(e) => setCode(e.target.value)} />
			</Grid>
			<Grid item xs={12}>
				<Button disabled={!code} fullWidth onClick={() => onSubmit(code)}>
					Sign in Securely
				</Button>
			</Grid>
			<Grid item xs={12} container justifyContent="center">
				<img src={Logos} style={{ width: 100 }} className="mt-xl" alt="3skye" />
			</Grid>
		</Grid>
	);
}

export default TwoFAPage;
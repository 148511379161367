import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextInput from "../../../inputs/TextInput";
import Divider from "../../../layout/Divider";
import PasswordInput from '../../../inputs/PasswordInput';
import { validate, handleOnboardingInputChange, checkOnboardingInputValid, checkOnboardingInputInvalid } from '../../../utils/ValidationUtils'
import { useNavigate, useOutletContext } from 'react-router';
import { Loading } from '@threeskye/global/dist/ThreeSkyeGlobal';

const OnboardingBasicInfo = () => {
	const [firstName, setFirstName] = useState({ value: "", touched: false, valid: false });
	const [middleName, setMiddleName] = useState({ value: "", touched: false, valid: true });
	const [lastName, setLastName] = useState({ value: "", touched: false, valid: false });
	const [email, setEmail] = useState({ value: "", touched: false, valid: false });
	const [password, setPassword] = useState({ value: "", touched: false, valid: false });
	const [confirmPassword, setConfirmPassword] = useState({ value: "", touched: false, valid: false });

	const [clientSetup, setClientSetup, setNextValid, remote, setNextFunc] = useOutletContext();

	const nav = useNavigate();
	useEffect(() => {
		if (clientSetup && clientSetup.uuid) {
			console.log("Navigating to onboarding from useEffect")
			nav(`/onboarding/${clientSetup.uuid}`);
			return <Loading/>
		}
	}, [clientSetup]);

	useEffect(() => {
		console.log("Setting next valid to ", firstName.valid && middleName.valid && lastName.valid && email.valid && password.valid && confirmPassword.valid)
		setNextValid(firstName.valid === '' && middleName.valid && lastName.valid === '' && email.valid === '' && password.valid === '' && confirmPassword.valid === '')
		setNextFunc({
			url: "/public/modules/hobson-support/stockfox/client",
			payload: {
				email: email.value,
				password: password.value,
				firstName: firstName.value,
				middleName: middleName.value,
				lastName: lastName.value
			}
		});
	}, [firstName, middleName, lastName, email, password, confirmPassword]);

	return (
		<form>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<div className="title">Welcome!</div>
				</Grid>
				<Grid item xs={12}>
					<h1>Let's create your account</h1>
				</Grid>
			</Grid>
			{/* General Detail Fields Here */}
			<Grid className="transition-fade-up-slow" container spacing={5}>
				<Grid item xs={12} sm={6}>
					<TextInput required label="First Name*" placeholder="First Name" valid={checkOnboardingInputValid(firstName)} invalid={checkOnboardingInputInvalid(firstName)} helperText={firstName.valid && firstName.valid} value={firstName.value} onChange={(e) => handleOnboardingInputChange(e.target.value, validate.name, setFirstName)} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput label="Middle Name" placeholder="Middle Name" value={middleName.value} onChange={(e) => handleOnboardingInputChange(e.target.value, null, setMiddleName )} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput required label="Last Name*" placeholder="Last Name" valid={checkOnboardingInputValid(lastName)} invalid={checkOnboardingInputInvalid(lastName)} helperText={lastName.valid && lastName.valid} value={lastName.value} onChange={(e) => handleOnboardingInputChange(e.target.value, validate.lastName, setLastName)} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput required label="Email*" placeholder="Email" valid={checkOnboardingInputValid(email)} invalid={checkOnboardingInputInvalid(email)} helperText={email.valid && email.valid} value={email.value} onChange={(e) => handleOnboardingInputChange(e.target.value, validate.email, setEmail)} />
				</Grid>
			</Grid>
			<Grid className="transition-fade-up-slow" container spacing={5}>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
			{/* Password Detail Fields Here */}
			<Grid className="transition-fade-up-slow" container spacing={5}>
				<Grid item xs={12}>
					<h1>Create New Password</h1>
				</Grid>
				<Grid item xs={12} md={6}>
					<PasswordInput label="Password*" required valid={checkOnboardingInputValid(password)} invalid={checkOnboardingInputInvalid(password)} helperText={password.valid && password.valid} value={password.value} onChange={(e) => handleOnboardingInputChange(e.target.value, validate.password, setPassword)} />
				</Grid>
				<Grid item xs={12} md={6}>
					<PasswordInput
						label="Confirm Password"
						required
						valid={checkOnboardingInputValid(confirmPassword)}
						invalid={checkOnboardingInputInvalid(confirmPassword)}
						helperText={confirmPassword.valid && confirmPassword.valid}
						value={confirmPassword.value}
						onChange={(e) => handleOnboardingInputChange(e.target.value, validate.confirmPassword, setConfirmPassword, [password.value])}
					/>
				</Grid>
			</Grid>
		</form>
	);
}
export default OnboardingBasicInfo;
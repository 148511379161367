import React from "react";
import { X } from "react-feather";
import Divider from "../layout/Divider";
import FlexWrapper from "../layout/FlexWrapper";
import Tooltip from "../layout/Tooltip";
import AssetLogo from "../misc/ticker-assets/AssetLogo";
import LabelledValue from "../pages/portal/shared/LabelledValue";
import "./OrderListItem.scss";

const OrderListItem = ({ order, removeOrder }) => {
	return (
		<div className="order-list-item">
			<FlexWrapper gap="s">
				<AssetLogo logo={order.logo} alt={order.name} />
				<div className="order-list-item-code">
					{order.code} | {order.exchange}
				</div>
			</FlexWrapper>
			<div className="order-list-item-action">
				<LabelledValue
					align="left"
					label="Action"
					value={order.action}
					valueClassName={`font-weight-semi-bold${order.action === "buy" ? " colour-text-positive" : order.action === "sell" ? " colour-text-danger" : ""}`}
				/>
			</div>
			<div className="order-list-item-type">
				<LabelledValue align="left" label="Type" value={order.type} valueClassName={`font-weight-semi-bold`} />
			</div>
			<div className="order-list-item-asset-price">
				<LabelledValue align="right" label={"Asset Price (" + order.currency + ")"} value={order.assetPrice} valueClassName="font-weight-semi-bold" />
			</div>
			<div className="order-list-item-quantity">
				<LabelledValue align="right" label="Qty" value={order.quantity} valueClassName="font-weight-semi-bold" />
			</div>
			<div className="order-list-item-estimated-cost">
				<LabelledValue align="right" label={order.action === "sell" ? "Estimated Value (" + order.currency + ")" : "Estimated Cost (" + order.currency + ")"} value={order.estimatedCost} valueClassName="font-weight-semi-bold" />
			</div>
			<Tooltip title="Remove from list" enterDelay={1000}>
				<X size={16} className="order-list-item-remove" onClick={() => removeOrder(order)} />
			</Tooltip>
		</div>
	);
};

export default OrderListItem;

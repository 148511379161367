import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../../../buttons/Button';
import OrderTypeFilterRadio from '../../../filters/OrderTypeFilterRadio';
import AssetSelectField from '../../../inputs/AssetSelectField';
import NumberFieldInput from '../../../inputs/NumberFieldInput';
import FlexWrapper from '../../../layout/FlexWrapper';
import ReviewOrderModal from '../../../popups/ReviewOrderModal';
import LabelledValue from '../shared/LabelledValue';
import NumberFormat from '../../../utils/NumberFormat'
import { useRemote, useStorage } from '../../../utils/ApiUtils';
import { useNavigate, useParams } from 'react-router-dom';

const OrderPadBuy = ({ orderType, onTypeChange, marketOrders, setMarketOrders, action, estimatedTotalBalance, account, cashBalance }) => {

	const [asset, setAsset] = useState(null);
	const [assetPrice, setAssetPrice] = useState("");
	const [assetPriceLimit, setAssetPriceLimit] = useState("");
	const [quantity, setQuantity] = useState("");
	const [estimatedCost, setEstimatedCost] = useState("");

	const [open, setOpen] = React.useState(false);
	const [dynamicBalance, setDynamicBalance] = useState(0)

	const [tickers, setTickers] = useState([]);
	// const [assets, setAssets] = useState([]);

	const [availableTickers, setAvailableTickers] = useState([]);
	const [assetsHeld, setAssetsHeld] = useState([]);

	const handleClose = () => setOpen(false);

	const remote = useRemote();
	const storage = useStorage();
	const navigate = useNavigate()
	const { ticker } = useParams()

	const pendingAML = !account || !account.status || !account.status.id || account.status.id === 4


	useEffect(() => {
		const getBufferedBalance = (cashBalance, estimatedCost) => {
			if (action === "buy") {
				return cashBalance - estimatedCost
			}
		}
		if (estimatedCost && cashBalance) {
			if (action === "sell") setDynamicBalance(cashBalance + estimatedCost)
			else setDynamicBalance(getBufferedBalance(cashBalance, estimatedCost))
		} else if (cashBalance) {
			setDynamicBalance(cashBalance)
		} else {
			setDynamicBalance(null)
		}
	}, [cashBalance, estimatedCost, action])

	useEffect(() => {
		if (!account || !account.id) {
			return;
		}
		storage.getOrFetch(`/modules/crm/accounts/${account.id}/portfolio`).then(portfolio => {
			if (portfolio.connectionError) {
				return;

			}
			if (portfolio.data && portfolio.data.classes) {
				const tempAssetsHeld = {};
				portfolio.data.classes.forEach(clazz => {
					if (clazz.holdings) {
						clazz.holdings.forEach(hold => {
							tempAssetsHeld[hold.assetId] = hold.units;
						})
					}
				})
				setAssetsHeld(tempAssetsHeld);
			}

		})
	}, [account, storage])

	useEffect(() => {
		let cost = orderType === "Limit" ? assetPriceLimit : assetPrice
		setEstimatedCost(cost * quantity)
	}, [assetPrice, assetPriceLimit, orderType, quantity])

	useEffect(() => {
		if (action === 'sell') {
			setAvailableTickers(tickers.filter(t => assetsHeld[t.assetId] > 0));
		} else {
			setAvailableTickers(tickers);
		}
	}, [action, assetsHeld, tickers])

	useEffect(() => {
		if (action === "sell") {
			setAsset(null)
		}
	}, [action])

	useEffect(() => {
		setAssetPrice("")
		setAssetPriceLimit("")
	}, [orderType])

	useEffect(() => {
		const tickers = storage.getOrFetch("/modules/hobson-support/stockfox/tickers");
		const availableAssets = storage.getOrFetch("/modules/hobson-support/stockfox/assets");
		Promise.all([tickers, availableAssets]).then(([tickerResponse, assetResponse]) => {
			//TODO - filter tickers by available assets.
			const tempTickerLookup = {};
			tickerResponse.forEach(ticker => {
				tempTickerLookup[ticker.isin] = ticker;
			});

			const tickers = assetResponse.map(ticker => {
				return {
					"value": ticker.tickerCode + "." + ticker.exchange,
					"label": ticker.name,
					"code": ticker.tickerCode + "." + ticker.exchange,
					"img": tempTickerLookup[ticker.isin] && tempTickerLookup[ticker.isin].logo,
					"assetId": ticker.id,
					"assetPrice": 123.45
				}
			});
			setTickers(tickers);
			setAvailableTickers(tickers);
			// setAssets(assetResponse)
			if (ticker) {
				setAsset(tickers.find((tickerFromList) => {
					return tickerFromList.code == ticker
				}))
			}
		})
	}, [storage])


	// Limit order specific variables.
	const limitOrderType = orderType === "Limit";

	const resetOrderPad = () => {
		setAsset(null)
		setAssetPrice("")
		setAssetPriceLimit("")
		setQuantity("")
	}

	const addOrder = () => {
		let prevMarketOrders = [...marketOrders]
		let checkForExistingOrder = prevMarketOrders.findIndex((order) => asset && order.code === asset.code)
		if (checkForExistingOrder === -1) {
			setMarketOrders([...marketOrders, { code: asset && asset.code, name: asset.label, type: orderType, exchange: "NZX", action: action, assetPrice: assetPrice, quantity: quantity, estimatedCost: estimatedCost, logo: asset && asset.img, currency: "NZD", DTO: { assetId: asset.assetId, ticker: asset && asset.code, name: asset.label, type: orderType.toUpperCase(), action: action.toUpperCase(), unitCost: limitOrderType ? assetPriceLimit : assetPrice, quantity: quantity, estimatedCost: estimatedCost && estimatedCost } }])
			resetOrderPad()
		}
	}
	// const getAssetId = (asset) => {
	// 	let assetData = asset && assets.find(assetToCheck => {

	// 		let selectedAssetSplit = asset.code.split(".")
	// 		console.log((selectedAssetSplit[0] === assetToCheck.tickerCode) + (selectedAssetSplit[1]+"X " === assetToCheck.exchange) )
	// 		if ((selectedAssetSplit[0] === assetToCheck.tickerCode) + (selectedAssetSplit[1]+"X " === assetToCheck.exchange)) return true
	// 	})
	// 	console.log(assetData)
	// 	return assetData && assetData.id ? assetData.id : null
	// }
	const placeOrder = () => {
		remote.post(`/modules/hobson-support/orders/${account.id}`, [{ assetId: asset.assetId, ticker: asset && asset.code, name: asset.label, type: orderType.toUpperCase(), action: action.toUpperCase(), unitCost: limitOrderType ? assetPriceLimit : assetPrice, quantity: quantity, estimatedCost: estimatedCost && estimatedCost }])
	}

	const handlePlaceOrder = () => {
		placeOrder()
		navigate("/order-history")
	}

	return (
		<>
			<Grid container spacing={3}>
				{/* Order Review Modal */}
				<ReviewOrderModal open={open} handleClose={handleClose} action={action} orderType={orderType} asset={asset} assetPriceLimit={assetPriceLimit} quantity={quantity} estimatedCost={estimatedCost} estimatedTotalBalance={estimatedTotalBalance} />
				<Grid item xs={12} sm={8}>
					<AssetSelectField
						tickers={availableTickers}
						onChange={(value) => { setAsset(value) }}
						value={asset}
						clearValue={() => { setAsset(""); setAssetPrice(null) }}
						disabled={pendingAML}
					/>
				</Grid>
				<Grid item xs={12} sm={4} className="transition-default">
					<OrderTypeFilterRadio
						onChange={(e) => {
							onTypeChange(e.target.value);
						}}
						disabled={pendingAML}
						value={orderType}
					/>
				</Grid>
				{/* Row 2 starts here */}
				<Grid item xs={12} sm={4} className="transition-default">
					<NumberFieldInput
						innerLabel="Shares"
						label="Quantity"
						placeholder="0"
						valid={quantity}
						value={parseInt(quantity)}
						onChange={(e) => setQuantity(e.target.value)}
						type="number"
						min="0"
						step="1"
						onKeyPress={(event) => {
							if (!/[0-9]/.test(event.key)) {
								event.preventDefault();
							}
						}}
						displayOnly={pendingAML}
						controlledByProps
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<NumberFieldInput
						innerLabel="NZD"
						label={limitOrderType ? "Limit Price" : "Unit Price"}
						// displayOnly={!limitOrderType}
						placeholder="0"
						valid={limitOrderType ? assetPriceLimit : assetPrice}
						value={limitOrderType ? assetPriceLimit : assetPrice}
						onChange={(e) => limitOrderType ? setAssetPriceLimit(e.target.value) : setAssetPrice(e.target.value)}
						controlledByProps
						min="0"
						onKeyPress={(e) => {
							if (e.code === 'Minus') {
								e.preventDefault();
							}
						}}
						displayOnly={pendingAML}
					/>
				</Grid>
				<Grid item xs={12} sm={4} className="transition-default">
					<NumberFieldInput
						displayOnly
						innerLabel="NZD"
						label="Estimated Cost"
						placeholder="0"
						valid={estimatedCost}
						value={estimatedCost && estimatedCost.toFixed(2)}
						onChange={(e) => setEstimatedCost(e.target.value)}
						controlledByProps
					/>
					{/* <LabelledValue fullWidth align="right" label="Estimated Cost" value={"$" + estimatedCost} /> */}

				</Grid>
				<Grid item xs={12}>
					<LabelledValue fullWidth align="right" label="Estimated New Balance (NZD)" value={dynamicBalance ? <NumberFormat places={2} value={dynamicBalance} prefix="$" /> : 0} />
				</Grid>
				<Grid item xs={12}>
					<FlexWrapper gap="s" justify="flex-end" className="pb-xl">
						<Button disabled={dynamicBalance < 0} variant="dark" hover="secondary" onClick={() => addOrder()}>
							Add to orders list
						</Button>
						<Button disabled={!asset || !estimatedCost || dynamicBalance < 0 || pendingAML} onClick={() => handlePlaceOrder()}>Place Order</Button>
					</FlexWrapper>
				</Grid>
			</Grid>
		</>
	);
}

export default OrderPadBuy;
import React, { Component } from "react";
// import Select from "react-select";
import SingleSelectInput from './SingleSelectInput'
import asClassName from "@threeskye/core-components/dist/components/Functions/asClassName";
// import "./SearchInput.scss";

class SearchInput extends Component {
	render() {
		let { label, className, labelProps, icon, ...selectProps } = this.props;
		labelProps = { ...labelProps, className: asClassName(["portal-label filter-input search-input-wrapper", labelProps && labelProps.className]) };
		className = asClassName(["search-input", className]);
		return (
			<SingleSelectInput label={label} className={className} classNamePrefix="search-input" isClearable {...selectProps} />
		);
	}
}

export default SearchInput;

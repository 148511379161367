import { withRemote, withI18n } from '@threeskye/global';
import { useRemote } from '../../utils/ApiUtils';
import React, { useState, useRef } from "react";
import Async from 'react-select/async'
import "../TextInput.scss";
import TextFieldLabel from "../TextFieldLabel";
import ValidationIcons from "../ValidationIcons";
import InputHelperText from "../InputHelperText";


const AddyLocationField = (props) => {


	const [isEmpty, setIsEmpty] = useState(true)
	const [locatable, setLocatable] = useState((props.value && props.value.locatable) || null)
	const [optionsLoading, setOptionsLoading] = useState(false)
	const [expanded, setExpanded] = useState(false)
	// const [expandedLoading, setExpandedLoading] = useState(false)
	const dropdown = useRef()
	const remote = useRemote()

	//Overwriting this behavior, keeps the menu closed when the user has erased text
	//The default behavior of this will leave all of the options visible if a user does this
	const handleInputChange = (input, actionInfo) => {
		switch (actionInfo.action) {
			case "menu-close":
				setLocatable(false)
				setExpanded(false)
				setIsEmpty(true)
				break;
			default:
				break;
		}
		// string, action required One of "set-value","input-change","input-blur","menu-close"
		if (input.length < 1) {
			setIsEmpty(true)
		} else {
			setIsEmpty(false)
		}
	}

	const setTextValue = (value) => {
		var dropdownRef = dropdown.current;
		if (dropdownRef && dropdownRef.props.value instanceof Object) {
			dropdownRef.props.value.text = value;
		}
	}

	const onChange = (value, { action, removedValue }) => {
		switch (action) {
			case 'select-option':
				setLocatable(value.locatable)
				break;
			case 'clear':
				setTextValue("");
				setLocatable(false)
				setExpanded(false)
				break;
			default:
				break;
		}
		props.onUpdate(value);
	}

	const findMatches = (text) => {
		if (text.length < 4) {
			return Promise.resolve([]);
		}

		// setOptionsLoading(true)
		return remote.get("/public/modules/hobson-support/stockfox/geocode/autocomplete/simple?q=" + text)
			.then(result => {
				setOptionsLoading(false);
				return result;
			});

	}

	const renderSuggestion = (suggestion) => {
		return <span className="suggestion-text">{suggestion.text}</span>;
	}

	const renderValue = (suggestion) => {
		return suggestion.text;
	}

	const renderCreateLabel = (text) => {
		return <span className="create-label">Use <span className="create-label-text">{text}</span></span>
	}

	const createSuggestion = (text, label) => {
		return {
			text: text,
			source: "new",
			locatable: true
		};
	}

	const toggleExpanded = () => {
		if (!props.value.locatable)
			return;

		if (!expanded) {
			if (!props.value.location) {
				doGeocodeLookup();
				setExpanded(true)
				// setExpandedLoading(true);
			} else {
				setExpanded(true);
			}
		} else {
			setExpanded(false);
		}
	}

	const doGeocodeLookup = () => {
		remote.post("/modules/crm/geocode/autocomplete/geocode", props.value)
			.then(location => {
				Object.assign(props.value, location);
				// setExpandedLoading(false);
			});
	}

	const addManualAddress = () => {
		// this can only show when adding or when the address label is already "Physical"
		if (props.manualOverrideEnabled) {
			return <div className="create-suggestion add-new-address" onClick={props.onManualOverride}>
				Add new Physical Address +
			</div>;
		}
		return <div className="no-options">No options</div>;

	}

	const components = (locatable && !(props.hideLocatable)) ? {
		DropdownIndicator: () => <i className="material-icons location-field-locator" onClick={toggleExpanded}>place</i>
	} : {
		DropdownIndicator: () => null,
		IndicatorSeparator: () => null
	}
	// let i = props.i18n.get;
	return (



		<div
			className={` select-input-wrapper text-input-wrapper${props.icon ? " text-input-icon-active" : ""}${props.helperText ? " helper-text-active" : ""}${props.invalid ? " text-input-wrapper-invalid" : ""}${props.valid ? " text-input-wrapper-valid" : ""}${props.fullWidth ? " input-full-width" : ""}${props.className ? " " + props.className : ""
				}`}
		>
			{props.label && (
				<TextFieldLabel>
					{props.label}
					<ValidationIcons required={props.required} valid={props.valid} infoIcon={props.infoIcon} />
				</TextFieldLabel>
			)}
			{props.icon && <div className="text-input-icon-wrapper" onClick={props.icon.onClick}>{props.icon.icon}</div>}
				<Async ref={dropdown}
					{...props}
					styles={{
						menuPortal: base => ({ ...base, zIndex: 9999 }),
						multiValueRemove: (base, state) => { return props.isRemovable(state.data) ? base : { ...base, display: "none" } }
					}}
					clearable={true}
					menuIsOpen={!isEmpty}
					onInputChange={handleInputChange}
					//Overriding the default format of the select
					//These options disable clicking for dropdown,
					//and disables the dropdown indicator.
					openMenuOnClick={false}
					blurInputOnSelect={true}
					components={components}
					allowCreateWhileLoading
					isLoading={optionsLoading}
					isClearable={props.isClearable}
					formatCreateLabel={renderCreateLabel}
					getNewOptionData={createSuggestion}
					loadOptions={findMatches.bind(this)}
					getOptionLabel={renderSuggestion}
					getOptionValue={renderValue}
					createOptionPosition="first"
					placeholder={props.placeholder || ""}
					value={props.value}
					onChange={onChange}
					className={`select-input${props.invalid ? " select-input-invalid" : ""}`}
					classNamePrefix="single-select-input"
					tabSelectsValue={false}
					noOptionsMessage={addManualAddress}
					menuPosition='fixed'
					menuPortalTarget={document.body} 
				/>
			{props.helperText && <InputHelperText invalid={props.invalid} valid={props.valid}>{props.helperText}</InputHelperText>}
		</div>
	);
}

export default withI18n(withRemote(AddyLocationField));
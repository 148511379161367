import React, { Component } from "react";
import moment from "moment";
import {dateRangeIsValidAndNonNull} from "./ValidationUtils";
import { isNullOrUndefinedOrNaN } from "./commonFunctions";
// import { DATE_FORMAT } from "../DateField/DateField";
import download from "@threeskye/core-components/dist/components/Functions/download";
import { format, subYears } from 'date-fns'

const DATE_FORMAT = "yyyy-MM-dd"

export const TRANSACTION_TYPES = { BUY: "Buy", SELL: "Sell" };
const blankState = { transactions: null, isLoadingTransactions: false, transactionsConnectionError: false, transactionBalancesConnectionError: false, currencies: [], assets: [] };
export const DEFAULT_TRANSACTION_FILTERS = {
	fromDate: moment()
		.subtract(6, "months")
		.format(DATE_FORMAT),
	toDate: moment().format(DATE_FORMAT),
	selectedCurrency: null,
	selectedAsset: null,
	units: "",
	price: "",
	totalCost: "",
	type: "",
	search: "",
};

export const getTickerOfATransaction = (transaction) => {
	if (transaction) {
		const { incomeTransaction, assetTransaction, nativeCurrency } = transaction;
		return (incomeTransaction && incomeTransaction.ticker) || (assetTransaction && assetTransaction.ticker) || nativeCurrency;
	}
	return null;
}


export const TRANSACTIONS_FIELDS = {
	ticker: "TICKER",
	title: "TITLE",
	typeOrActivity: "TYPE_OR_ACTIVITY",
	status: "STATUS",
	date: "DATE",
	total: "TOTAL",
	balance: "BALANCE",
	particulars: "PARTICULARS",
	assetName: "NAME",
	unitQty: "UNIT_QUANTITY",
	unitCost: "UNIT_COST",
	currency: "CURRENCY"
	// creditOrDebit: "CREDIT_OR_DEBIT",
};

 const withTransactions = (WrappedComponent) => {
	return class extends Component {
		state = { ...blankState };

		constructor(props) {
			super(props);
			this.updateTransactions = this.updateTransactions.bind(this);
			this.exportTransactions = this.exportTransactions.bind(this);
			this.setGenerateCSVFunc = this.setGenerateCSVFunc.bind(this);
		}

		updateTransactions({ fromDate, toDate, selectedCurrency, units, price, totalCost, type, narrativeSearch, selectedAsset, account }) {
			this.setState({ isLoadingTransactions: true });
			


			let parsedFromDate = fromDate ? fromDate : format(subYears(new Date(), 1), "yyyy-MM-dd")	
			let parsedToDate = toDate ? toDate : format(new Date(), "yyyy-MM-dd")
				
			if (moment.isMoment(fromDate)) parsedFromDate = fromDate.format("yyyy-MM-DD")
			if (moment.isMoment(toDate)) parsedToDate = toDate.format("yyyy-MM-DD")			
			
			// if (dateRangeIsValidAndNonNull(fromDate, toDate)) {
				// const portfolioDate = moment(fromDate)
				// 	.subtract(1, "day")
				// 	.format(DATE_FORMAT);
				Promise.all([
					this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/transactions?fromDate=${parsedFromDate}&toDate=${parsedToDate}`),
					// this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/portfolio/${portfolioDate}`),
					// this.props.storage.getOrFetch(`/modules/crm/classification-scheme/${account.classificationScheme.id}/model-data`),
				]).then(([transactionsResponse/*, portfolioResp, scheme*/]) => {
					let transactions = null;
					const isLoadingTransactions = false;
					const transactionsConnectionError = !transactionsResponse || transactionsResponse.connectionError;
					// const transactionBalancesConnectionError = !portfolioResp || portfolioResp.connectionError || !portfolioResp.data || !scheme || !portfolioResp.data.classes || !scheme.classes;
					let currencies = [];
					let assets = [];
					if (!transactionsConnectionError) {
						if (transactionsResponse.data && transactionsResponse.data.length > 0) {
							// default comes newest to oldest
							transactions = transactionsResponse.data.slice();
						}

						if (transactions) {
							assets = transactions
								.filter(({ assetTransaction }) => !!assetTransaction)
								.reduce((assets, transaction) => {
									const ticker = getTickerOfATransaction(transaction);
									if (!assets.find((tAndN) => tAndN.ticker === ticker && tAndN.asset === transaction.asset)) {
										assets.push({ asset: transaction.asset, ticker });
									}
									return assets;
								}, [])
								.map(({ asset, ticker }) => ({ value: { asset, ticker }, label: asset.includes(`(${ticker})`) ? asset : `${asset} (${ticker})` }));

							currencies = [...transactions.reduce((currencies, { nativeCurrency }) => currencies.add(nativeCurrency), new Set())].map((c) => ({ value: c, label: c }));

							if (narrativeSearch && narrativeSearch.toLowerCase) {
								transactions = transactions.filter(({ narrative }) => narrative && narrative.toLowerCase && narrative.toLowerCase().includes(narrativeSearch.toLowerCase()));
							}
							if (selectedAsset) {
								transactions = transactions.filter((transaction) => getTickerOfATransaction(transaction) === selectedAsset.ticker && transaction.asset === selectedAsset.asset);
							}
							if (units && !Number.isNaN(units)) {
								transactions = transactions.filter(
									({ assetTransaction }) =>
										assetTransaction && !isNullOrUndefinedOrNaN(assetTransaction.units) && assetTransaction.units.toFixed && Number(assetTransaction.units.toFixed(2)) === Number(units.replace(/,/g, ""))
								);
							}
							if (price && !Number.isNaN(price)) {
								transactions = transactions.filter(
									({ assetTransaction }) =>
										assetTransaction && !isNullOrUndefinedOrNaN(assetTransaction.price) && assetTransaction.price.toFixed && Number(assetTransaction.price.toFixed(2)) === Number(price.replace(/,/g, ""))
								);
							}
							if (totalCost && !Number.isNaN(totalCost)) {
								transactions = transactions.filter(
									({ nativeCurrencyValue }) => !isNullOrUndefinedOrNaN(nativeCurrencyValue) && nativeCurrencyValue.toFixed && Number(nativeCurrencyValue.toFixed(2)) === Number(totalCost.replace(/,/g, ""))
								);
							}
							if (type) {
								if (type === TRANSACTION_TYPES.SELL) {
									transactions = transactions.filter(
										({ assetTransaction }) => assetTransaction && assetTransaction.assetType === "SELL"
									);
								} else if (type === TRANSACTION_TYPES.BUY) {
									transactions = transactions.filter(
										({ assetTransaction }) => assetTransaction && assetTransaction.assetType === "BUY"
									);
								}
							}
							if (selectedCurrency) {
								// .filter changes ordering so verbose for each.
								const filteredCurrencyTransactions = [];
								transactions.forEach(transaction => {
									if (transaction && transaction.nativeCurrency === selectedCurrency) {
										filteredCurrencyTransactions.push(transaction);
									}
								});
								transactions = filteredCurrencyTransactions;
							}
							// if (!transactionBalancesConnectionError) {
							// 	const cashFund = portfolioResp.data.classes.find((portfolioClass) => (scheme.classes.find((schemeClass) => portfolioClass.classId === schemeClass.id) || {}).cash);
							// 	let balances = {};
							// 	if (cashFund && cashFund.holdings && cashFund.holdings.length > 0) {
							// 		balances = currencies.reduce(
							// 			(balances, { value }) => ({ ...balances, [value]: (cashFund.holdings.find(({ nativeCurrency }) => nativeCurrency === value) || {}).nativeValue }),
							// 			{}
							// 		);
							// 	}
							// 	transactions.forEach((transaction) => {
							// 		if (!isNullOrUndefinedOrNaN(balances[transaction.nativeCurrency])) {
							// 			balances[transaction.nativeCurrency] += transaction.nativeCurrencyValue;
							// 			transaction.nativeCurrencyBalance = balances[transaction.nativeCurrency];
							// 		} else {
							// 			transaction.nativeCurrencyBalance = null;
							// 		}
							// 	});
							// }

							//ensure it comes as newest to oldest
							transactions = transactions.slice().reverse();
						}

						this.setState({ transactions, isLoadingTransactions, transactionsConnectionError, /*transactionBalancesConnectionError,*/ currencies, assets });
					}
				});
			// } else {
			// 	this.setState({ ...blankState });
			// }
		}

		exportTransactions() {
			if (this.generateCSV) {
				const { account } = this.props;
				const generatedCSV = this.generateCSV();
				const encoded = generatedCSV.replace(/[\u00A0-\u2666]/g, function(c) {
					return '&#' + c.charCodeAt(0) + ';';
				});
				const base64 = btoa(encoded);
				download({ base64, filename: `${account.name} Transaction History`, extension: "csv" });
			}
		}

		setGenerateCSVFunc(func) {
			this.generateCSV = func;
		}

		render() {
			const { transactions, isLoadingTransactions, transactionsConnectionError, /*transactionBalancesConnectionError,*/ currencies, assets } = this.state;
			const { updateTransactions, exportTransactions, setGenerateCSVFunc } = this;
			return (
				<WrappedComponent
					{...this.props}
					{...{
						transactions,
						isLoadingTransactions,
						transactionsConnectionError,
						// transactionBalancesConnectionError,
						currencies,
						assets,
						updateTransactions,
						exportTransactions,
						setGenerateCSVFunc,
					}}
				/>
			);
		}
	};
}
export default withTransactions;
import React, { Component } from "react";
import withReactKey from "@threeskye/core-components/dist/components/withReactKey";
import { TRANSACTION_TYPES } from "@threeskye/core-components";
import Button from "../buttons/Button";
import { Grid } from "@material-ui/core";
import { Search } from "react-feather";
import FilterInput from "../inputs/FilterInput";
import SearchInput from "../inputs/SearchInput";
import RadioButtonGroup from "../inputs/RadioButtonGroup";
import SeparateDateRangeFilter from "./SeparateDateRangeFilter";
import { format, subYears } from "date-fns";
import moment from 'moment'


const emptyState = {
	fromDate: "",
	toDate: "",
	selectedCurrency: null,
	selectedAsset: null,
	units: "",
	price: "",
	totalCost: "",
	type: "",
	narrativeSearch: "",
};

const TransactionFiltersSearchInput = (props) => (
	<SearchInput
		components={{ DropdownIndicator: null, IndicatorSeparator: () => null }}
		className="token-field"
		classNamePrefix="token-field"
		{...props}
	/>
);

class TransactionFilters extends Component {
	state = {
		...emptyState,
	};

	constructor(props) {
		super(props);
		this.updateTransactions = this.updateTransactions.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	componentDidMount() {
		this.updateTransactions();
	}

	componentDidUpdate(prevProps) {
		if (!this.props.isLoadingAccount && (prevProps.account && prevProps.account.id) !== (this.props.account && this.props.account.id)) {
			this.updateTransactions();
		}
	}

	updateTransactions() {
		const { fromDate, toDate, selectedCurrency, units, price, totalCost, type, narrativeSearch, selectedAsset } = this.state;
		const { account } = this.props;
		if (account) {
			this.props.updateTransactions({ fromDate, toDate, selectedCurrency, units, price, totalCost, type, narrativeSearch, selectedAsset, account });
		}
	}

	clearFilters() {
		this.setState({ ...emptyState }, () => {
			this.updateTransactions();
			this.props.changeKey();
		});
	}

	handleKeyPress(event) {
		if (event.key === "Enter") {
			this.updateTransactions();
		}
	}

	render() {
		const { currencies, assets } = this.props;
		const { fromDate, toDate, units, price, totalCost, narrativeSearch, selectedAsset, selectedCurrency } = this.state;
		return (
			<Grid container spacing={3} className="transition-fade-up-slow" onKeyPress={this.handleKeyPress}>
				<Grid item xs={12} className="transition-fade-up-slow">
					<FilterInput
						icon={<Search />}
						placeholder="Search"
						label="Search"
						name="narrativeSearch"
						value={narrativeSearch}
						onChange={(narrativeSearch) => this.setState({ narrativeSearch })}
						onBlur={this.updateTransactions}
						controlledByProps
					/>
				</Grid>
				<Grid item xs={12} sm={6} className="transition-default">
					<TransactionFiltersSearchInput
						options={currencies}
						label="Currencies"
						placeholder="Search Currencies"
						value={selectedCurrency ? { value: selectedCurrency, label: selectedCurrency } : null}
						onChange={(selection) => this.setState({ selectedCurrency: selection ? selection.value : null }, this.updateTransactions)}
						clearValue={() => this.setState({selectedCurrency: null})}
					/>
				</Grid>
				<Grid item xs={12} sm={6} className="transition-default">
					<TransactionFiltersSearchInput
						options={assets}
						label="Assets"
						placeholder="Search Assets"
						value={selectedAsset ? assets.find(({ value }) => value.ticker === selectedAsset.ticker && value.asset === selectedAsset.asset) : null}
						onChange={(selection) => this.setState({ selectedAsset: selection ? selection.value : null }, this.updateTransactions)}
						clearValue={() => this.setState({selectedAsset: null})}

					/>
				</Grid>
				<SeparateDateRangeFilter
					fromDate={{
						valid: fromDate,
						value: fromDate,
						onChange: (e) => this.setState({ fromDate: e }, this.updateTransactions),
					}}
					toDate={{
						valid: toDate,
						value: toDate,
						onChange: (e) => this.setState({ toDate: e }, this.updateTransactions),
					}}
				/>
				<Grid item xs={12} sm={4} className="transition-default">
					<FilterInput
						placeholder="Quantity"
						label="Quantity"
						name="units"
						value={units}
						onChange={(units) => this.setState({ units })}
						onBlur={this.updateTransactions}
						controlledByProps
						type="number"
					/>
				</Grid>
				<Grid item xs={12} sm={4} className="transition-default">
					<FilterInput
						placeholder="Unit Cost"
						label="Unit Cost"
						name="price"
						value={price}
						onChange={(price) => this.setState({ price })}
						onBlur={this.updateTransactions}
						controlledByProps
						type="number"
					/>
				</Grid>
				<Grid item xs={12} sm={4} className="transition-default">
					<FilterInput
						placeholder="Total Cost"
						label="Total Cost"
						name="totalCost"
						value={totalCost}
						onChange={(totalCost) => this.setState({ totalCost })}
						onBlur={this.updateTransactions}
						controlledByProps
						type="number"
					/>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacin={3} justifyContent="space-between" alignItems="center">
						<Grid  item xs={12} sm="auto">
							<RadioButtonGroup
								label="Type"
								options={[
									{ value: TRANSACTION_TYPES.BUY, label: TRANSACTION_TYPES.BUY },
									{ value: TRANSACTION_TYPES.SELL, label: TRANSACTION_TYPES.SELL },
								]}
								id="action-type"
								onChange={(e) => this.setState({ type: e.target.value }, this.updateTransactions)}
								value={this.state.type}
								clear={() => this.setState({ type: null }, this.updateTransactions)}
								toggleable
							/>
						</Grid>
						<Grid item xs="auto">
							<Button onClick={this.clearFilters}>Clear Filters</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withReactKey(TransactionFilters);

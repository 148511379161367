import { Grid } from '@material-ui/core';
import React from 'react';
import Divider from './Divider';

const GridItemDivider = ({spacing}) => {
	return (
		<Grid className="transition-fade-up-slow" item container spacing={spacing}>
			<Grid item xs={12}>
				<Divider />
			</Grid>
		</Grid>
	);
}

export default GridItemDivider;
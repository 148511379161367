import React from 'react';
import { Menu } from 'react-feather';
import NotificationBell from '../buttons/NotificationBell';
import FlexWrapper from '../layout/FlexWrapper';
import "./MobileTopBar.scss";

const MobileTopBar = ({handleMenuClick, handleNotificationClick}) => {
	return (
		<FlexWrapper className="mobile-top-bar display-mobile-only" justify="space-between" align="center">
			<Menu className="menu-icon" onClick={handleMenuClick} />
			<NotificationBell onClick={handleNotificationClick} />
		</FlexWrapper>
   );
}

export default MobileTopBar;
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../inputs/TextInput';

const AccInfoOccupationSection = ({ clientDetails }) => {

	const [occupation, setOccupation] = useState("");
	const [prevOccupation, setPrevOccupation] = useState("");
	const [employer, setEmployer] = useState("");
	const [industry, setIndustry] = useState("");

	useEffect(() => {
		if (clientDetails) {
			const unstructuredData = clientDetails.unstructuredData ? JSON.parse(clientDetails.unstructuredData) : null
			setOccupation(unstructuredData && unstructuredData.Occupation ? unstructuredData.Occupation : "")
			setPrevOccupation(unstructuredData && unstructuredData.PreviousOccupation ? unstructuredData.PreviousOccupation : "")
			setEmployer(unstructuredData && unstructuredData.Employer ? unstructuredData.Employer : "")
			console.log(unstructuredData.Industry, typeof unstructuredData.Industry)
			setIndustry(unstructuredData && unstructuredData.Industry ? unstructuredData.Industry : "")
		}
	}, [clientDetails])

	return (
		<Grid item container spacing={3}>
			<Grid item xs={12}>
				<h1>Occupation</h1>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="Occupation" placeholder="Occupation" value={occupation} onChange={(e) => setOccupation(e.target.value)} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="Previous Occupation" placeholder="Previous Occupation" value={prevOccupation} onChange={(e) => setPrevOccupation(e.target.value)} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="Employer" placeholder="Employer" value={employer} onChange={(e) => setEmployer(e.target.value)} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="Industry" placeholder="Industry" value={industry} onChange={(e) => setIndustry(e.target.value)} />
			</Grid>
		</Grid>
	);
}

export default AccInfoOccupationSection;
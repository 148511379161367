import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextInput from "../../../inputs/TextInput";
import RadioButtonGroup from '../../../inputs/RadioButtonGroup';
import CustomAddressModal from '../../../layout/CustomAddressModal';
import DateOfBirthInput from '../../../inputs/DateOfBirthInput';
import AddyLocationInput from '../../../inputs/Addy/AddyLocationInput';
// import PhoneNumberDetailField from '../../../inputs/PhoneNumberDetailField';
import { validate, handleOnboardingInputChange, checkOnboardingInputValid, checkOnboardingInputInvalid, display } from '../../../utils/ValidationUtils'
import { months } from '../../../../resources/DummyData';

const OnboardingPersonalDetails = ({ handlePrescribedPerson, clientSetup, setPersonal, componentValid, setPersonalValid, setPrescribedValid }) => {
	const [dateOfBirth, setDateOfBirth] = useState({ touched: false, valid: false });
	const [dateOfBirthDay, setDateOfBirthDay] = useState("")
	const [dateOfBirthMonth, setDateOfBirthMonth] = useState("")
	const [dateOfBirthYear, setDateOfBirthYear] = useState("")
	const [mobile, setMobile] = useState({ value: "", touched: false, valid: false });
	const [postalAddress, setPostalAddress] = useState({ value: null, touched: false, valid: false });
	const [physicalAddress, setPhysicalAddress] = useState({ value: null, touched: false, valid: false });
	const [prescribedPerson, setPrescribedPerson] = useState(false);
	const [open, setOpen] = useState(false);
	const [modalType, setModalType] = useState("")
	const handleClose = () => { setOpen(false); setModalType("") };
	const handleOpen = (type) => { setOpen(true); setModalType(type) }

	useEffect(() => {
		if (clientSetup && clientSetup.uuid) {
			const DOBSplit = clientSetup && clientSetup.dateOfBirth ? clientSetup.dateOfBirth.split("-") : ["", "", ""]
			let monthToSet = months.find(month => month.value === parseInt(DOBSplit[1]))
			setDateOfBirthDay(DOBSplit && DOBSplit[2])
			setDateOfBirthMonth(DOBSplit && monthToSet)
			setDateOfBirthYear(DOBSplit && DOBSplit[0])
			setMobile({ value: clientSetup.mobile === null ? "" : clientSetup.mobile, touched: false, valid: "" });
			setPhysicalAddress(clientSetup.address ? { value: { fullAddress: clientSetup.address.fullAddress, text: clientSetup.address.fullAddress, addressLine1: clientSetup.address.addressLine1, addressLine2: clientSetup.address.addressLine2, suburb: clientSetup.address.suburb, city: clientSetup.address.city, postCode: clientSetup.address.postCode, }, touched: false, valid: "" } : null)
			setPostalAddress(clientSetup.postal ? { value: { fullAddress: clientSetup.postal.fullAddress, text: clientSetup.postal.fullAddress, addressLine1: clientSetup.postal.addressLine1, addressLine2: clientSetup.postal.addressLine2, suburb: clientSetup.postal.suburb, city: clientSetup.postal.city, postCode: clientSetup.postal.postCode }, touched: false, valid: "" } : null)
			setDateOfBirth({ touched: false, valid: false })
			setPrescribedPerson(clientSetup.prescribed !== null ? clientSetup.prescribed === false ? "No" : "Yes" : "")
		}
	}, [clientSetup])

	useEffect(() => {
		if (clientSetup && clientSetup.uuid) {
			const isComponentValid = ![
				mobile && mobile.value && validate.mobile({idd: `+${mobile.value.idd}`, number: mobile.value.number}),
				validate.dateOfBirth(dateOfBirthDay, dateOfBirthMonth && dateOfBirthMonth.value ? dateOfBirthMonth.value : "", dateOfBirthYear) === "",
				postalAddress && validate.address(postalAddress.value) === "",
				physicalAddress && validate.address(physicalAddress.value) === "",
			].includes(false)
			const prescribedPersonValid = prescribedPerson === "No"
			setPersonalValid(isComponentValid)
			setPrescribedValid(prescribedPersonValid)
			setPersonal({
				dateOfBirth: (dateOfBirthDay && dateOfBirthDay !== "") && (dateOfBirthMonth && dateOfBirthMonth !== "") && (dateOfBirthYear && dateOfBirthYear !== "" && dateOfBirthYear.length === 4) ? display.dateForDTO(dateOfBirthDay, dateOfBirthMonth.value, dateOfBirthYear) : null,
				mobile: mobile.value ? mobile.value : null,
				postal: postalAddress && postalAddress.value ? postalAddress.value : null,
				address: physicalAddress && physicalAddress.value ? physicalAddress.value : null,
				prescribed: prescribedPerson === "No" ? false : prescribedPerson === "" ? "" : true
			})
		}
	}, [dateOfBirth, dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear, mobile, postalAddress, prescribedPerson, clientSetup, physicalAddress, setPersonal, setPersonalValid, setPrescribedValid])

	useEffect(() => {
		if ((dateOfBirthDay && dateOfBirthDay !== null && dateOfBirthMonth && dateOfBirthMonth !== null && dateOfBirthYear && dateOfBirthYear !== null && dateOfBirthYear.length >= 2) || dateOfBirth.touched) {
			setDateOfBirth({ touched: true, valid: validate.dateOfBirth(dateOfBirthDay, dateOfBirthMonth.value, dateOfBirthYear) })
		}
	}, [dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear, dateOfBirth.touched])


	return (
		<div className="mt-l mb-l">
			{/* General Detail Fields Here */}
			<Grid className="transition-fade-up-slow" container spacing={3}>
				{/* Custom address modal */}
				<CustomAddressModal
					open={open}
					addressType={modalType}
					handleClose={handleClose}
					setPostalAddress={(value) => handleOnboardingInputChange(value, null, setPostalAddress)}
					setPhysicalAddress={(value) => handleOnboardingInputChange(value, null, setPhysicalAddress)}
				/>
				<Grid item xs={12} sm={6}>
					<DateOfBirthInput
						required
						valid={checkOnboardingInputValid(dateOfBirth)}
						invalid={checkOnboardingInputInvalid(dateOfBirth)}
						day={{ value: dateOfBirthDay, onChange: (e) => setDateOfBirthDay(e.target.value) }}
						month={{ value: dateOfBirthMonth, onChange: (e) => setDateOfBirthMonth(e) }}
						year={{ value: dateOfBirthYear, onChange: (e) => setDateOfBirthYear(e.target.value) }}
						helperText={dateOfBirth.valid && dateOfBirth.valid}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					{/* <PhoneNumberDetailField
						onChange={(value) => {if (value.idd && value.mobile){handleOnboardingInputChange(value, validate.mobile, setMobile)}}}
						value={mobile.value}
						label="Mobile"
						valid={mobile.valid === true || mobile.valid === ""}
					/> */}
					<TextInput
						required
						label="Mobile*"
						placeholder="Mobile"
						value={mobile.value.number}
						valid={checkOnboardingInputValid(mobile)}
						invalid={checkOnboardingInputInvalid(mobile)}
						helperText={mobile.valid && mobile.valid}
						onChange={(e) => handleOnboardingInputChange({idd: "+64", number: e.target.value}, validate.mobile, setMobile)}
						inputPrefix="+64"
						wrapped
						type="number"
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<AddyLocationInput
						value={physicalAddress && physicalAddress.value ? physicalAddress.value : null }
						name="Physical Address"
						label="Physical Address"
						placeholder="Physical Address"
						onUpdate={(address) => handleOnboardingInputChange({addressLine1: null, addressLine2: null, suburb: null, city: null, postcode: null, fullAddress: address.fullAddress, text: address.fullAddress, manualEntry: "VERIFIED"}, null, setPhysicalAddress)}
						isClearable
						manualOverrideEnabled={true}
						onManualOverride={() => handleOpen("physical")}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<AddyLocationInput
						value={postalAddress && postalAddress.value ? postalAddress.value : null}
						name="Postal Address"
						label="Postal Address"
						placeholder="Postal Address"
						onUpdate={(address) => handleOnboardingInputChange({addressLine1: null, addressLine2: null, suburb: null, city: null, postcode: null, fullAddress: address.fullAddress, text: address.fullAddress, manualEntry: "VERIFIED"}, null, setPostalAddress)}
						isClearable
						manualOverrideEnabled={true}
						onManualOverride={() => handleOpen("postal")}
					/>
				</Grid>
				<Grid item xs={12}>
					<RadioButtonGroup
						label="Prescribed Person"
						options={[
							{ value: "Yes", label: "Yes" },
							{ value: "No", label: "No" },
						]}
						id="prescribed-person"
						onChange={(e) => {
							setPrescribedPerson(e.target.value);
							handlePrescribedPerson(e.target.value);
						}}
						value={prescribedPerson}
					/>
					{/* If prescribed person is true */}
					{prescribedPerson === "Yes" && (
						<p className="transition-fade-up-slow colour-text-danger mt-s mb-none">Cannot be a prescribed person.</p>
					)}
				</Grid>
			</Grid>
		</div>
	);
}
export default OnboardingPersonalDetails;
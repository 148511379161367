import React from 'react';
import Divider from '../layout/Divider';
import FlexWrapper from '../layout/FlexWrapper';

const SplitValueField = ({values}) => {
	return (
		<FlexWrapper gap="m">
			{values &&
				values.map((value, idx) => (
					<React.Fragment key={idx}>
						<h1 className={`bold${value.className ? " " + value.className : ""}`}>{value.label || value.value}</h1>
						{idx === values.length - 1 ? "" : <Divider height={24} vertical />}
					</React.Fragment>
				))}
		</FlexWrapper>
	);
}
export default SplitValueField;
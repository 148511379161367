import React from 'react';
import {Checkbox as MuiCheckbox} from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import "./Checkbox.scss";

const Checkbox = ({label, checked, onChange, id}) => {
	return (
		<FormControlLabel className="checkbox-wrapper" control={<MuiCheckbox checked={checked} onChange={onChange} id={id} />} label={label} />
   );
}

export default Checkbox;
import { Grid } from '@material-ui/core';
import React from 'react';
import DateFieldInput from '../inputs/DateFieldInput';
import DatePicker from '../inputs/DatePicker';

const SeparateDateRangeFilter = ({ fromDate, toDate, xs, sm }) => {
	return (
		<>
			<Grid item xs={12} sm={sm ? sm : 6}>
				{/* <DatePicker
					label="From Date"
					placeholder="DD/MM/YYYY"
					valid={fromDate.valid}
					date={fromDate.value}
					onDateChange={fromDate.onChange}
					displayFormat="DD/MM/yyyy"
					/> */}
				<DateFieldInput
					label="From Date"
					date={fromDate.value}
					onChange={fromDate.onChange}
				/>
			</Grid>
			<Grid item xs={12} sm={sm ? sm : 6}>
				{/* <DatePicker
					label="To Date"
					placeholder="DD/MM/YYYY"
					valid={toDate.valid}
					date={toDate.value}
					onDateChange={toDate.onChange}
					displayFormat="DD/MM/yyyy"
				/> */}
				<DateFieldInput
					label="To Date"
					date={toDate.value}
					onChange={toDate.onChange}
				/>
			</Grid>
		</>
	);
}

export default SeparateDateRangeFilter;
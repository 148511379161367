import React, { useState, useEffect } from "react";
import NumberFormat from "../utils/NumberFormat";
import TableHeader from "./TableHeader";
import "./SimpleTable.scss";
import "./TransactionHistoryTable.scss";
import TableData from "./TableData";
import Token from "../misc/Token";
import LoadingIcon from "../misc/LoadingIcon";
import EmptyStateMessage from "../misc/EmptyStateMessage";
import FlexWrapper from "../layout/FlexWrapper";
import withSortedCashAssets from '@threeskye/core-components/dist/components/Portfolio/CashFundTable/withSortedCashAssets'
import { format } from 'date-fns'
import { isNullOrUndefined } from "../utils/commonFunctions";
import "./PortfolioCashTable.scss";
import { getTotalPendingTransactionValue } from "@threeskye/core-components/dist/components/Portfolio/CashFundTable/withSortedCashAssets";


export const fields = {
	asset: "ASSET",
	name: "NAME",
	pendingTransactions: "PENDING_TRANSACTIONS",
	cashHeld: "CASH_HELD",
	total: "TOTAL",
	totalNZD: "TOTAL_NZD",
	pctOfHoldings: "PCT_OF_HOLDINGS",
};

const headers = {
	[fields.asset]: "Asset",
	[fields.name]: "",
	[fields.pendingTransactions]: "Pending Transactions",
	[fields.cashHeld]: "Cash Held",
	[fields.total]: "Total",
	[fields.totalNZD]: "Total NZD",
	[fields.pctOfHoldings]: "% of Holdings",
};

const display = {
	[fields.asset]: ({ tickerCode }) => tickerCode,
	[fields.name]: ({ name }) => name,
	[fields.pendingTransactions]: ({ nativeCurrency }, pendingCashError, pendingCashTransactions, pendingCashLoading) => <NumberFormat places={2} value={getTotalPendingTransactionValue(!pendingCashError && !pendingCashLoading && pendingCashTransactions, nativeCurrency) || 0} />,
	[fields.cashHeld]: ({ nativeValue }, totalPendingTransactionValue) => <NumberFormat places={2} value={nativeValue - (totalPendingTransactionValue || 0)} />,
	[fields.total]: ({ nativeValue, nativeCurrency }) => <NumberFormat places={2} value={nativeValue} suffix={nativeCurrency} />,
	[fields.totalNZD]: ({ value }) => <NumberFormat places={2} value={value} />,
	[fields.pctOfHoldings]: ({ percentPortfolio }) => <NumberFormat value={percentPortfolio} places={2} suffix="%" />,
};

const PortfolioCashTable = ({ sortedHoldingsLoading, cashAssets, pendingCashLoading, pendingCashError, pendingCashTransactions, connectionError, loading }) => {

	const [sortedCashAssets, setSortedCashAssets] = useState(null)
	const [rowsExpanded, setRowsExpanded] = useState({})

	useEffect(() => {
		setSortedCashAssets(cashAssets)
	}, [cashAssets])

	const toggleExpandFund = (expandIdentifier) => {
		setRowsExpanded({ ...rowsExpanded, [expandIdentifier]: !rowsExpanded[expandIdentifier] })
	}

	if (sortedHoldingsLoading) {
		return <FlexWrapper fullWidth justify="center"><LoadingIcon colour="brand" /></FlexWrapper>;
	} else if (connectionError) {
		return <EmptyStateMessage message="Failed to load data for this account." />;
	} else {
		return (
			<div className="order-history-list" style={{ marginRight: "auto", marginLeft: "auto" }}>
				<>
					<div style={{ width: "100%", overflow: "auto" }}>
						<table className="simple-table transaction-table" style={{ width: "100%" }}>
							<tbody>
								<tr >
									<TableHeader width={125} value={headers[fields.asset]} />
									<TableHeader width={150} value={headers[fields.name]} alignment="right" />
									<TableHeader width={80} value={headers[fields.pendingTransactions]} alignment="right" />
									<TableHeader value={headers[fields.cashHeld]} alignment="right" />
									<TableHeader value={headers[fields.total]} alignment="right" />
									<TableHeader value={headers[fields.totalNZD]} alignment="right" />
									<TableHeader value={headers[fields.pctOfHoldings]} alignment="right" />
								</tr>
							</tbody>
							<tbody>
								{sortedCashAssets &&
									sortedCashAssets.length > 0 &&
									sortedCashAssets.map((cashAsset, index) => {
										const { tickerCode, nativeCurrency, name, cash } = cashAsset
										const expandIdentifier = tickerCode + name
										const totalPendingTransactionValue = getTotalPendingTransactionValue(!pendingCashError && !pendingCashLoading && pendingCashTransactions, nativeCurrency)

										return (
											<>
												<tr style={{ cursor: "pointer" }} key={index} onClick={() => toggleExpandFund(expandIdentifier)} >
													<TableData><Token className="mr-s">{display[fields.asset](cashAsset) || "-"}</Token></TableData>
													<TableData className={`td-cash-balance text-align-center`}>{display[fields.name](cashAsset)}</TableData>
													<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.pendingTransactions](cashAsset, pendingCashError, pendingCashTransactions, pendingCashLoading) || "-"}</TableData>
													<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.cashHeld](cashAsset, totalPendingTransactionValue) || "-"}</TableData>
													<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.total](cashAsset) || "-"}</TableData>
													<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.totalNZD](cashAsset) || "-"}</TableData>
													<TableData alignment="right" className={`td-cash-balance text-align-right}`}>{display[fields.pctOfHoldings](cashAsset) || "-"}</TableData>
												</tr>
												{/* <ExpandedRow colSpan="6" showRow={rowsExpanded[expandIdentifier]}> */}
												{rowsExpanded[expandIdentifier] && (
													<tr className="expanded-row">
														<td colSpan="7">

															{pendingCashLoading || loading
																? (
																	<div className="p-3">
																		<LoadingIcon size={24} colour="brand" />
																	</div>
																)
																: !pendingCashError && pendingCashTransactions && pendingCashTransactions[nativeCurrency] && cash
																	? (<div className="pending-transactions-container">
																		<p className="pending-transaction-header">Pending Transactions</p>
																		<table className="expanded-row-table">
																			<thead>
																				<tr>
																					<th>Date</th>
																					<th>Asset</th>
																					<th>Type/Activity</th>
																					<th className="text-right">Quantity</th>
																					<th className="text-right">Cost</th>
																				</tr>
																			</thead>
																			<tbody>
																				{pendingCashTransactions[nativeCurrency].map(({ date, asset, ticker, narrative, quantity, value }, key) => (
																					<tr key={key}>
																						<td>{date && format(new Date(date), "dd/MM/yy")}</td>
																						<td className="expanded-table-ticker-col">
																							{ticker
																								? (
																									<>
																										<Token>{ticker}</Token>{" "}
																									</>
																								)
																								: (
																									""
																								)}
																							<span style={{ marginLeft: 4 }}>{asset}</span>
																						</td>
																						<td>{narrative || "-"}</td>
																						<td className="text-right">{isNullOrUndefined(quantity) ? "-" : <NumberFormat places={2} value={quantity} />}</td>
																						<td className="text-right">{isNullOrUndefined(value) ? "-" : <NumberFormat places={2} value={value} />}</td>
																					</tr>
																				))}
																			</tbody>
																		</table>
																	</div>
																	) : pendingCashError ? (
																		pendingCashError
																	) : (
																		<div className="no-transactions"><span> No pending cash transactions to display</span></div>
																	)}
														</td>
													</tr>
												)}
											</>
										)
									})}
							</tbody>
						</table>
					</div>
				</>
				{/* )} */}
			</div>
		);
	}
}

export default withSortedCashAssets(PortfolioCashTable);

import { CircularProgress } from '@material-ui/core';
import React from 'react';
import "./LoadingIcon.scss";

const LoadingIcon = ({ size, colour }) => {
	return (
		<CircularProgress size={size} className={`loading-icon${colour ? " colour-" + colour : ""}`} color="inherit" />
   );
}

export default LoadingIcon;
import React from 'react';
import SingleSelectInput from './SingleSelectInput';
import TextFieldLabel from './TextFieldLabel';
import { months } from '../../resources/DummyData';
import TextInput from './TextInput';
import ValidationIcons from './ValidationIcons';
import InputHelperText from './InputHelperText';
import { Grid } from '@material-ui/core';
import './DateOfBirthInput.scss'

const DateOfBirthInput = ({ month, day, year, required, helperText, infoIcon, invalid, valid, disabled }) => {
	// const valid = month.value && day.value && year.value;
	const currentYear = new Date().getFullYear()
	return (
		<div style={{ width: "100%", position: "relative" }}>
			<TextFieldLabel>
				Date of Birth
				<ValidationIcons required={required} valid={valid} infoIcon={infoIcon} />
			</TextFieldLabel>
			<Grid container spacing={1}>
				<Grid item xs={3}>
					<TextInput
						required={required}
						onChange={day.onChange}
						value={day.value}
						valid={valid}
						invalid={invalid}
						width="30%"
						placeholder="Day"
						hideDropdownIndicator
						type="number"
						min="1"
						max="31"
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={6}>
					<SingleSelectInput
						required={required}
						onChange={month.onChange}
						value={month.value}
						valid={valid}
						invalid={invalid}
						clearValue={month.clearValue}
						blurInputOnSelect
						placeholder="Month"
						options={months}
						hideDropdownIndicator
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextInput
						required={required}
						onChange={year.onChange}
						value={year.value}
						valid={valid}
						invalid={invalid}
						width="40%"
						placeholder="Year"
						hideDropdownIndicator
						type="number"
						min="1900"
						max={currentYear}
						disabled={disabled}
					/>
				</Grid>
			</Grid>
			{helperText && (
				<InputHelperText valid={valid} invalid={invalid}>
					{helperText}
				</InputHelperText>
			)}
		</div>
	);
}

export default DateOfBirthInput;
import React from 'react';
import CheckboxGroup from '../inputs/CheckboxGroup';

const StatusTypeFilterCheck = ({ setStatusOpen, statusOpen, setStatusCompleted, statusCompleted, setStatusCancelled, statusCancelled }) => {
	return (
		<CheckboxGroup
			label="Status"
			checkboxes={[
				{
					label: "Open",
					onChange: setStatusOpen,
					checked: statusOpen
				},
				{
					label: "Completed",
					onChange: setStatusCompleted,
					checked: statusCompleted
				},
				{
					label: "Cancelled",
					onChange: setStatusCancelled,
					checked: statusCancelled
				},
			]}
		/>
	);
}

export default StatusTypeFilterCheck;
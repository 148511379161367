import { Grid } from '@material-ui/core';
import React from 'react';
// import { bankAccounts } from '../../../../../resources/DummyData';
import BankAccountField from '../../../../misc/BankAccountField';
import EmptyStateMessage from '../../../../misc/EmptyStateMessage';

const AccInfoBankAccounts = ({bankAccounts}) => {
	return (
		<Grid item container spacing={3}>
			<Grid item xs={12}>
				<h1>Bank Accounts</h1>
			</Grid>
			<Grid item container spacing={3}>
				{bankAccounts && bankAccounts.length > 0 ? (
					bankAccounts.map((acc, idx) => (
						<Grid key={idx + "-grid-container"} item xs={12}>
							<BankAccountField key={idx} disabled bankAccount={acc} />
						</Grid>
					))
				) : (
					<Grid item xs={12}>
						<EmptyStateMessage noMargin message="No bank accounts available" />
					</Grid>
				)}
			</Grid>
		</Grid>
	);
}

export default AccInfoBankAccounts;
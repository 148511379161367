import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import "./Tooltip.scss";
  
const Tooltip = ({ title, children, placement, ...props }) => {
	const useTooltipStyles = makeStyles(() => ({
		tooltip: {
			margin: 8,
		},
	}));
	const classes = useTooltipStyles();

	return <MuiTooltip {...props} classes={classes} placement={placement ? placement : "top"} enterTouchDelay={0} id="custom-tooltip" title={title}>{children}</MuiTooltip>;
}

export default Tooltip;
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../../buttons/Button';
import TextInput from '../../../inputs/TextInput';
import FlexWrapper from '../../../layout/FlexWrapper';
import Logos from "../../../../resources/images/main-logo.png";
import { ChevronLeft } from 'react-feather';
import { useNavigate } from "react-router-dom";

const ForgotPasswordPage = ({handleClick}) => {
	const [email, setEmail] = useState(false);
	const navigate = useNavigate();

	return (
		<Grid container spacing={3} className="transition-fade-in-left">
			<Grid item xs={12}>
				<FlexWrapper gap="xs" align="center" className="cursor-pointer" onClick={handleClick}>
					<ChevronLeft className="colour-text-brand" onClick={() => navigate(-1)} />
					<p className="m-none font-weight-semi-bold" onClick={() => navigate(-1)}>Back</p>
				</FlexWrapper>
			</Grid>
			<Grid item xs={12}>
				<div className="title colour-text-black">Forgot Password</div>
			</Grid>
			<Grid item xs={12}>
				<p className="m-none colour-text-lm-dark-grey">We’ll send a link to your email address to reset your password.</p>
			</Grid>
			{/* Fields */}
			<Grid item xs={12}>
				<TextInput required valid={email} label="Email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
			</Grid>
			<Grid item xs={12}>
				<Button disabled={!email} fullWidth>
					Send Recovery Link
				</Button>
			</Grid>
			<Grid item xs={12} container justifyContent="center">
				<img src={Logos} style={{ width: 100 }} className="mt-xl" alt="3skye" />
			</Grid>
		</Grid>
	);
}

export default ForgotPasswordPage;
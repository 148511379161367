import React from "react";
import { Calendar } from "react-feather";
import DateField from "@threeskye/core-components/dist/components/DateField/DateField";
import asClassName from "@threeskye/core-components/dist/components/Functions/asClassName";
import TextFieldLabel from "./TextFieldLabel";
import "./DateFieldInput.scss";

const DateFieldInput = ({ label, date, onChange, className, onBlur, onEnter }) => {

	const onKeyPress = (e) => {
		if (e.key === "Enter" && onEnter) {
			this.props.onEnter();
		}
	}

	return (
		<div className={asClassName(["date-field", className])} tabIndex="-1" onBlur={onBlur} onKeyPress={onKeyPress}>
			{label && <TextFieldLabel className="filter-heading">{label}</TextFieldLabel>}
			<DateField IconOverride={<Calendar size={20} />} date={date} onChange={onChange} stateShouldUpdateWhenPropsChange/>
		</div>
	);
}

export default DateFieldInput;

import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Button from '../buttons/Button';
import TextInput from '../inputs/TextInput';
import Modal from './Modal';
import { validate, checkOnboardingInputInvalid, checkOnboardingInputValid, handleOnboardingInputChange } from '../utils/ValidationUtils';

const CustomAddressModal = ({ open, addressType, handleClose, setPhysicalAddress, setPostalAddress }) => {
	const [buildingName, setBuildingName] = useState({ value: "", touched: false, valid: "" });
	const [unitApt, setUnitApt] = useState({ value: "", touched: false, valid: "" });
	const [streetAddress, setStreetAddress] = useState({ value: "", touched: false, valid: false });
	const [suburb, setSuburb] = useState({ value: "", touched: false, valid: false });
	const [townCity, setTownCity] = useState({ value: "", touched: false, valid: false });
	const [postcode, setPostcode] = useState({ value: "", touched: false, valid: false });

	const formValid = validate.value(streetAddress.value) === "" && validate.value(townCity.value) === "";

	const handleConfirm = () => {
		const addBuildingName = buildingName.value && buildingName.value !== "" ? buildingName.value + ", " : ""
		const addUnitApt = unitApt.value && unitApt.value !== "" ?  unitApt.value + "/" : ""
		const addStreetAddress = streetAddress.value && streetAddress.value !== "" ? streetAddress.value + ", " : ""
		const addSuburb = suburb.value && suburb.value !== "" ? suburb.value + ", " : ""
		const addTownCity = townCity.value && townCity.value !== "" ? townCity.value + ", " : ""
		const addPostcode = postcode.value && postcode.value !== "" ? postcode.value : ""
		const address = addBuildingName + addUnitApt + addStreetAddress + addSuburb + addTownCity + addPostcode
		const mapToCRMObj = () => {
			if (buildingName.value){
				return {
					addressLine1: buildingName.value,
					addressLine2: addUnitApt + streetAddress.value,
					suburb: suburb.value,
					city: townCity.value,
					postCode: postcode.value,
					verifiedState: "MANUAL"
				}
			}
			else {
				return {
					addressLine1: addUnitApt + streetAddress.value,
					addressLine2: null,
					suburb: suburb.value,
					city: townCity.value,
					postCode: postcode.value,
					verifiedState: "MANUAL"
				}
			}
			// return {buildingName: buildingName, unitApt: unitApt, streetAddress: streetAddress, suburb: suburb, townCity: townCity, postcode: postcode}
		}
		switch (addressType) {
			case "physical":
				setPhysicalAddress({ fullAddress: address, text: address, ...mapToCRMObj() })
				break;
			case "postal":
				console.log({ fullAddress: address, text: address, ...mapToCRMObj() })
				setPostalAddress({ fullAddress: address, text: address, ...mapToCRMObj() })
				break;
			default:
				break;
		}
		handleClose()
	}

	return (
		<Modal header="Add Custom Address" open={open} handleClose={handleClose}>
			<Grid item xs={12} sm={6}>
				<TextInput
					label="Building Name or Floor"
					placeholder="Building Name or Floor"
					autoFocus
					valid={checkOnboardingInputValid(buildingName)}
					// invalid={checkOnboardingInputInvalid(buildingName)}
					value={buildingName.value}
					onChange={(e) => handleOnboardingInputChange(e.target.value, null, setBuildingName)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput
					label="Unit/Apt Number"
					placeholder="Unit/Apt Number"
					valid={checkOnboardingInputValid(unitApt)}
					// invalid={checkOnboardingInputInvalid(unitApt)}
					value={unitApt.value}
					onChange={(e) => handleOnboardingInputChange(e.target.value, null, setUnitApt)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput
					required
					label="Street Address*"
					placeholder="Street Address"
					valid={checkOnboardingInputValid(streetAddress)}
					invalid={checkOnboardingInputInvalid(streetAddress)}
					value={streetAddress.value}
					helperText={streetAddress.valid && streetAddress.valid}
					onChange={(e) => handleOnboardingInputChange(e.target.value, validate.value, setStreetAddress)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput
					label="Suburb/Rural"
					placeholder="Suburb/Rural"
					valid={checkOnboardingInputValid(suburb)}
					// invalid={checkOnboardingInputInvalid(suburb)}
					value={suburb.value}
					// helperText={suburb.valid && suburb.valid}
					onChange={(e) => handleOnboardingInputChange(e.target.value, null, setSuburb)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput
					required
					label="Town/City*"
					placeholder="Town/City"
					valid={checkOnboardingInputValid(townCity)}
					invalid={checkOnboardingInputInvalid(townCity)}
					value={townCity.value}
					helperText={townCity.valid && townCity.valid}
					onChange={(e) => handleOnboardingInputChange(e.target.value, validate.value, setTownCity)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput
					label="Postcode"
					placeholder="Postcode"
					valid={checkOnboardingInputValid(postcode)}
					// invalid={checkOnboardingInputInvalid(postcode)}
					value={postcode.value}
					// helperText={postcode.valid && postcode.valid}
					onChange={(e) => handleOnboardingInputChange(e.target.value, null, setPostcode)}
				/>
			</Grid>
			<Grid item container spacing={3} justifyContent="flex-end">
				<Grid item xs="auto">
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
				</Grid>
				<Grid item xs="auto">
					<Button disabled={!formValid} onClick={() => handleConfirm()}>
						Confirm
					</Button>
				</Grid>
			</Grid>
		</Modal>
	);
}

export default CustomAddressModal;
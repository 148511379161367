import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useRemote } from '../../utils/ApiUtils';
import Logos from "../../../resources/images/main-logo.png";
import LoadingIcon from '../../misc/LoadingIcon';
import FlexWrapper from '../../layout/FlexWrapper';
import { AlertOctagon, CheckCircle } from 'react-feather';
import Button from '../../buttons/Button';

const HandleBankVerification = () => {

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const code = searchParams.get("code")
	const remote = useRemote();
	const [response, setResponse] = useState({});

	// dummy states for testing UI
	// const verified = true;
	// const error = true;

	useEffect(() => {
		if (!code) {
			console.log("No code, so naving")
			navigate("/");
		}
		remote.post("/modules/hobson-support/stockfox/verify-bank/"+code).then(json => {
			setResponse(json);
			if (json && json.connectionError) {
				//TODO - show error message, get them to try again?
			}
		});
	}, [code])

	// Success
	if (response && response.connectionError === false && response.data.length > 0) {
		return (
			<div className="transition-fade-up-slow p-m" alignitems="flex-end" style={{ maxWidth: 500, margin: "0 auto" }}>
				<Grid container spacing={3} className="transition-fade-in-left" justifyContent="center">
					<Grid item xs={12} container justifyContent='center'>
						<FlexWrapper gap="m">
							<div className="title colour-text-black">Bank account verified</div>
							<CheckCircle className="colour-text-positive" />
						</FlexWrapper>
					</Grid>
					<Grid item xs={12}>
						<p className="text-align-center">Your bank account has been successfully verified. First AML will be in touch shortly, but in the meantime, check out your portfolio.</p>
					</Grid>
					<Grid item>
						<Button onClick={() => navigate("/")}>Continue to Portfolio</Button>
					</Grid>
					<Grid item xs={12} container justifyContent="center">
						<img src={Logos} style={{ width: 100 }} className="mt-xl" alt="3skye" />
					</Grid>
				</Grid>
			</div>
		)
	}

	// Failed
	if (response && response.connectionError) {
		return (
			<div className="transition-fade-up-slow p-m" alignitems="flex-end" style={{ maxWidth: 500, margin: "0 auto" }}>
				<Grid container spacing={3} className="transition-fade-in-left" justifyContent="center">
					<Grid item xs={12}>
						<FlexWrapper gap="m">
							<div className="title colour-text-black">Unable to verify bank details</div>
							<AlertOctagon className="colour-text-danger" />
						</FlexWrapper>
					</Grid>
					<Grid item xs={12}>
						<p className="text-align-center">{response.connectionErrorMessage ? response.connectionErrorMessage : "We were unable to retrieve your bank account details"}</p>
					</Grid>
					<Grid item>
						{/* TODO hook up button */}
						<Button onClick={() => navigate("/")}>Start Over</Button>
					</Grid>
					<Grid item xs={12} container justifyContent="center">
						<img src={Logos} style={{ width: 100 }} className="mt-xl" alt="3skye" />
					</Grid>
				</Grid>
			</div>
		);
	}

	// Loading
	return (
		<div className="transition-fade-up-slow p-m" alignitems="flex-end" style={{ maxWidth: 500, margin: "0 auto" }}>
			<Grid container spacing={3} className="transition-fade-in-left">
				<Grid item xs={12} container justifyContent="center">
					<FlexWrapper gap="m">
						<div className="title colour-text-black">Verifying bank details</div>
						<LoadingIcon size={24} colour="brand" />
					</FlexWrapper>
				</Grid>
				<Grid item xs={12}>
					<p className="text-align-center">Please wait a moment while we verify your bank account</p>
				</Grid>
				<Grid item xs={12} container justifyContent="center">
					<img src={Logos} style={{ width: 100 }} className="mt-xl" alt="3skye" />
				</Grid>
			</Grid>
		</div>
	);
}

export default HandleBankVerification;
import { Menu, MenuItem } from '@material-ui/core';
import * as React from 'react';
import { Bell, Info } from 'react-feather';
import FlexWrapper from '../layout/FlexWrapper';
import withNotifications from '../utils/withNotifications';
import "./NotificationBell.scss";
import NotificationItem from './NotificationItem';

const NotificationBell = ({className, onClick, notifications, onNotificationClick, deleteNotification, notificationsLoading }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const unreadNotifications = true;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={`notification-bell-wrapper${open ? " notification-menu-active" : ""}${unreadNotifications ? " unread-notifications" : ""}${className ? ' ' + className : ''}`}>
			<Bell className="notification-bell-icon" size={28} id="notification-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={onClick ? onClick : handleClick} />
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: ("top"),
					horizontal: ("left"),
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				MenuListProps={{
					"aria-labelledby": "notification-button",
					className: "notification-menu-wrapper",
				}}
				PaperProps={{
					style: {
						maxHeight: 600,
					},
				}}
			>
				<FlexWrapper className="menu-header desktop-notification-header" justify="space-between">
					<h2>Notifications</h2>
					<p className="colour-text-brand">Mark all as read</p>
				</FlexWrapper>
				{notifications && notifications.length > 0 ? (
					notifications.map((notif, idx) => <NotificationItem onClick={onNotificationClick} key={idx} notification={notif} deleteNotification={deleteNotification} />)
				) : (
					<FlexWrapper justify="center" align="center" className="p-l" gap="s">
						<Info size={16} className="colour-text-brand" /> <p>No notifications to display yet</p>
					</FlexWrapper>
				)}
			</Menu>
		</div>
	);
}

export default withNotifications(NotificationBell);
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { dummyNotifications } from '../../../resources/DummyData';
import NotificationBell from '../../buttons/NotificationBell';
import Modal from '../../layout/Modal';
import MobileNotificationMenu from '../../menus/MobileNotificationMenu';
import MobileTopBar from '../../menus/MobileTopBar';
import { useRemote } from '../../utils/ApiUtils';
import { NotificationsWrapper } from '../../utils/withNotifications';
import BankVerification from './pages/BankVerification';
import "./PortalWrapper.scss";
import SidebarNav from './sidebar/SidebarNav';


const pages = [
	{ label: "Portfolio", path: "/" },
	{ label: "Orders", path: "/orders" },
	{ label: "Order History", path: "/order-history" },
	{ label: "Transactions", path: "/transactions" },
	{ label: "Settings", path: "/settings" },
	{ label: "Contact Us", path: "/contact-us" },
];

const PortalWrapper = ({logout}) => {
	const location = useLocation();
	const [openMenu, setOpenMenu] = React.useState(false);
	const handleCloseMenu = () => setOpenMenu(false);
	const [openNotifications, setOpenNotifications] = React.useState(false);
	const handleCloseNotifications = () => setOpenNotifications(false);

	const [user, setUser] = useState({});

	// const [needsEmailVerification, setNeedsEmailVerification] = useState(false);
	const [needsBankVerification, setNeedsBankVerification] = useState(false);
	const [loading, setLoading] = useState(true);

	const remote = useRemote();

	useEffect(() => {
		remote.get("/modules/hobson-support/stockfox/current-user").then(json => {
			setUser(json.user);
			setNeedsBankVerification(json.needsBankVerification);
			// setNeedsEmailVerification(json.needsEmailVerification);
		 	setLoading(false);
		});
	}, [remote]);

	if (loading) {
		return <div></div>
	}

	const Page = () => needsBankVerification ? <BankVerification/> : <Outlet context={user} />
	
	return (
		<div id="portal-wrapper">
			{/* Mobile Top Banner */}
			<MobileTopBar handleMenuClick={() => setOpenMenu(true)} handleNotificationClick={() => setOpenNotifications(true)} />
			{/* Mobile Notifications */}
			<Modal hideClose open={openNotifications} handleClose={handleCloseNotifications} className="notification-menu-wrapper-mobile display-mobile-only">
				<MobileNotificationMenu notifications={dummyNotifications} handleClose={handleCloseNotifications} />
			</Modal>
			{/* Mobile side nav */}
			<Modal hideClose open={openMenu} handleClose={handleCloseMenu} className="menu-wrapper display-mobile-only">
				<SidebarNav links={pages} activePage={location.pathname} onLinkClick={handleCloseMenu} user={user} />
			</Modal>
			<div className="display-desktop-only">
				<SidebarNav links={pages} activePage={location.pathname} logout={logout} user={user} />
			</div>
			<div id="page-content-wrapper">
				<Page/>
				<NotificationsWrapper className="display-desktop-only"><NotificationBell /></NotificationsWrapper>
			</div>
		</div>
	);
}

export default PortalWrapper;
import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import TransactionsHistoryTable from '../../../tables/TransactionsHistoryTable'
import { useRemote } from '../../../utils/ApiUtils';
import Toast from '../../../misc/Toast';
import TransactionsFilters from '../../../filters/TransactionsFilters';
import withTransactions from '../../../utils/withTransactions';
import { withThreeSkyeGlobal } from '@threeskye/global';
import PortalCard from '../../../layout/PortalCard';

export const transactionTypes = { buy: "Buy", sell: "Sell" };

const TransactionsPage = ({transactions, isLoadingTransactions, transactionsConnectionError, currencies, assets, updateTransactions, exportTransactions, setGenerateCSVFunc,}) => {

	const [errorToastOpen, setErrorToastOpen] = useState(false)
	const [account, setAccount] = useState(null)

	const remote = useRemote()

	useEffect(() => {
		remote.get("/modules/crm/client/accounts")
			.then((accounts) => {
				const accountToSet = accounts[0]
				setAccount(accountToSet)
			})
	}, [remote])

	const closeToast = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setErrorToastOpen(false);
	};

	return (
		<>
			<Toast
				open={errorToastOpen}
				message={transactionsConnectionError === true ? "Unable to retrieve account data" : transactionsConnectionError}
				type="danger"
				handleClose={closeToast}
			/>
			<Grid container spacing={3} className="transition-fade-up-slow">
				<Grid item xs={12}>
					<div className="title colour-text-black">Transactions</div>
				</Grid>
				<Grid item xs={12} style={{zIndex: 100}}>
					<TransactionsFilters {...{ currencies, assets, updateTransactions }} account={account} />
				</Grid>
				<Grid item xs={12}>
					<PortalCard>
						<Grid item xs={12}>
							<TransactionsHistoryTable transactions={transactions} loading={isLoadingTransactions} connectionError={transactionsConnectionError} />
						</Grid>
					</PortalCard>
				</Grid>
			</Grid>
		</>
	);
}


export default withThreeSkyeGlobal(withTransactions(TransactionsPage));

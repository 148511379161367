import aiaLogo from "../resources/images/AIA.NZ.png";
import cenLogo from "../resources/images/CEN.NZ.png";
import melLogo from "../resources/images/MEL.NZ.png";

export const dummyCash = [
	{ balance: '$756,331.00', currency: "NZD" },
	{ balance: '$155.00', currency: "AUD" },
];

export const dummyAddresses = [
	{ value: '123 Limgrave Way, 6021 Lands Between', label: '123 Limgrave Way, 6021 Lands Betweenolate' },
	{ value: '65 Coastal Cave, 6022 Lands Between', label: '65 Coastal Cave, 6022 Lands Between' },
	{ value: '3/92 Elder Tree Cres, 6021 Lands Between', label: '3/92 Elder Tree Cres, 6021 Lands Between' },
];

export const rwtOptions = [
	{ value: 0, label: '0%' },
	{ value: 10.5, label: '10.5%' },
	{ value: 17.5, label: '17.5%' },
	{ value: 30, label: '30%' },
	{ value: 33, label: '33%' },
	{ value: 39, label: '39%' },
	{ value: 45, label: '45%' }
];

export const pirOptions = [
	{ value: 0, label: '0%' },
	{ value: 10.5, label: '10.5%' },
	{ value: 17.5, label: '17.5%' },
	{ value: 28, label: '28%' },
];

export const taxTypeOptions = [
	{ value: "nz-resident", label: 'NZ Resident' },
	{ value: "non-resident", label: 'Non-Resident' },
];

export const dummyCountryOptions = [
	{ value: 'Fantasia', label: 'Fantasia' },
	{ value: 'Krondor', label: 'Krondor' },
	{ value: 'Lothric', label: 'Lothric' },
	{ value: 'Wonderland', label: 'Wonderland' },
];

export const dummyCurrencies = [
	{ value: 'AUD', label: 'AUD' },
	{ value: 'NZD', label: 'NZD' },
	{ value: 'USD', label: 'USD' }
];

export const dummyBankAccounts = [
	{
		label: "Michael Jordan",
		value: "Michael Jordan",
		fields: [
			{ value: "Bank of NZ", name: "Bank Name", placeholder: "Bank name", width: 80 },
			{ value: "02-0162-0030388-00", name: "Account Number", placeholder: "Account Number" },
			{ value: "NZD", name: "Currency", placeholder: "Currency", width: 80 },
		],
	},
	{
		label: "MJ and Mary",
		value: "MJ and Mary",
		fields: [
			{ value: "Bank of NZ", name: "Bank Name", placeholder: "Bank name", width: 80 },
			{ value: "02-0162-0030388-00", name: "Account Number", placeholder: "Account Number" },
			{ value: "NZD", name: "Currency", placeholder: "Currency", width: 80 },
		],
	},
];

export const bankAccounts = [
	{
		label: "Michael Jordan",
		value: "Michael Jordan",
		bankName: "Bank of NZ",
		accountNumber: "02-0162-0030388-00",
		currency: "NZD"
	},
	{
		label: "MJ and Mary",
		value: "MJ and Mary",
		bankName: "Westpac",
		accountNumber: "02-0196-0020478-00",
		currency: "NZD"
	},
	{
		label: "Savings",
		value: "Savings",
		bankName: "Westpac",
		accountNumber: "02-0196-0020478-00",
		currency: "NZD"
	},
];

export const dummyTickers = [
	{ value: "AIA.NZ", label: "Auckland International Airport Ltd", code: "AIA.NZ", img: aiaLogo, assetPrice: 96.04 },
	{ value: "CEN.NZ", label: "Contact Energy Ltd", code: "CEN.NZ", img: cenLogo, assetPrice: 1.87 },
	{ value: "MEL.NZ", label: "Mercury Energy Ltd", code: "MEL.NZ", img: melLogo, assetPrice: 12.54 }
];

export const dummyOrderList = [
	{ code: "AIA.NZ", exchange: "NZX", action: "buy", assetPrice: "$7.70", quantity: 2, estimatedCost: "$15.40", logo: aiaLogo, currency: "NZD" },
	{ code: "CEN.NZ", exchange: "NZX", action: "sell", assetPrice: "$7.70", quantity: 1000, estimatedCost: "$7700.40", logo: cenLogo, currency: "NZD" },
	{ code: "MEL.NZ", exchange: "NZX", action: "buy", assetPrice: "$7.70", quantity: 52, estimatedCost: "$400.04", logo: melLogo, currency: "NZD" },
];

export const dummyNotifications = [
	{ name: "Market Order", date: "15 June 2022", time: "12:22 PM", order: { asset: "AIA.NZ", action: "buy", total: "$15.23", logo: aiaLogo }, status: "unread" },
	{ name: "Limit Order Expired", date: "15 June 2022", time: "12:22 PM", order: { asset: "CEN.NZ", action: "sell", total: "$5.23", logo: cenLogo }, status: "unread" },
	{ name: "Limit Order Expired", date: "14 June 2022", time: "12:22 PM", order: { asset: "MEL.NZ", action: "sell", total: "$893.12", logo: melLogo }, status: "unread" },
	{ name: "You're a Wizard Harry", date: "13 June 2022", time: "12:22 PM", content: "I'm a what?", status: "unread" },
	{ name: "Goblet of Fire Market", date: "13 June 2022", time: "12:22 PM", content: "Krum's thick eyebrows contracted slightly. Cedric was still looking politely bewildered. Fleur frowned.", status: "read" },
	{ name: "Market Order", date: "12 June 2022", time: "12:22 PM", order: { asset: "AIA.NZ", action: "buy", total: "$15.23", logo: aiaLogo }, status: "read" },
	{ name: "Limit Order Expired", date: "12 June 2022", time: "12:22 PM", order: { asset: "CEN.NZ", action: "sell", total: "$5.23", logo: cenLogo }, status: "read" },
];

export const months = [
	{ label: "January", value: 1, shortLabel: "Jan" },
	{ label: "February", value: 2, shortLabel: "Feb" },
	{ label: "March", value: 3, shortLabel: "Mar" },
	{ label: "April", value: 4, shortLabel: "Apr" },
	{ label: "May", value: 5, shortLabel: "May" },
	{ label: "June", value: 6, shortLabel: "June" },
	{ label: "July", value: 7, shortLabel: "July" },
	{ label: "August", value: 8, shortLabel: "Aug" },
	{ label: "September", value: 9, shortLabel: "Sep" },
	{ label: "October", value: 10, shortLabel: "Oct" },
	{ label: "November", value: 11, shortLabel: "Nov" },
	{ label: "December", value: 12, shortLabel: "Dec" }
];

export const orderHistory = [
	{ date: "2011-11-11", ticker: dummyTickers[0], status: "Open", action: "Buy", type: "Market", qty: 5, assetPrice: 7, total: 5500 },
	{ date: "2022-02-22", ticker: dummyTickers[1], status: "Closed", action: "Buy", type: "Market", qty: 5, assetPrice: 7, total: 30000 },
	{ date: "2011-11-11", ticker: dummyTickers[0], status: "Open", action: "Sell", type: "Market", qty: 5, assetPrice: 7, total: 5500 },
	{ date: "2022-02-22", ticker: dummyTickers[1], status: "Closed", action: "Buy", type: "Market", qty: 5, assetPrice: 7, total: 30000 },
	{ date: "2011-11-11", ticker: dummyTickers[0], status: "Open", action: "Sell", type: "Market", qty: 5, assetPrice: 7, total: 5500 },
	{ date: "2022-02-22", ticker: dummyTickers[0], status: "Closed", action: "Buy", type: "Market", qty: 5, assetPrice: 7, total: 30000 },
]

export const transactions = [
	{ date: "2022-04-01", action: "Sell", particulars: "NZD", typeActivity: "Sell Vital Healthcare Property Trust", quantity: 40, unitCost: 12.84, currency: { name: "NZD", id: 1 }, total: 513.6, cashBalance: 10128.34 },
	{ date: "2022-01-11", action: "Buy", particulars: "NZD", typeActivity: "Buy Spark", quantity: 10, unitCost: 32.05, currency: { name: "NZD", id: 1 }, total: -320.5, cashBalance: 9614.74 },
	{ date: "2021-10-25", action: "Buy", particulars: "NZD", typeActivity: "Buy Vital Healthcare Property Trust", quantity: 40, unitCost: 12.84, currency: { name: "NZD", id: 1 }, total: -513.6, cashBalance: 9935.24 },
	{ date: "2021-06-06", action: "Buy", particulars: "NZD", typeActivity: "Buy GameStop, never to sell", quantity: 200, unitCost: 5.53, currency: { name: "NZD", id: 1 }, total: -1106, cashBalance: 10448.84 },
	{ date: "2021-02-20", action: "Buy", particulars: "NZD", typeActivity: "Buy Nintendo", quantity: 56, unitCost: 21.67, currency: { name: "NZD", id: 1 }, total: -1213.52, cashBalance: 11554.84 },
]

export const dummyNZEquity = [
	{asset: {ticker: "EBO", name: "Ebos Group Limited"}, localCost: 100, localCurrency: dummyCurrencies[1], nzdCost: 100, units: 25, currentUnitPrice: 100, currentLocalValue: 100, currentNzdValue: 100, pctOfHoldings: 100},
	{asset: {ticker: "EBO", name: "Ebos Group Limited"}, localCost: 100, localCurrency: dummyCurrencies[1], nzdCost: 100, units: 25, currentUnitPrice: 100, currentLocalValue: 100, currentNzdValue: 100, pctOfHoldings: 100},
	{asset: {ticker: "EBO", name: "Ebos Group Limited"}, localCost: 100, localCurrency: dummyCurrencies[1], nzdCost: 100, units: 25, currentUnitPrice: 100, currentLocalValue: 100, currentNzdValue: 100, pctOfHoldings: 100},
	{asset: {ticker: "EBO", name: "Ebos Group Limited"}, localCost: 100, localCurrency: dummyCurrencies[1], nzdCost: 100, units: 25, currentUnitPrice: 100, currentLocalValue: 100, currentNzdValue: 100, pctOfHoldings: 100}
]

export const dummyCashAssets = [
	{ asset: "Cash AUD", name: "AUD Call Account", pendingTransactions: "-500.00", cashHeld: "10,000.00", total: "9,500.00", totalNZD: "10,398.54", pctOfHoldings: "33.00%" },
	{ asset: "Cash NZD", name: "NZD Call Account", pendingTransactions: "-4,300.00", cashHeld: "10,000.00", total: "5,700.00", totalNZD: "5,700.00", pctOfHoldings: "18.08%" }
]

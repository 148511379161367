import React from 'react';
import "./TableHeader.scss";

const TableHeader = ({value, alignment, className, width}) => {
	return (
		<th className={`table-header${className ? ' ' + className : ''}`} style={{textAlign: alignment ? alignment : "left",  width: width}}>
			{value}
		</th>
   );
}

export default TableHeader;
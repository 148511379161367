import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextInput from "../../../inputs/TextInput";
import { validate, handleOnboardingInputChange, checkOnboardingInputValid, checkOnboardingInputInvalid } from '../../../utils/ValidationUtils'

const OnboardingAml = ({ clientSetup, setAML, setComponentValid }) => {
	const [sourceOfWealth, setSourceOfWealth] = useState({ value: "", touched: false, valid: "" });
	const [sourceOfFunds, setSourceOfFunds] = useState({ value: "", touched: false, valid: "" });
	const [purpose, setPurpose] = useState({ value: "", touched: false, valid: "" });
	const [regularDeposits, setRegularDeposits] = useState({ value: "", touched: false, valid: "" });

	useEffect(() => {
		if (clientSetup && clientSetup.uuid) {
			setSourceOfWealth({ value: clientSetup.wealthSource === null ? "" : clientSetup.wealthSource, touched: false, valid: "" });
			setSourceOfFunds({ value: clientSetup.fundsSource === null ? "" : clientSetup.fundsSource, touched: false, valid: "" });
			setPurpose({ value: clientSetup.purpose === null ? "" : clientSetup.purpose, touched: false, valid: "" });
			setRegularDeposits({ value: clientSetup.investmentBehaviour === null ? "" : clientSetup.investmentBehaviour, touched: false, valid: "" });
		}
	}, [clientSetup])

	useEffect(() => {
		if (clientSetup && clientSetup.uuid) {
			const isComponentValid = ![
				validate.response(sourceOfWealth) === "" && sourceOfWealth.value !== "",
				validate.response(sourceOfFunds) === "" && sourceOfFunds.value !== "",
				validate.response(purpose) === "" && purpose.value !== "",
				validate.response(regularDeposits) === "" && regularDeposits.value !== ""
			].includes(false)
			setComponentValid(isComponentValid)
			setAML({
				wealthSource: sourceOfWealth.value,
				fundsSource: sourceOfFunds.value,
				purpose: purpose.value,
				investmentBehaviour: regularDeposits.value,
			});
		}
	}, [clientSetup, sourceOfWealth, sourceOfFunds, purpose, regularDeposits, setAML, setComponentValid]);


	return (
		<div className="mt-l">
			{/* General Detail Fields Here */}
			<Grid className="transition-fade-up-slow" container spacing={3}>
				<Grid item xs={12}>
					<h1>AML</h1>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						textArea
						required
						label="What is your purpose for investing with 3SKYE?*"
						placeholder="e.g. Saving for retirement, generating income"
						valid={checkOnboardingInputValid(purpose)}
						invalid={checkOnboardingInputInvalid(purpose)}
						helperText={purpose.valid && purpose.valid}
						value={purpose.value}
						onChange={(e) => handleOnboardingInputChange(e.target.value, validate.response, setPurpose)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						textArea
						required
						label="Is this a one-off investment or do you intend to make regular deposits for investment?*"
						placeholder="e.g. initial lump sum of $X, ongoing basis of $X per month"
						value={regularDeposits.value}
						valid={checkOnboardingInputValid(regularDeposits)}
						invalid={checkOnboardingInputInvalid(regularDeposits)}
						helperText={regularDeposits.valid && regularDeposits.valid}
						onChange={(e) => handleOnboardingInputChange(e.target.value, validate.response, setRegularDeposits)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						textArea
						required
						label="Given your intended investment activity please provide details on where these funds will be coming from*"
						placeholder="e.g. sale of assets, term deposit, inheritance etc."
						value={sourceOfFunds.value}
						valid={checkOnboardingInputValid(sourceOfFunds)}
						invalid={checkOnboardingInputInvalid(sourceOfFunds)}
						helperText={sourceOfFunds.valid && sourceOfFunds.valid}
						onChange={(e) => handleOnboardingInputChange(e.target.value, validate.response, setSourceOfFunds)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						textArea
						required
						label="What is your source of Wealth?*"
						placeholder="e.g. sale of assets, employment income, inheritance, insurance pay-out etc."
						value={sourceOfWealth.value}
						valid={checkOnboardingInputValid(sourceOfWealth)}
						invalid={checkOnboardingInputInvalid(sourceOfWealth)}
						helperText={sourceOfWealth.valid && sourceOfWealth.valid}
						onChange={(e) => handleOnboardingInputChange(e.target.value, validate.response, setSourceOfWealth)}
					/>
				</Grid>
			</Grid>
		</div>
	);
}
export default OnboardingAml;
import React from 'react';
import "./Divider.scss";

const Divider = ({vertical, dashed, className, height, spacing, colour, style, id}) => {
	let dividerClass = "divider";
	if (className) {
		dividerClass += (" " + className);
	}
	if (vertical) {
		dividerClass += " divider-vertical";
	}
	if (dashed) {
		dividerClass += " divider-dashed";
	}
	if (spacing) {
		dividerClass += " spacing-" + spacing;
	}
	if (colour) {
		dividerClass += " colour-" + colour;
	}
	return (
		<hr id={id} className={dividerClass} style={{...style, height: height}} />
	)
}

export default Divider;
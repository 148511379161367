import React from 'react';
import { Info } from 'react-feather';
import FlexWrapper from '../layout/FlexWrapper';

const LimitOrderMessage = ({gap, ...props}) => {
	return (
		<FlexWrapper {...props} gap={gap ? gap : "s"}>
			<Info className="icon-size-l colour-text-brand" />
			<h2 style={{ margin: 0 }} className="font-weight-regular">
				Limit orders will expire end of the trading day
			</h2>
		</FlexWrapper>
	);
}

export default LimitOrderMessage;
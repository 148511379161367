import React from 'react';
import "./PortalCard.scss";

export const PortalCardTab = ({onClick, label, active, disabled }) => {
	return (
		<div className={`portal-card-tab${active ? " tab-active" : ""}${disabled ? " tab-disabled" : ""}`} onClick={onClick}>{label}</div>
	)
}

const PortalCard = ({children, tabs, className}) => {
	return (
		<div className={`portal-card-wrapper${tabs ? " portal-card-with-tabs" : ""}${className ? ' ' + className : ''}`}>
			{tabs && <div className="portal-card-tabs-wrapper">{tabs.map((tab, idx) => <PortalCardTab disabled={tab.disabled} active={tab.active} key={idx} onClick={tab.onClick} label={tab.label} />)}</div>}
			{children}
		</div>
   );
}

export default PortalCard;
import { Grid } from '@material-ui/core';
import React from 'react';
import NumberFieldInput from '../inputs/NumberFieldInput';
import useWindowDimensions from '../utils/DisplayUtils';
import NumberFormat from '../utils/NumberFormat';

const OrderFieldRow = ({ balance, currency, balanceLabel, amount, onChange, autoFocus}) => {
	const { width } = useWindowDimensions();
	const isMobile = width < 600;

	return (
		<>
			<Grid item xs={12} sm={5} className={isMobile ? "text-align-right" : ""}>
				<h2><NumberFormat places={2} value={balance} suffix="" prefix="$" /></h2>
				{balanceLabel && <p className="sub-label transition-fade-up">{balanceLabel}</p>}
			</Grid>
			<Grid item xs={12} sm={7}>
				<NumberFieldInput autoFocus={autoFocus} innerLabel={currency ? currency : "NZD"} placeholder="$0" value={amount} onChange={onChange} />
			</Grid>
			{currency !== "NZD" && (
				<Grid item xs={12} className="text-align-right">
					<p className="sub-label">Conversion* = 0 NZD</p>
				</Grid>
			)}
		</>
	);
}

export default OrderFieldRow;
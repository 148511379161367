import React from 'react';
import {Table} from '@material-ui/core';
import {TableBody} from '@material-ui/core';
import {TableCell} from '@material-ui/core';
import {TableContainer} from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import "./SimpleTable.scss";

const SimpleTable = ({rows}) => {
	return (
		<TableContainer className="simple-table">
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableBody>
					{rows && rows.map((row) => (
						<TableRow key={row.name}>
							<TableCell component="td" scope="row">
								{row.name}
							</TableCell>
							<TableCell align="right">{row.value}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default SimpleTable;
import { Portal, Snackbar } from '@material-ui/core';
import React from 'react';
import { CheckCircle, X } from 'react-feather';
import "./Toast.scss";

const Toast = ({open, handleClose, message, type, closeable, autoHideDuration}) => {
	return (
		<Portal>		
			<Snackbar
				open={open}
				onClose={handleClose}
				autoHideDuration={autoHideDuration ? autoHideDuration : 10000}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				key={'topright'}
				message={<div style={{ display: "flex", alignItems: "center", gap: 24 }}>{message}{type === "positive" && <CheckCircle size="24" />}</div>}
				className={`toast toast-type-${type}`}
				action={closeable ? <X style={{cursor: "pointer"}} onClick={handleClose} /> : ""}
			/>
		</Portal>
   );
}

export default Toast;
import React from 'react';
import AssetLogo from './AssetLogo';
import "./AssetOptions.scss";

const AssetOption = ({logo, name, code}) => {
	return (
		<div className="asset-option-wrapper">
			<AssetLogo logo={logo} alt={name} size={32} />
			<div className="asset-details">
				<div className="asset-name">{name}</div>
				<div className="asset-code">{code}</div>
			</div>
		</div>
	);
}

export default AssetOption;
import React, { useEffect, useState } from 'react';
import GridItemDivider from '../../../layout/GridItemDivider';
import AccountInformation from '../sections/settings/AccInformation';
import SecuritySection from '../sections/settings/SecuritySection';
import {useStorage} from '../../../utils/ApiUtils'
import { useOutletContext } from 'react-router-dom';

const SettingsPage = () => {

	const storage = useStorage()
	const client = useOutletContext()
	const [account, setAccount] = useState(null)
	const [clientDetails, setClientDetails] = useState(null)
	const [accountDetails, setAccountDetails] = useState(null)

	useEffect(() => {
		Promise.all([
			storage.getOrFetch(`/modules/crm/client/accounts?dynamicFields=CustodyType`),
			storage.getOrFetch(`/modules/crm/client/clients/${client.id}/details?complete=true`)
		])
		.then(([accounts, clientDetails]) => {
			setAccount(accounts[0])
			setAccount(accounts[0])
			setClientDetails(clientDetails)
			
			storage.getOrFetch("/modules/crm/provider/accounts/" + accounts[0].id).then((addresses) => {
				setAccountDetails(addresses)
			})
					// })
					})	
			}, [])

	return (
		<>
			{/* <SecuritySection />
			<GridItemDivider spacing={5}/> */}
			<AccountInformation clientDetails={clientDetails} accountDetails={accountDetails} />
		</>
	);
}

export default SettingsPage;
import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextInput from "../../../inputs/TextInput";
import { validate, handleOnboardingInputChange, checkOnboardingInputValid, checkOnboardingInputInvalid } from '../../../utils/ValidationUtils'


const OnboardingOccupation = ({ clientSetup, setOccupation, componentValid, setComponentValid }) => {
	const [currentOccupation, setCurrentOccupation] = useState({ value: "", touched: false, valid: "" });
	const [prevOccupation, setPrevOccupation] = useState({ value: "", touched: false, valid: "" });
	const [employer, setEmployer] = useState({ value: "", touched: false, valid: "" });
	const [industry, setIndustry] = useState({ value: "", touched: false, valid: "" });

	useEffect(() => {
		if (clientSetup && clientSetup.uuid) {
			setCurrentOccupation({ value: clientSetup.occupation === null ? "" : clientSetup.occupation, touched: false, valid: "" })
			setPrevOccupation({ value: clientSetup.previousOccupation === null ? "" : clientSetup.previousOccupation, touched: false, valid: "" })
			setEmployer({ value: clientSetup.employer === null ? "" : clientSetup.employer, touched: false, valid: "" })
			setIndustry({ value: clientSetup.industry === null ? "" : clientSetup.industry, touched: false, valid: "" })
		}
	}, [clientSetup])

	useEffect(() => {
		if (clientSetup && clientSetup.uuid) {
			const isComponentValid = ![
				validate.occupation(currentOccupation, prevOccupation) === "" && (currentOccupation.value !== "" || prevOccupation.value !== ""),
				validate.employer(employer) === "",
				validate.industry(industry) === ""
			].includes(false)
			setComponentValid(isComponentValid)
			setOccupation({
				occupation: currentOccupation.value && currentOccupation.value !== "" ? currentOccupation.value : null,
				previousOccupation: prevOccupation.value && prevOccupation.value !== "" ? prevOccupation.value : null,
				employer: employer.value && employer.value !== "" ? employer.value : null,
				industry: industry.value && industry.value !== "" ? industry.value : null
			});
		}
	}, [clientSetup, currentOccupation, prevOccupation, employer, industry, setComponentValid, setOccupation]);



	return (
		<div className="mt-l mb-l">
			{/* General Detail Fields Here */}
			<Grid className="transition-fade-up-slow" container spacing={3}>
				<Grid item xs={12}>
					<h1>Occupation</h1>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput
						required
						label="Current Occupation*"
						placeholder="Occupation"
						valid={checkOnboardingInputValid(currentOccupation)}
						invalid={checkOnboardingInputInvalid(currentOccupation)}
						helperText={currentOccupation.valid && currentOccupation.valid}
						value={currentOccupation.value}
						onChange={(e) => {
							handleOnboardingInputChange(e.target.value, validate.occupation, setCurrentOccupation, [prevOccupation.value])
							handleOnboardingInputChange(prevOccupation.value, validate.occupation, setPrevOccupation, [e.target.value])
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput
						label="Previous Occupation (if not currently employed)"
						placeholder="Occupation"
						value={prevOccupation.value}
						valid={checkOnboardingInputValid(prevOccupation)}
						invalid={checkOnboardingInputInvalid(prevOccupation)}
						helperText={prevOccupation.valid && prevOccupation.valid}
						onChange={(e) => {
							handleOnboardingInputChange(e.target.value, validate.occupation, setPrevOccupation, [currentOccupation.value])
							handleOnboardingInputChange(currentOccupation.value, validate.occupation, setCurrentOccupation, [e.target.value])

						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput
						required
						label="Employer (or previous)*"
						placeholder="Employer"
						value={employer.value}
						valid={checkOnboardingInputValid(employer)}
						invalid={checkOnboardingInputInvalid(employer)}
						helperText={employer.valid && employer.valid}
						onChange={(e) => handleOnboardingInputChange(e.target.value, validate.employer, setEmployer)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput
						required
						label="Industry of Employment (or previous)*"
						placeholder="Industry"
						value={industry.value}
						valid={checkOnboardingInputValid(industry)}
						invalid={checkOnboardingInputInvalid(industry)}
						helperText={industry.valid && industry.valid}
						onChange={(e) => handleOnboardingInputChange(e.target.value, validate.industry, setIndustry)}
					/>
				</Grid>
			</Grid>
		</div>
	);
}
export default OnboardingOccupation;
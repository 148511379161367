import React from 'react';
import Grid from "@material-ui/core/Grid";
import { CheckCircle } from 'react-feather';
import FlexWrapper from '../../../layout/FlexWrapper';
import Button from '../../../buttons/Button';
import { useNavigate } from "react-router-dom";

const OnboardingComplete = () => {	
	let navigate = useNavigate();

	return (
		<>
			<Grid item container spacing={3}>
				<Grid item xs={12}>
					<FlexWrapper gap="s">
						<div className="title">Account Created</div>
						<CheckCircle className="colour-text-positive" />
					</FlexWrapper>
				</Grid>
				<Grid item xs={12}>
					<h1>Welcome!</h1>
					<p>Thanks for signing up, we've sent you an email, follow the link there to verify your email address.</p>
					{/* <Button className="mt-xl" onClick={() => navigate("/login")}>Login Now</Button> */}
				</Grid>
			</Grid>
		</>
	);
}
export default OnboardingComplete;
import React, { Component } from 'react';
import './TimeFrameSelectionMenu.scss';

class TimeFrameSelectionMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.selected || 0
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick(index, handler, e) {
        this.setState({selected: index});
        handler && handler(e);
    }
    render() {
        let className = "selection-menu";
        if (this.props.className)
            className += " " + this.props.className;
        if (this.props.wide) 
            className += " selection-menu-wide";
        if (this.props.narrow) 
            className += " selection-menu-narrow";
            
        const children = [];
        if (this.props.children.forEach) {
            this.props.children.forEach(child=>{
                if (child.forEach) {
                    child.forEach(grandchild=>children.push(grandchild));
                } else {
                    children.push(child);
                }
            });
        } else {
            children.push(this.props.children);
        }

        return <ul className={className}>
            { children.map((child, idx)=>{
                return React.cloneElement(child, {
                    onClick: e=>this.onClick(idx, child.props.onClick, e),
                    active: idx === this.state.selected,
                    key: idx
                });
            })}
        </ul>;
    }
}

export default TimeFrameSelectionMenu;
import { Grid } from '@material-ui/core';
import React from 'react';
import Button from '../buttons/Button';

const OrderPlacedMessage = ({orderType, handleClose}) => {
	return (
		<>
			<Grid item xs={12} className="text-align-center">
				<h1>Order Placed!</h1>
				<p>
					{orderType === "Limit"
						? "Confirmation of the trade will be provided if the limit price is met and completed."
						: "Confirmation of the trade will be provided once the trade is complete."}
				</p>
			</Grid>
			<Grid item container xs={12} justifyContent="center">
				<Button onClick={handleClose}>Close</Button>
			</Grid>
		</>
	);
}
export default OrderPlacedMessage;
import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { pirOptions, rwtOptions, taxTypeOptions } from '../../../../../resources/DummyData';
import RadioButtonGroup from '../../../../inputs/RadioButtonGroup';
import SingleSelectInput from '../../../../inputs/SingleSelectInput';
import TextInput from '../../../../inputs/TextInput';

const AccInfoTaxSection = ({ clientDetails }) => {
	const [irdNumber, setIrdNumber] = useState("");
	const [taxType, setTaxType] = useState("");
	const [taxRate, setTaxRate] = useState("");
	const [pirRate, setPirRate] = useState("");
	const [prescribedPerson, setPrescribedPerson] = useState("No");

	useEffect(() => {
		if (clientDetails) {
			const unstructuredData = clientDetails.unstructuredData ? JSON.parse(clientDetails.unstructuredData) : null
			setIrdNumber(clientDetails.taxNumbers && clientDetails.taxNumbers[0] ? clientDetails.taxNumbers[0].content.tin.split("-").join("") : "")
			setPirRate(unstructuredData.ClientPIR ? unstructuredData.ClientPIR : "")
			setTaxType(unstructuredData.TaxType ? unstructuredData.TaxType : "")
			setTaxRate(unstructuredData.TaxPercentage ? unstructuredData.TaxPercentage : "")
			// prescribedPerson()

		}
	}, [clientDetails])

	return (
		<Grid item container spacing={3}>
			<Grid item xs={12}>
				<h1>FATCA and Tax</h1>
			</Grid>
			<Grid item xs={12} sm={6}>
				{/* <SingleSelectInput
					label="Tax Type"
					placeholder="Tax Type"
					value={taxType}
					options={taxTypeOptions}
					onChange={(value) => setTaxType(value)}
					clearValue={() => setTaxType("")}
				/> */}
				<TextInput
					disabled
					label="Tax Type"
					placeholder="Tax Type"
					value={taxType}
					onChange={(value) => setTaxType(value)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput disabled label="IRD Number" placeholder="IRD Number" valid={irdNumber} value={irdNumber} onChange={(e) => setIrdNumber(e.target.value)} />
			</Grid>
			<Grid item xs={12} sm={6}>
				{/* <SingleSelectInput
					label="Tax Rate"
					placeholder="Tax Rate"
					value={taxRate}
					options={rwtOptions}
					onChange={(value) => setTaxRate(value)}
					clearValue={() => setTaxRate("")} 
				 /> */}
				<TextInput
					disabled
					label="Tax Rate"
					placeholder="Tax Rate"
					value={taxRate}
					// options={rwtOptions}
					onChange={(value) => setTaxRate(value)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				{/* <SingleSelectInput
					label="PIR Rate"
					placeholder="PIR Rate"
					value={pirRate}
					options={pirOptions}
					onChange={(value) => setPirRate(value)}
					clearValue={() => setPirRate("")} 
				 /> */}
				<TextInput
					disabled
					label="PIR Rate"
					placeholder="PIR Rate"
					value={pirRate}
					// options={pirOptions}
					onChange={(value) => setPirRate(value)}
				/>
			</Grid>
			<Grid item xs={12}>
				<RadioButtonGroup
					label="NZX Prescribed Person"
					options={[
						{ value: "Yes", label: "Yes", disabled: true},
						{ value: "No", label: "No", disabled: true},
					]}
					id="prescribed-person"
					onChange={(e) => {
						setPrescribedPerson(e.target.value);
					}}
					value={prescribedPerson}
				/>
			</Grid>
		</Grid>
	);
}

export default AccInfoTaxSection;
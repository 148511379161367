import React, { useState, useEffect } from "react";
import { isNullOrUndefined } from "../utils/commonFunctions";
import NumberFormat from "../utils/NumberFormat";
import TableHeader from "./TableHeader";
import "./SimpleTable.scss";
import "./TransactionHistoryTable.scss";
import TableData from "./TableData";
import Token from "../misc/Token";
import LoadingIcon from "../misc/LoadingIcon";
import EmptyStateMessage from "../misc/EmptyStateMessage";
import FlexWrapper from "../layout/FlexWrapper";

export const fields = {
	asset: "ASSET",
	ticker: "TICKER",
	localCost: "LOCAL_COST",
	localCurrency: "LOCAL_CURRENCY",
	nzdCost: "NZD_COST",
	units: "UNITS",
	currentUnitPrice: "CURRENT_UNIT_PRICE",
	currentLocalValue: "CURRENT_LOCAL_VALUE",
	currentNzdValue: "CURRENT_NZD_VALUE",
	pctOfHoldings: "PCT_OF_HOLDINGS",
};

const headers = {
	[fields.asset]: "Asset",
	[fields.localCost]: "Local Cost",
	[fields.localCurrency]: "Local Currency",
	[fields.nzdCost]: "NZD Cost",
	[fields.units]: "Units",
	[fields.currentUnitPrice]: "Current Unit Price",
	[fields.currentLocalValue]: "Current Local Value",
	[fields.currentNzdValue]: "Current NZD Value",
	[fields.pctOfHoldings]: "% of Holdings",
};

const display = {
	[fields.asset]: ({ name }) => name,
	[fields.ticker]: ({ tickerCode }) => tickerCode,
	[fields.localCost]: ({ nativeAssetCost }) => { return isNullOrUndefined(nativeAssetCost) ? null : <NumberFormat places={2} prefix="" value={nativeAssetCost} /> },
	[fields.localCurrency]: ({ nativeCurrency }) => nativeCurrency,
	[fields.nzdCost]: ({ assetCost }) => { return isNullOrUndefined(assetCost) ? null : <NumberFormat value={assetCost} places={2} /> },
	[fields.units]: ({ units }) => { return isNullOrUndefined(units) ? null : <NumberFormat value={units} /> },
	[fields.currentUnitPrice]: ({ unitPrice }) => isNullOrUndefined(unitPrice) ? null : <NumberFormat value={unitPrice} places={2} />,
	[fields.currentLocalValue]: ({ nativeValue }) => { return isNullOrUndefined(nativeValue) ? null : <NumberFormat places={2} prefix="" value={nativeValue} /> },
	[fields.currentNzdValue]: ({ value }) => { return isNullOrUndefined(value) ? null : <NumberFormat places={2} prefix="" value={value} /> },
	[fields.pctOfHoldings]: ({ percentPortfolio }) => { return isNullOrUndefined(percentPortfolio) ? null : <NumberFormat places={2} value={percentPortfolio} suffix="%" /> },
};

const NZEquityTable = ({ equities, loading, connectionError }) => {
	const [sortedEquities, setSortedEquities] = useState(null)

	useEffect(() => {
		setSortedEquities(equities)
	}, [equities])

	if (loading) {
		return <FlexWrapper fullWidth justify="center"><LoadingIcon colour="brand" /></FlexWrapper>;
	} else if (connectionError) {
		return <EmptyStateMessage message="Failed to load data for this account." />;
	} else {
		return (
			<div className="order-history-list">
				<div style={{ width: "100%", overflow: "auto" }}>
					<table className="simple-table transaction-table" style={{ width: "100%" }}>
						<tbody>
							<tr>
								<TableHeader value={headers[fields.asset]} />
								<TableHeader width={120} value="" />
								<TableHeader width={100} value={headers[fields.localCost]} alignment="right" />
								<TableHeader width={80} value={headers[fields.localCurrency]} />
								<TableHeader value={headers[fields.nzdCost]} alignment="right" />
								<TableHeader value={headers[fields.units]} alignment="right" />
								<TableHeader value={headers[fields.currentUnitPrice]} alignment="right" />
								<TableHeader value={headers[fields.currentLocalValue]} alignment="right" />
								<TableHeader value={headers[fields.currentNzdValue]} alignment="right" />
								<TableHeader value={headers[fields.pctOfHoldings]} alignment="right" />
							</tr>
						</tbody>
						<tbody>
							{sortedEquities &&
								sortedEquities.length > 0 &&
								sortedEquities.map((equity, index) => {
									return (
										<tr key={index}>
											<TableData><Token className="mr-s">{display[fields.ticker](equity) || "-"}</Token></TableData>
											<TableData>{display[fields.asset](equity) || "-"}</TableData>
											<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.localCost](equity)}</TableData>
											<TableData >{display[fields.localCurrency](equity) || "-"}</TableData>
											<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.nzdCost](equity) || "-"}</TableData>
											<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.units](equity) || "-"}</TableData>
											<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.currentUnitPrice](equity) || "-"}</TableData>
											<TableData alignment="right" className={`td-cash-balance text-align-right}`}>{display[fields.currentLocalValue](equity) || "-"}</TableData>
											<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.currentNzdValue](equity) || "-"}</TableData>
											<TableData alignment="right" className={`td-cash-balance text-align-right`}>{display[fields.pctOfHoldings](equity) || "-"}</TableData>
										</tr>
									)
								})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default NZEquityTable;

import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../../../buttons/Button';
import ActionTypeFilterCheck from '../../../filters/ActionTypeFilterCheck';
import OrderTypeFilterCheck from '../../../filters/OrderTypeFilterCheck';
import SeparateDateRangeFilter from '../../../filters/SeparateDateRangeFilter';
import TextInput from '../../../inputs/TextInput';
import FlexWrapper from '../../../layout/FlexWrapper';
import OrderHistoryList from '../../../tables/OrderHistoryList';
import AmountRangeInputs from '../../../inputs/AmountRangeInputs';
import StatusTypeFilterCheck from '../../../filters/StatusTypeFilterCheck';
import { useRemote, useStorage } from '../../../utils/ApiUtils'
import { parseISO, isBefore, isSameDay, isAfter } from 'date-fns';

const OrderHistoryPage = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [actionBuy, setActionBuy] = useState(false);
	const [actionSell, setActionSell] = useState(false);
	const [typeMarket, setTypeMarket] = useState(false);
	const [typeLimit, setTypeLimit] = useState(false);
	const [statusOpen, setStatusOpen] = useState(false);
	const [statusCompleted, setStatusCompleted] = useState(false);
	const [statusCancelled, setStatusCancelled] = useState(false);

	const [filtersVisible, setFiltersVisible] = useState("");
	const [filtersActive, setfiltersActive] = useState(false);

	const [initialOrderHistory, setInitialOrderHistory] = useState([])
	const [filteredOrderHistory, setfilteredOrderHistory] = useState([])

	const [assets, setAssets] = useState([]);
	const [tickers, setTickers] = useState([]);

	const remote = useRemote()
	const storage = useStorage();

	useEffect(() => {
		Promise.all([
			storage.getOrFetch("/modules/hobson-support/stockfox/tickers"),
			storage.getOrFetch("/modules/hobson-support/stockfox/assets"),
			remote.get("/modules/hobson-support/stockfox/order-history")
		])
			.then(([tickers, assets, orderHistory]) => {
				setTickers(tickers)
				setAssets(assets)
				setInitialOrderHistory(orderHistory)
				setfilteredOrderHistory(orderHistory)
			}).catch(() => {
				console.error("Order History call failed")
			})
	}, [storage, remote])

	const resetFilters = () => {
		setSearchTerm("")
		setFromDate("")
		setToDate("")
		setMinAmount("")
		setMaxAmount("")
		setActionBuy(false)
		setActionSell(false)
		setTypeMarket(false)
		setTypeLimit(false)
		setStatusOpen(false)
		setStatusCompleted(false)
		setStatusCancelled(false)
	}

	useEffect(() => {

		const getTickerFromAssetId = (assetId) => {
			if (!assets || !tickers || !assetId) return null;
			let assetFound = assets.find((asset) => asset.id === assetId)
			return tickers.find((ticker) => {
				let splitTicker = ticker.ric.split(".")
				if ((splitTicker[0] === assetFound.tickerCode) + (splitTicker[1] + "X " === assetFound.exchange)) return true
				else return false
			})
		}

		if ((searchTerm && searchTerm !== "") || (fromDate && fromDate !== "") || (toDate && toDate !== "") || (minAmount && minAmount !== "") || (maxAmount && maxAmount !== "") || typeLimit || typeMarket || actionBuy || actionSell) {
			setfiltersActive(true)
		} else setfiltersActive(false)

		let newFilteredOrderHistory = initialOrderHistory.filter((order) => {
			if (searchTerm && searchTerm !== "") {
				//filter by searchTerm
				let searchTerms = searchTerm.toLowerCase().split(" ")
				let orderTerms =
					[order.action && order.action.toLowerCase(),
					order.status && order.status.toLowerCase(),
					order.type && order.type.toLowerCase(),
					order.assetId && getTickerFromAssetId(order.assetId) && getTickerFromAssetId(order.assetId).ric && getTickerFromAssetId(order.assetId).ric.toLowerCase(),
					order.assetId && getTickerFromAssetId(order.assetId) && getTickerFromAssetId(order.assetId).name && getTickerFromAssetId(order.assetId).name.toLowerCase()
					].join(" ")
				let verify = searchTerms.map((term) => {
					if (orderTerms.includes(term)) return true
					else return false
				})
				if (verify.includes(false)) return false
			}
			if (fromDate && fromDate !== "") {
				//filter by fromDate
				if (isBefore(parseISO(order.createdAt), parseISO(fromDate))) return false
			}
			if (toDate && toDate !== "") {
				//filter by toDate
				if (isAfter(parseISO(order.createdAt), parseISO(toDate)) || isSameDay(parseISO(order.createdAt), parseISO(toDate))) return false
			}
			if (minAmount && minAmount !== "") {
				//filter by minAmount
				if (order.estimatedCost < minAmount) return false
			}
			if (maxAmount && maxAmount !== "") {
				//filter by maxAmount
				if (order.estimatedCost && order.estimatedCost > maxAmount) return false
			}
			if (typeMarket || typeLimit) {
				//filter by orderType
				if (typeMarket && !typeLimit) {
					if (order.type === "LIMIT") return false
				}
				if (!typeMarket && typeLimit) {
					if (order.type === "MARKET") return false
				}
			}
			if (actionBuy || actionSell) {
				//filter by actionType
				if (actionBuy && !actionSell) {
					if (order.action === "SELL") return false
				}
				if (!actionBuy && actionSell) {
					if (order.action === "BUY") return false
				}
			}
			if (statusOpen || statusCompleted || statusCancelled) {
				//filter by actionType
				let arr = [
					statusOpen ? order.status === "OPEN" : false,
					statusCancelled ? order.status === "CANCELLED" : false,
					statusCompleted ? order.status === "COMPLETED" : false,
				]
				if (!arr.includes(true)) return false
			}
			return true
		})

		setfilteredOrderHistory(newFilteredOrderHistory)

	}, [searchTerm, fromDate, toDate, typeMarket, typeLimit, actionBuy, actionSell, minAmount, maxAmount, initialOrderHistory, assets, tickers, statusOpen, statusCancelled, statusCompleted])

	return (
		<Grid item container spacing={3} className="transition-fade-up-slow">
			<Grid item xs={12}>
				<div className="title colour-text-black">Order History</div>
			</Grid>
			{/* Filters */}
			<Grid item xs={12}>
				<TextInput label="Search" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} controlledByProps/>
			</Grid>
			{filtersVisible ? (
				<>
					<SeparateDateRangeFilter
						sm={4}
						fromDate={{
							valid: fromDate,
							value: fromDate,
							onChange: (e) => setFromDate(e),
						}}
						toDate={{
							valid: toDate,
							value: toDate,
							onChange: (e) => setToDate(e),
						}}
					/>

					{/* <TotalCostFilter valid={totalCost} value={totalCost} onChange={(e) => setTotalCost(e.target.value)} />{" "} */}
					<AmountRangeInputs
						minAmount={{ value: minAmount, onChange: (e) => setMinAmount(e.target.value) }}
						maxAmount={{ value: maxAmount, onChange: (e) => setMaxAmount(e.target.value) }}
					/>
					<Grid item xs={12} sm={6} className="transition-default">
						<StatusTypeFilterCheck statusOpen={statusOpen} setStatusOpen={() => setStatusOpen(!statusOpen)} statusCompleted={statusCompleted} setStatusCompleted={() => setStatusCompleted(!statusCompleted)} statusCancelled={statusCancelled} setStatusCancelled={() => setStatusCancelled(!statusCancelled)} />
					</Grid>
					<Grid item xs={12} sm={6} md={3} className="transition-default">
						<ActionTypeFilterCheck actionBuy={actionBuy} setActionBuy={() => setActionBuy(!actionBuy)} actionSell={actionSell} setActionSell={() => setActionSell(!actionSell)} />
					</Grid>
					<Grid item xs={12} sm={6} md={3} className="transition-default">
						<OrderTypeFilterCheck typeMarket={typeMarket} setTypeMarket={() => setTypeMarket(!typeMarket)} typeLimit={typeLimit} setTypeLimit={() => setTypeLimit(!typeLimit)} />
					</Grid>
				</>
			) : (
				""
			)}
			<Grid item xs={12}>
				<FlexWrapper gap="s">
					<Button onClick={() => setFiltersVisible(!filtersVisible)}>{filtersVisible ? "Hide Filters" : "Show Filters"}</Button>
					<Button variant="secondary" onClick={() => resetFilters()}>
						Clear Filters
					</Button>
				</FlexWrapper>
			</Grid>
			<Grid item xs={12}>
				{/* <h2>History</h2> */}
				<OrderHistoryList orderHistory={filtersActive ? filteredOrderHistory : initialOrderHistory} assets={assets} tickers={tickers} />
			</Grid>
		</Grid>
	);
}

export default OrderHistoryPage;
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PortalCard from '../../../layout/PortalCard';
import WithdrawFundsModal from '../../../popups/WithdrawFundsModal';
import PortfolioChart from '../../../misc/charts/PortfolioChart';
import { useRemote, useStorage } from '../../../utils/ApiUtils';
import NZEquityTable from '../../../tables/NZEquityTable';
import PortfolioCashTable from '../../../tables/PortfolioCashTable';
import Toast from '../../../misc/Toast';
import Button from '../../../buttons/Button';
import FlexWrapper from '../../../layout/FlexWrapper';

const PortfolioPage = () => {
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const [portfolioLoading, setPortfolioLoading] = useState(true)
	const [cashAssets, setCashAssets] = useState(null)
	const [equities, setEquities] = useState(null)
	const [connectionError, setConnectionError] = useState(false)
	const [errorToastOpen, setErrorToastOpen] = useState(false)
	const [accountId, setAccountId] = useState(null)
	const remote = useRemote();
	const storage = useStorage();

	useEffect(() => {
		storage.getOrFetch("/modules/crm/client/accounts?dynamicFields=CustodyType")
			.then((accounts) => {
				const accountToSet = accounts[0]
				setAccountId(accountToSet.id)
				storage.getOrFetch(`/modules/crm/accounts/${accountToSet.id}/portfolio`)
					.then((response) => {
						setPortfolioLoading(false);
						if (response.connectionError === true) {
							setConnectionError(response.connectionErrorMessage)
							setErrorToastOpen(true)
						} else {
							let cashToSet = response && response.data && response.data.classes && response.data.classes.find(portfolioClass => portfolioClass.classId === 85)
							let NZEquitiesToSet = response && response.data && response.data.classes && response.data.classes.find(portfolioClass => portfolioClass.classId === 79)
							setCashAssets(cashToSet)
							setEquities(NZEquitiesToSet)
						}
					})
			})
			.catch(() => {
				setConnectionError(true)
				setPortfolioLoading(false)
			});
	}, [remote, storage]);

	const closeToast = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setErrorToastOpen(false);
	};

	return (
		<>
			<Toast
				open={errorToastOpen}
				message={connectionError === true ? "Unable to retrieve account data" : connectionError}
				type="danger"
				handleClose={closeToast}
				autoHideDuration={5000}
				closeable
			/>
			<Grid container spacing={4} className="transition-fade-up-slow">
				<WithdrawFundsModal open={open} handleClose={handleClose} accountId={accountId} cashAssets={!connectionError && cashAssets && cashAssets.holdings ? cashAssets.holdings : []}/>
				<Grid item xs={12}>
					<div className="title colour-text-black">Portfolio</div>
				</Grid>
				<Grid item xs={12}>
					<Grid item container spacing={2}>
						<Grid item xs={12}>
							<PortfolioChart />
						</Grid>
						<Grid item xs={12}>
							<PortalCard>
								<h2>NZ Equities</h2>
								<NZEquityTable
									connectionError={connectionError}
									loading={portfolioLoading}
									equities={!connectionError && equities && equities.holdings ? equities.holdings : []}
								/>
							</PortalCard>
						</Grid>
						<Grid item xs={12}>
							<PortalCard>
							<FlexWrapper justify="space-between" className="mb-m">
								{/* <Grid item xs="auto"> */}
									<h2>Cash</h2>
								{/* </Grid> */}
								{/* <Grid item xs="auto"> */}
									<Button size="s" onClick={() => setOpen(true)}>Withdraw</Button>
								{/* </Grid> */}
								</FlexWrapper>
								<PortfolioCashTable remote={remote} storage={storage} accountId={accountId} connectionError={connectionError} loading={portfolioLoading} cashAssets={!connectionError && cashAssets && cashAssets.holdings ? cashAssets.holdings : []} />
							</PortalCard>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default PortfolioPage;
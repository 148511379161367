import React from 'react';
import Divider from '../layout/Divider';
import FlexWrapper from '../layout/FlexWrapper';
import OrderActionToken from '../misc/OrderActionToken';
import AssetLogo from '../misc/ticker-assets/AssetLogo';
import moment from 'moment'
import "./NotificationItem.scss";

const NotificationItem = ({ notification, onClick }) => {
	const { content, createdDate, heading } = notification

	const draft = content ? JSON.parse(content) : ""
	const text = draft && draft.blocks ? draft.blocks.map((block) => { return block.text }) : ""
	const displayText = text && text.length > 0 ? text.join(" ") : ""
	const parsedDate = moment(createdDate);
	const displayDate = parsedDate ? parsedDate.isSame(moment()) ? parsedDate.fromNow() : parsedDate.format("DD/MM/yyyy") : "Time not available.";

	return (
		<FlexWrapper onClick={() => onClick(notification) } gap="s" wrap className={`notification-item${notification.status === "unread" ? " notification-unread" : ""}`}>
			<FlexWrapper gap="xs" justify="space-between" align="flex-start" className="notification-header">
				<div className="notification-name">{heading}</div>
				<div className="notification-date">
					{displayDate}
				</div>
			</FlexWrapper>
			{
				<div className="notification-content">
					{notification.content
						? displayText
						: notification.order && (
								<FlexWrapper gap="s">
									<AssetLogo size={16} logo={notification.order.logo} alt={notification.order.asset} />
									<div className="notification-order">{notification.order.asset}</div>
									<Divider spacing="none" height={18} vertical />
									<OrderActionToken action={notification.order.action} className="notification-order" />
									<Divider spacing="none" height={18} vertical />
									<div className="notification-order">{notification.order.total}</div>
								</FlexWrapper>
						  )}
				</div>
			}
		</FlexWrapper>
	);
}

export default NotificationItem;
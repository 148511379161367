import React from 'react';
import "./Token.scss";

const Token = ({children, value, className}) => {
	return (
		<div className={`token-wrapper${className ? ' ' + className : ''}`}>
			{children || value}
		</div>
   );
}

export default Token;
import React, { useEffect, useState } from "react";
import "./TextInput.scss";
import TextFieldLabel from "./TextFieldLabel";
import ValidationIcons from "./ValidationIcons";
import InputHelperText from "./InputHelperText";

const TextInput = ({ value: initialValue, inputStyle, name, rows, textArea, fullWidth, inputClassName, className, label, invalid, valid, required, helperText, disabled, type, placeholder, onChange, autoFocus, infoIcon, icon, hideDropdownIndicator, inputPrefix, wrapped, controlledByProps, ...props }) => {
	const [value, setValue] = useState("");

	useEffect(() => {
		if (initialValue) setValue(initialValue)
	}, [initialValue]);

	return (
		<div
			className={`text-input-wrapper${icon ? " text-input-icon-active" : ""}${helperText ? " helper-text-active" : ""}${invalid ? " text-input-wrapper-invalid" : ""}${valid ? " text-input-wrapper-valid" : ""}${fullWidth ? " input-full-width" : ""}${className ? " " + className : ""
				}`}
		>
			{label && (
				<TextFieldLabel>
					{label}
					<ValidationIcons required={required} valid={valid} infoIcon={infoIcon} />
				</TextFieldLabel>
			)}
			{icon && <div className="text-input-icon-wrapper" onClick={icon.onClick}>{icon.icon}</div>}
			{textArea ? (
				<textarea
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e);
					}}
					value={controlledByProps ? initialValue : value}
					style={{ resize: "vertical", height: "auto" }}
					rows={rows ? rows : 6}
					name={name}
					disabled={disabled}
					placeholder={placeholder}
					className={`text-input${inputClassName ? " " + inputClassName : ""}`}
					autoFocus={autoFocus}
					autoComplete="off"
				/>
			) : controlledByProps ? (<div className={`${wrapped ? "text-input" : ""}${inputClassName ? " " + inputClassName : ""}`}>
				{inputPrefix && <span className="text-input-prefix" >{inputPrefix}</span>}
				<input
					{...props}
					onChange={(e) => {
						onChange(e);
					}}
					value={initialValue}
					type={type ? type : "text"}
					name={name}
					disabled={disabled}
					placeholder={placeholder}
					className={wrapped ? "wrapped-input" : "text-input"}
					autoFocus={autoFocus}
					autoComplete="off"
					style={inputStyle}
				/>
			</div>
			)
				: (<div className={`${wrapped ? "text-input" : ""}${inputClassName ? " " + inputClassName : ""}`}>
					{inputPrefix && <span className="text-input-prefix" >{inputPrefix}</span>}
					<input
						{...props}
						onChange={(e) => {
							setValue(e.target.value);
							onChange(e);
						}}
						value={value}
						type={type ? type : "text"}
						name={name}
						disabled={disabled}
						placeholder={placeholder}
						className={wrapped ? "wrapped-input" : "text-input"}
						autoFocus={autoFocus}
						autoComplete="off"
						style={inputStyle}
					/>
				</div>
				)
			}
			{helperText && <InputHelperText invalid={invalid} valid={valid}>{helperText}</InputHelperText>}
		</div>
	);
};

export default TextInput 
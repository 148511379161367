import React from 'react';
import Grid from "@material-ui/core/Grid";
import FlexWrapper from '../../../layout/FlexWrapper';
import Button from '../../../buttons/Button';
import { useNavigate } from "react-router-dom";

const OnboardingBankVerification = () => {	
	let navigate = useNavigate();

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FlexWrapper gap="s">
						<div className="title">Bank Account Verification</div>
					</FlexWrapper>
				</Grid>
				<Grid item xs={12}>
					<h1>Account Verified</h1>
					<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quasi fugiat ex in eligendi expedita.</p>
					<Button className="mt-xl" onClick={() => navigate("/login")}>Login Now</Button>
				</Grid>
			</Grid>
		</>
	);
}

export default OnboardingBankVerification;
import React, { useEffect, useRef, useState } from 'react';
import TextInput from './TextInput';
import "./NumberFieldInput.scss";

const NumberFieldInput = ({ money, innerLabel, helperText, displayOnly, value, ...props }) => {
	const [width, setWidth] = useState(0);
	const innerLabelRef = useRef(null);
	// To dynamically set the padding-left of the input for the inner label text.
	useEffect(() => {
		setWidth(innerLabelRef.current.getBoundingClientRect().width);
	}, []);

	return (
		<div className={`number-input-field-wrapper${helperText ? " helper-text" : ""}`}>
			 <TextInput
				{...props}
				disabled={displayOnly}
				value={value}
				inputClassName="number-field-input"
				type="number"
				helperText={helperText}
				min="1"
				step="any"
				inputStyle={{ paddingLeft: width + 24 }}
			/>
			{money && <div className="nfi-prefix">$</div>}
			{innerLabel && <div className="nfi-inner-label" ref={innerLabelRef}>{innerLabel}</div>}
		</div>
	);
}

export default NumberFieldInput;
import React, { PureComponent } from 'react';

class TimeFrameSelectionItem extends PureComponent {
    render() {
        let className = "selection-menu-item";
        if (this.props.className) {
            className += " " + this.props.className;
        }
        if (this.props.active) {
            className += " selection-menu-active-item";
        }
        return <li onClick={this.props.onClick} className={className}>{this.props.children}</li>;
    }
}

export default TimeFrameSelectionItem;